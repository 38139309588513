import React from "react";
import {ReactComponent as Character} from "../../../Assets/CharacterAsset/character.svg"
import { QuestionTitleStyle, TextWrapperStyle } from "./QuestionTitle.styled";

function QuestionTitle(props){
  return(
    <QuestionTitleStyle>
      <Character/>
      <TextWrapperStyle>
        {props.text}
      </TextWrapperStyle>
    </QuestionTitleStyle>
  )
    
}

export default QuestionTitle