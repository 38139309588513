import styled from "styled-components";

export const DividerStyle = styled.div`
  display:flex;
  align-items:center;
  
  ${(props)=>{
    switch(props.orientation){
      case "vertical":
        return {
          width: `${props.width}px`,
          height: `${props.length}px`
        }
      case "horizontal":
        return {
          width: `${props.length}px`,
          height: `${props.width}px`
        }
    }
  }}
`