import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack, ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@mui/material";
import styled, { withTheme } from "styled-components";
import { SceneToggleButtons } from "../../../../../../../Components/pages/Editor/SceneToggleButtons";
import { EditorPageContext } from "../../../../../editorPageContext";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
//import { ThemeProvider } from "../../../../../../../theme/ThemeProvider";
import theme from "../../../../../../../styles/theme";

import screenEffectJson from "../../../../../../../Data/screenEffect.json"

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const SceneButton = styled((props: ButtonProps) => (
  <Button variant="outlined" sx={{ borderColor: 'divider', padding: 0, whiteSpace: 'normal' }} {...props} />
))((theme) => {
  return {
  width: 90.33,
  height: 56,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
}});

// const SceneButton = styled(Button)({
//   boxShadow: 'none',
//   textTransform: 'none',
//   fontSize: 16,
//   padding: '6px 12px',
//   border: '1px solid',
//   lineHeight: 1.5,
//   backgroundColor: '#5612f3',
//   borderColor: '#0063cc',
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   '&:hover': {
//     backgroundColor: '#0069d9',
//     borderColor: '#0062cc',
//     boxShadow: 'none',
//   },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: '#0062cc',
//     borderColor: '#005cbf',
//   },
//   '&:focus': {
//     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//   },
// });

// const SceneButton = styled(withTheme(Button))( props => {
//  console.log(props);
//  return {
//   flex: 1,
//   height: 56,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   border: 1,
//   borderColor: "black",
//   borderRadius: 2,
// }});

export const SceneTransitionTabContent = ({index}): JSX.Element => {
  
  const [transition, setTransition] = useState('slide_right');
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  // {"effect":"오른쪽으로 슬라이드", "name":"slide_right"},
  // {"effect":"왼쪽으로 슬라이드", "name":"slide_left"},
  // {"effect":"위로 슬라이드", "name":"slide_up"},
  // {"effect":"아래로 슬라이드", "name":"slide_down"},
  // {"effect":"원 화면전환", "name":"circle"},
  // {"effect":"물결 화면전환", "name":"wave"},
  // {"effect":"페이드아웃 화면전환", "name":"fadein"},
  // {"effect":"없음", "name":"none"}


  useEffect(()=>{
    setTransition(scenarioContext.finalScenario.scenes[index].effect);
  },[index, scenarioContext.finalScenario.scenes[index]])

  const handleChangeTransition = (newEffect:any ) => {
    // console.log(`Scene${index+1} new transition :: `, newEffect.effect);
    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
    finalScenario.scenes[index].effect=newEffect.effect;
    scenarioContext.setFinalScenario(finalScenario);
  };

  useEffect(()=> {
    // console.log(transition);
  }, [transition]);

  return (
    
    <StyledFrame>
      {/* {editorPageContext.selectedItem?.type}
      {editorPageContext.selectedItem?.index}
      {transition} */}
      <Stack gap={1} direction="row" sx={{ flexWrap: 'wrap'}}>
        {
          screenEffectJson.map(effect=>{
            const selected = effect.effect === transition
            return(
              <SceneButton
                variant="outlined"
                onClick={()=> {handleChangeTransition(effect)}}
                sx={{
                  wordBreak:"keep-all",
                  border:`${theme.border["border-01"]} solid`, 
                  borderColor: `${selected? `${theme.colors["border-active"]}`:`${theme.colors["border-secondary"]}`}`
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {effect.effect}
                </Typography>
              </SceneButton>
            )
          })
        }
      </Stack>
      {/* <SceneToggleButtons value={transition} onChange={handleChangeTransition}></SceneToggleButtons> */}
      {/* <Stack gap={1} direction="row" sx={{ flexWrap: 'wrap'}}>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            페이드인
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            페이드아웃
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            슬라이드<br/>라이트
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')} >
          <Typography variant="body2" color="text.secondary">
            효과 이름
          </Typography>
        </SceneButton>
      </Stack> */}
    </StyledFrame>
  );
};
