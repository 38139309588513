import React, { useEffect, useState } from "react";
import styled from "styled-components";

import theme from "../../styles/theme";

import  {ReactComponent as CameraIconElement} from '../../Assets/Icons/Camera.svg'
import  {ReactComponent as CaretDownIconElement } from'../../Assets/Icons/CaretDown.svg'
import  {ReactComponent as CaretLeftIconElement } from'../../Assets/Icons/CaretLeft.svg'
import  {ReactComponent as CaretRightIconElement } from'../../Assets/Icons/CaretRight.svg'
import  {ReactComponent as CaretUpIconElement } from'../../Assets/Icons/CaretUp.svg'
import  {ReactComponent as CheckIconElement } from'../../Assets/Icons/Check.svg'
import  {ReactComponent as CropIconElement } from'../../Assets/Icons/Crop.svg'
import  {ReactComponent as DeleteIconElement } from'../../Assets/Icons/Delete.svg'
import  {ReactComponent as DeleteBorderlessIconElement } from'../../Assets/Icons/DeleteBorderless.svg'
import  {ReactComponent as HouseIconElement } from'../../Assets/Icons/House.svg'
import  {ReactComponent as InformationIconElement } from'../../Assets/Icons/information.svg'
import  {ReactComponent as ListIconElement } from'../../Assets/Icons/List.svg'
import  {ReactComponent as MagnifyingGlassIconElement } from'../../Assets/Icons/MagnifyingGlass.svg'
import  {ReactComponent as PencilSimpleIconElement } from'../../Assets/Icons/PencilSimple.svg'
import  {ReactComponent as QuestionIconElement } from'../../Assets/Icons/Question.svg'
import  {ReactComponent as ScissorsIconElement } from'../../Assets/Icons/Scissors.svg'
import  {ReactComponent as XIconElement } from'../../Assets/Icons/X.svg'

import { IconDivStyle } from "./Icon.styled";

export type IconTypes = "X"|"Scissors"|"Question"|"PencilSimple"|"MagnifyingGlass"|"List"|"information"|"House"|"delete"|"DeleteBorderless"|"Crop"|"Check"|"CaretUp"|"CaretRight"|"CaretLeft"|"CaretDown"|"Camera"|undefined

interface IconInterface{
  icon:IconTypes,
  width?:number,
  height?:number,
  style?:React.CSSProperties,
  disabled?:boolean,
  color?:string,
}


function Icon(props){
  const [buttonIconElement,  setButtonIconElement] = useState<any>(null)  
  const svgStyleProps={
    height:props.height||"100%",
    width:props.height||"100%",
  }

  useEffect(()=>{
    switch(props.icon){
      case "X":
        setButtonIconElement(<XIconElement style={svgStyleProps}/>);
        break;
      case "Scissors":
        setButtonIconElement(<ScissorsIconElement style={svgStyleProps} />);
        break;
      case "Question":
        setButtonIconElement(<QuestionIconElement style={svgStyleProps} />);
        break;
      case "PencilSimple":
        setButtonIconElement(<PencilSimpleIconElement style={svgStyleProps} />);
        break;
      case "MagnifyingGlass":
        setButtonIconElement(<MagnifyingGlassIconElement style={svgStyleProps} />);
        break;
      case "information":
        setButtonIconElement(<InformationIconElement style={svgStyleProps} />);
        break;
      case "House":
        setButtonIconElement(<HouseIconElement style={svgStyleProps} />);
        break;
      case "delete":
        setButtonIconElement(<DeleteIconElement style={svgStyleProps} />);
        break;
      case "DeleteBorderless":
        setButtonIconElement(<DeleteBorderlessIconElement style={svgStyleProps} />);
        break;
      case "Crop":
        setButtonIconElement(<CropIconElement style={svgStyleProps} />);
        break;
      case "Check":
        setButtonIconElement(<CheckIconElement style={svgStyleProps} />);
        break;
      case "CaretUp":
        setButtonIconElement(<CaretUpIconElement style={svgStyleProps} />);
        break;
      case "CaretRight":
        setButtonIconElement(<CaretRightIconElement style={svgStyleProps} />);
        break;
      case "CaretLeft":
        setButtonIconElement(<CaretLeftIconElement style={svgStyleProps} />);
        break;
      case "CaretDown":
        setButtonIconElement(<CaretDownIconElement style={svgStyleProps} />);
        break;
      case "Camera":
        setButtonIconElement(<CameraIconElement style={svgStyleProps} />);
        break;
      case "List":
        setButtonIconElement(<ListIconElement style={svgStyleProps} />)
        break;
    }
  },[props.icon])

  

  return (
    <IconDivStyle style={{height:`${props.height}`, stroke: theme.colors[`${props.color}`]}} {...props}>
      {buttonIconElement}
    </IconDivStyle>
  )
}

export default Icon
