import React, { useEffect, useRef, useState } from "react";

import { VideoSectionStyle, LoadingStatusStyle,VideoStyle,VideoWrapper,LottieWrapper,LoadingTextWrapper } from "./VideoLoading.styled";
import ProgressBar from "../ProgressBar";
import Button from "../../../common/Button";

import Lottie from "lottie-react";
import characterAnimation from '../../../../animation/character.json'
import AlertModal from "../../../common/AlertModal";
import { ReactComponent as CheckIcon } from "../../../../Assets/ModalIllu/check.svg"

function VideoLoading(props){
  const videoRef = useRef(null);
  const [showAlert, setShowAlert] = useState(true);
  const closeAlert = () => {setShowAlert(false)}

  const handleCancel = () => {
    if(props.onCancel){
      props.onCancel();
    }
  }

  const handleCloseAlert = () => {
    const videoEl = videoRef.current as HTMLVideoElement
    if(videoEl){
      videoEl.play();
    }
    closeAlert();
  }


  return (
    <VideoSectionStyle >      
        <VideoStyle ref={videoRef} src="./adv_rendering_wait.mp4" loop/>
      {/* <VideoWrapper>
      </VideoWrapper> */}
      <LoadingStatusStyle>
        <LoadingTextWrapper>
          {`AI 광고 제작 중 (${props.value}%)`}
        </LoadingTextWrapper>
        <ProgressBar style={{flex:1}} value={props.value}/>
        <Button onClick={handleCancel} $buttonType="button-box" $variant="brand2-subtle1" text="취소"/>
      </LoadingStatusStyle>
      <AlertModal
        show={showAlert}
        icon={<CheckIcon/>}
        onConfirm={handleCloseAlert}
        onCancel={handleCloseAlert}
        title={"AI 광고 영상을 생성합니다."}
        onlyConfirm
      />
    </VideoSectionStyle>
  )
}

export default VideoLoading;