import styled from "styled-components";

export const ModalBtnsStyle = styled.div`
  display:flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  justify-self: stretch;
  flex: 0 0 auto;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  position: relative;
  width: 100%;
`

export const BodyStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display:flex;

  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-09"]};
  position: relative;
  width: 500px;
  box-sizing: border-box;
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-08"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
`

export const ContentFrameStyle = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-06"]};
  width:100%;
  align-items: center;
  align-self: stretch;
  display:flex;
  flex: 0 0 auto;
  flex-direction: column;
`

export const BtnFrameStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  width:100%;
  flex: 0 0 auto;
`

export const TextStyle = styled.div`
align-items: flex-start;
align-self: stretch;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: ${(props)=>props.theme.spacing["spacing-04"]};
position: relative;
width: 100%;
`

export const ModalTextWrapperStyle = styled.div`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-heading-04"]};
  margin-top: -1px;
  position: relative;
  text-align: center;
`

export const ModalPStyle = styled.p`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-body-04"]};
  position: relative;
  text-align: center;
  margin: 0px;
`