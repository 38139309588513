import styled from "styled-components";

export const VideoFrameStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-06"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]}`
  }};
  width:100%;
  box-sizing: border-box;
`
export const LoadingFrameStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]}`
  }};
  width:100%;
  box-sizing: border-box;
`

export const FootStyle = styled.div`
  display:flex;
  justify-content: space-between;
  flex:1;
`
export const FootRightStyle = styled.div`
  width: fit-content;
  display: flex;
  gap:${(props)=>props.theme.spacing["spacing-04"]}
`