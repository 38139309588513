import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

interface Props {
  color: string;
  className: any;
}

const StyledSvg = styled.svg`
  &.icon-7 {
    fill: none;
    height: 16px;
    width: 16px;
  }

  & .path {
    stroke: ${(props) => props.color};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
  }
`;

export const Icon7 = ({ color = "#5A636C", className }: Props): JSX.Element => {
  return (
    <StyledSvg
      color={`${color}`}
      className={`icon-7 ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        className="path"
        d="M10 13L5 8L10 3"
      />
    </StyledSvg>
  );
};

Icon7.propTypes = {
  color: PropTypes.string,
};