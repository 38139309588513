import React, { useEffect, useState } from "react";
import styled from "styled-components";

function PlayerSoundBar(props){
  return(
    <SoundBarFrameStyle>
      <SoundBarStyle {...props}></SoundBarStyle>
      <VolumeStyle {...props}></VolumeStyle>
    </SoundBarFrameStyle>
  )
}

export default PlayerSoundBar

const SoundBarFrameStyle = styled.div`
  width: 120px;
  height:100%;
  align-items:center;
  display: inline-flex;
  position: relative;
  border-radius:${(props)=>props.theme.radius["radius-full"]};
  overflow:hidden;
`

const SoundBarStyle = styled.div`
  background-color:${(props)=>props.theme.colors["background-primary"]};
  opacity:0.4;
  height: 4px;
  width: 120px;
  position:relative;
  box-sizing:border-box;
  display: inline-flex;
  align-items:center;
  justify-content:flex-start;
  position: relative;
`
const VolumeStyle = styled.div`
  width:${(props:any)=>`${props.value}%`};
  height:100%;
  background-color:${(props)=>props.theme.colors["background-primary"]};
  opacity:1;
  position: absolute;
`
