import axios from 'axios';

export const BASE_URL = `https://${process.env.REACT_APP_API_SERVER_HOST}`

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Adv-Token":"9ab559f974a9446839628edc267852aec3a53ebe32ba992c66396a2c500a3327"
  },
});


///// set axios interceptors

apiClient.interceptors.request.use(
  function (config) {
    try{
      // console.log("sending request : ",config);
      return config;
    }catch(err){
      return Promise.reject(err)
    }
  },
  function (error) {
    // 요청 오류가 있는 경우 작업 수행
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    try{
      if(response.status === 200){
        // console.log("Got Axios response Success")
      }else{
        console.error("Got Axios response failed with error", response);
      }
      return response
    }catch(error){
      console.error("response error ::: ", error)
      return Promise.reject(error)
    }
  },
  function (error) {
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    console.error("Axios response failed with an error", error);
    return Promise.reject(error);  // 여기서도 Promise.reject로 에러 처리
  }
);


export default apiClient