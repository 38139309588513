import React from "react";

import styled from "styled-components";

import Spiner from "../Spiner";
import Lottie from 'lottie-react';
import spinnerAnimation from "../../../animation/spinner.json"

interface LoadingScreenProp{
  text?:string
}

function LoadingScreen({text="AI가 정보를 분석하고 있습니다"}:LoadingScreenProp){
  return(
    <LoadingScreenStyle>
      <Lottie
        animationData={spinnerAnimation}
        loop={true}
      />
      <TextWrapper>
        {text}
      </TextWrapper>
    </LoadingScreenStyle>
  )
}

export default LoadingScreen

const LoadingScreenStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;

  user-select:none;

  gap:${(props)=>props.theme.spacing["spacing-07"]};
`
const TextWrapper = styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-brand2"]};
`