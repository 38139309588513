import React from "react";

import styled,{keyframes} from "styled-components";

function Toast(props){
  
  return(
    <ToastStyle className={props.active&&"active"}>
      {props.children}
    </ToastStyle>
  )
}

export default Toast

const moveUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const ToastStyle = styled.div`
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-04"]} ${spacing["spacing-08"]} ${spacing["spacing-04"]} ${spacing["spacing-08"]}`
  }};
  display: inline-flex;
  align-items:center;
  justify-content:center;
  background-color:${(props)=>props.theme.colors["background-overlay-subtle"]};
  border-radius:${(props)=>props.theme.radius["radius-02"]};

  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-primary-inverse"]};
  width: fit-content;
  white-space:nowrap;
  user-select:none;

  animation: ${moveUp} 0.5s ;

`