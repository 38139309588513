import styled from "styled-components";

export const ButtonQuestionStyle = styled.div`
  align-items: center;
  border-radius: ${(props)=>props.theme.radius["radius-04"]};
  display:flex;
  gap: ${(props)=>props.theme.spacing["spacing-06"]};
  padding: ${(props)=>props.theme.spacing["spacing-07"]};
  position:relative;
  height:100%;
  flex:1;
  flex-grow:1;
  cursor: pointer;
  box-sizing:border-box;

  background: ${(props)=>props.theme.colors["button-mute"]};

  &.selected{
    border: ${(props)=>props.theme.border["border-03"]} solid ${(props)=>props.theme.colors["border-active"]};
    padding: calc(${(props)=>props.theme.spacing["spacing-07"]} - ${(props) => props.theme.border["border-03"]});
  }

  &:hover{
    background: ${(props)=>props.theme.colors["button-mute-hover"]};
  }
`

export const ButtonIconWrapper = styled.div`
  width: 56px;
`

export const ButtonQuestionContentsStyle= styled.div`
  display:flex;
  align-items:flex-start;
  flex-direction:column;
  flex:1;
  flex-grow:1;
  position:relative;
`

export const ButtonQuestionTitleStyle= styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
`
export const ButtonQuestionDescStyle= styled.div`
  ${(props)=>props.theme.typography["pre-body-04"]}
`