import React, { useEffect, useRef } from "react";
import Dot from "../Dot";
import { InputLabelStyle, InputLabelTextWrapperStyle } from "./InputLabel.styled";

function InputLabel(props){
  const labelRef = useRef(null)
  
  useEffect(()=>{
    const element = (labelRef.current as HTMLElement)
    if(element){
      element.classList.add(props.size)
    }
  },[props.size])

  return( 
    <InputLabelStyle ref={labelRef} {...props}>
      <InputLabelTextWrapperStyle>
        {props.label}
      </InputLabelTextWrapperStyle>
      {props.required && <Dot/>}
    </InputLabelStyle>
  )
}

export default InputLabel

