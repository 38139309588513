import React from 'react'
import styled from 'styled-components'

function Container({head, content, foot}:any){
  return (
    <ContainerStyle>
      <ContainerTopBtnStyle>
        {head}
      </ContainerTopBtnStyle>      
      {content}      
      <ContainerButtomBtnStyle>
        {foot}
      </ContainerButtomBtnStyle>
    </ContainerStyle>
  )
}

export default Container

export const ContainerStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width:930px;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-13"]} ${spacing["spacing-17"]} ${spacing["spacing-13"]} ${spacing["spacing-17"]}`
  }};
  box-sizing: border-box;
  background-color: ${(props)=>props.theme.colors["background-tertiary"]};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const ContainerTopBtnStyle = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  justify-content: flex-start;
  position:relative;
  width:100%;
`

export const ContainerTextFrameStyle= styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props)=>props.theme.colors["background-quarternary"]};
  width:100%;
  height:40px;
  box-sizing:border-box;
  padding:${(props)=>props.theme.spacing["spacing-04"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-secondary"]};
  border-radius:${(props)=>props.theme.radius["radius-05"]};
  margin-top:${(props)=>props.theme.spacing["spacing-07"]};
`
export const ContainerAITextStyle= styled.span`
  color:${(props)=>props.theme.colors["text-brand1"]}
`
export const ContainerFrameStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap:${(props)=>props.theme.spacing["spacing-06"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]}`
  }};
  box-sizing: border-box;
  width:100%;
`

export const ContainerSubFrameStyle = styled.div`
  align-items: flex-start;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
  box-sizing: border-box;
  width:100%;
`

export const ContainerButtomBtnStyle = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  justify-content: flex-end;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-08"]} ${spacing["spacing-none"]} ${spacing["spacing-08"]}`
  }};
  width:100%;
`

