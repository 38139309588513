import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState, memo } from "react";

import { ReactComponent as EraserIcon } from "../../../../../Assets/icon-editor/timeline-eraser.svg";
import VideoHandle from "../../../../../Assets/icon-editor/timeline-video-handle.png";

import thumbSample from "../../../../../Assets/icon-editor/thumb_sample.png";
import thumbSample1 from "../../../../../Assets/icon-editor/thumb_sample1.png";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { WebGetFile, WebGetUrl } from "../../../../../Api/resource";
import { EditorContext } from "../../../../../Contexts/EditorContext";
import { EditorPageContext } from "../../../editorPageContext";
import { ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";

interface Props {
  stepSize: number,
  onClickItem?: Function,
  clips?: any[],
}

interface ItemProps {
  order: number,
  sceneName: string,
  duration: number, // 밀리세컨드
  stepSize: number,
  // images: string[],
  // image?: string|undefined,
  onClick?: Function,
  selected?:boolean,
  clip?:any,
  scene?:any,
}
const VideoItem = ({ 
  order,
  sceneName,
  duration,
  stepSize,
  // images,
  // image=undefined,
  onClick=undefined,
  selected=false,
  clip=undefined,
  scene=undefined,
}: ItemProps): JSX.Element => {
  // const [start, setStart] = useState(0);
  // const {editor} = useContext(MasherContext);
  const [end, setEnd] = useState(duration * stepSize * 10);
  const [imageArr, setImageArr] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [imageList, setImageList] = useState(undefined);

  const editorPageContext = useContext(EditorPageContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const scenarioContext = useContext(ScenarioContext)

  useEffect(()=>{
    // console.log(`Scene${order} :: `,scene)

    getThumbnails()
        
  },[scene])

  async function getThumbnails(){
    if(scene.type==="이미지"){
      const targetIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName)
      if(targetIndex>=0){
        const targetFile = shopInfoContext.editedFiles[targetIndex].file;
        const url = URL.createObjectURL(targetFile)
        if(end>84){
          const newArr=[]
          for(let i=0; i<end/84; i++){
            newArr.push( <img key={`thumb-${i}`} src={url} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
          }
          setImageArr(newArr)
        }
      }
    }else if(scene.type==="동영상"){
      const targetIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName)
      const targetFile = shopInfoContext.editedFiles[targetIndex].file;
      const url = URL.createObjectURL(targetFile);
      const newArr = []
      for(let i=0; i<scene.time*1.5;i++){
        newArr.push(<video style={{ width:84, height:48, objectFit: "cover"}} src={url} onLoadedMetadata={(e)=>{e.currentTarget.currentTime=i*(2/3)}} muted/>)
      }
      setImageArr(newArr)
    }else if(scene.type==="last"){
      if(end>84){
        const newArr=[]

        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;
        let searchBg = `./searchBarW_U3.png`
        const nameLength=name.length
        if(consonantCode === 0){ //를
          if(nameLength<=3){searchBg = `./searchBarW1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW4.png`}
          else {searchBg = `./searchBarW5.png`}
        }else{//을
          if(nameLength<=3){searchBg = `./searchBarW_U1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW_U2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW_U3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW_U4.png`}
          else {searchBg = `./searchBarW_U5.png`}
        }

        for(let i=0; i<end/84; i++){
          newArr.push( <img key={`thumb-${i}`} src={searchBg} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
        }
        setImageArr(newArr)
      }
    }else{
      // console.log("gen images :: ",scenarioContext.generatedImages)
      const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===scene.no);
      if(idx>=0){
        const genImages = scenarioContext.generatedImages[idx];
        const targetKey = genImages.s3Keys[genImages.selectedS3KeyIndex];
  
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: targetKey,
        })
        if(response.result===0){
          const url = response.presignedUrl;
          if(end>84){
            const newArr=[]
            for(let i=0; i<end/84; i++){
              newArr.push( <img key={`thumb-${i}`} src={url} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
            }
            setImageArr(newArr)
          }
        }
      }
    }
  }

  // useEffect(()=>{
  //   // 클립 시간과 definition이 바뀌는 경우 섬네일 다시 생성
  //   if(end>84){
  //     const newArr=[]
  //     for(let i=0; i<end/84; i++){
  //       newArr.push( <img key={`thumb-${i}`} src={clip.content.definition.source} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
  //     }
  //     setImageArr(newArr)
  //   }
  // },[end, clip.content.definition])

  const handleClick = (e) => {
    setIsSelected(prev=>!isSelected)
    editorPageContext.setSelectedItem({
      type:"video",
      index:order-1,
      // clip:clip
    })
    if(onClick){
      onClick();
    }
  }
  useEffect(()=>{
    setIsSelected(selected);
  },[selected])

  // useEffect(()=>{
  //   const itemSize = duration * stepSize * 10
  //   setEnd(itemSize)  
  // },[duration])

  // useEffect(()=>{
  //   if(image && end){
  //     console.log(`imageItem ${order} :: `, image);
  //     let newImageList = [];
  //     for(let i=0; i<end/84; i++){
  //       newImageList.push(image);
  //     }
  //     console.log(`video item ${order} :: `,newImageList)
  //     setImageList(newImageList);
  //   }else{
  //     setImageList([]);
  //   }
  // },[image, end])
  

  // useEffect(()=>{
  //   console.log("image list :: ", imageList);
  // },[imageList])



  return (
    <Box display="flex" width={end} height={48} justifyContent="space-between" alignItems="center" pr={2} pl={2} py={0} position="relative" borderRadius={1} overflow="hidden" sx={{backgroundColor:"black"}} onClick={handleClick}>
      <Box display="flex" position="absolute" top={0} left={0}>
        {imageArr}
        {/* {images?.length > 0 ?
          (images.map((src, index) => (
            <img key={index} src={src} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)))
          :
          <img src="" alt="null" />
        } */}
      </Box>
      <Box position="absolute" width="100%" height="100%" top={0} left={0} bgcolor={isSelected? "#3617ce80" : "rgba(0,0,0, 0.3"} />
      <>
        {isSelected && <Box component="img" src={VideoHandle} alt="Bar" draggable="false" sx={{ position: 'absolute', width: 12, height: "100%", top: 0, left: 0, cursor: "pointer", }} />}
        <Typography position="relative" flex={1} fontWeight="bold" color="white" fontSize="0.875rem" letterSpacing="0.1em" lineHeight="1.5">{`#${sceneName} ${order}`}</Typography>
      </>
      <>
        <IconButton size="small">
          <EraserIcon width={16} height={16}/>
        </IconButton>
        {isSelected && <Box component="img" src={VideoHandle} alt="Bar" draggable="false" sx={{ position: 'absolute', width: 12, height: "100%", top: 0, right: 0, cursor: "pointer", }} />}
      </>
    </Box>
  )
}

export const VideoTrack = ({ stepSize, onClickItem=undefined, clips=[] }: Props): JSX.Element => {

  const [sceneList, setSceneList] = useState([]);
  const [thumbnailUrlList, setThumbnailUrlList] = useState<any[]>([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const editorContext = useContext(EditorContext);
  /// 미리보기 페이지 컨텍스트
  const editorPageContext = useContext(EditorPageContext);
  
  const outputUrl = editorContext.outputVideoUrl;
  /// 출력 영상 URL : 출력 영상의 파일 Blob URL
  const outputUrlPresigned = editorContext.outputPresignedUrl;
  /// 출력 영상 presigned url : AWS S3에 업로드된 출력 영상 파일의 presigned url
  const scenarioContext = useContext(ScenarioContext);
  // 시나리오 페이지 컨텍스트
  const videoThumbRef =  useRef(null);

  const [videoClips, setVideoClips] = useState(clips);
  


  useEffect(()=>{
    const scenes = scenarioContext.finalScenario?.scenes;
    // 최종 시나리오의 장면 정보
    // scenes.no : 장면 번호
    // scenes.time : 장면 재생시간
    // scenes.title: 장면 제목
    // scenes.fileName : 장면에 해당하는 파일

    // console.log("scenes :: ", scenes)
    if(scenes){
      setSceneList(scenes);
      
    } 
  },[scenarioContext.finalScenario])


  useEffect(()=>{
    if(sceneList.length>0){
      getThumbnail();
    }
  },[sceneList])
  

  async function genThumbnails(interval: number){
    setIsCapturing((isCapturing)=>true)
    const tempVideoEl = videoThumbRef.current;   
    if (!tempVideoEl) {
      console.log("video failed")
      setIsCapturing((isCapturing)=>false)
      return;
    };
  
    const captureFrames = async (targetVideo:HTMLVideoElement) => {
      console.log(`function captureFrames`)
      targetVideo.currentTime = 0;
      const thumbList = [];
      const canvas = document.createElement('canvas');
      const aspectRatio = targetVideo.videoHeight/targetVideo.videoWidth;
      
      canvas.width = 120;
      canvas.height = 120*aspectRatio;
      const context = canvas.getContext('2d');
      if(!context){
        throw Error("context failed");
      }
  
      for(let i=0.0; i < targetVideo.duration; i+=interval){
        targetVideo.currentTime = i;
        await new Promise((resolve) => {
          targetVideo.onseeked = resolve;
        });
        // console.log(`thumb at ${targetVideo.currentTime}`)
        context.drawImage(targetVideo, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL('image/png');
        thumbList.push(imageDataUrl);
      }

      targetVideo.currentTime = 0.0;
      setThumbnailUrlList(thumbList);
      // console.log(thumbList);
    };
    captureFrames(tempVideoEl);
    setIsCapturing((isCapturing)=>false)
  }

  async function getThumbnail(){
    //섬네일 생성
    let thumbList = []
    for(let i=0; i<sceneList.length; i++){
      try{
        //presigned url요청
        const request = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: sceneList[i].layoutList[0].path
        }
        const response = await WebGetUrl(request);
        if(response.result===0){
          // console.log("thumbnail created :: ", response.presignedUrl)
          thumbList.push(response.presignedUrl);
        }else{
          // console.error("err :: ", response.errMsg)
          thumbList.push("")
        }
      }catch(err){
        console.error("err :: ", err)
        thumbList.push("")
      }      
    }
    // console.log("thumbnail list :: ", thumbList)
    setThumbnailUrlList(thumbList);
  }

  const handleClickItem = (index) => {
    if(onClickItem){
      if(editorPageContext.selectType==="video" && editorPageContext.selectIndex===index){
        onClickItem({type:undefined, index:undefined})
      }else{
        onClickItem({type:"video", index:index})
      }
    }
  }

  const handleLoadThumbVideo = async (e:any) => {
    console.log("============= thumbnail video load metadata ===============================");
    genThumbnails(0.5);
  }

  const getThumbListForScene = (index) => {
    
    // console.log("call getThumbListForScene : ", index);
    if(sceneList == null || sceneList.length === 0 ) return [];
    if(thumbnailUrlList == null || thumbnailUrlList.length === 0 ) return [];

    const currentScene = sceneList[index];
    let startTime = 0;

    for(let i=0; i < index; i++) {
      startTime = startTime + sceneList[i].time
    }
    
    const startIndex = Math.floor(startTime / 0.5); //초당 2프레임이라서
    let endIndex = startIndex + Math.ceil(currentScene.time / 0.5);
    if(startIndex > thumbnailUrlList.length-1) return [];
    if(endIndex > thumbnailUrlList.length-1) endIndex = thumbnailUrlList.length-1
    
    // console.log("thumbnail index for scene:", index, startIndex, endIndex);
    return thumbnailUrlList.slice(startIndex, endIndex);
  }

  return (
    // <Box sx={{ width: "100%", height: "48px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
    //   <VideoItem duration={5} stepSize={stepSize} />
    //   <VideoItem duration={7} stepSize={stepSize} />
    // </Box>
    <Stack gap={0} direction="row" sx={{ width: "100%", height: "48px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
      {sceneList.map((scene, index)=>{
        return <VideoItem selected={(editorPageContext.selectType==="video")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleClickItem(index)}} key={`video-item${index}`} sceneName={`Scene`} order={index+1} duration={parseFloat(scene.time)} stepSize={stepSize} scene={scene}/>  
      })}
      {/* {
        clips.map((clip, index)=>{
          return (
            <VideoItem
              selected={(editorPageContext.selectType==="video")&&(editorPageContext.selectIndex===index)}
              onClick={()=>{handleClickItem(index)}}
              key={`video-item${index}`}
              sceneName={`Scene`}
              order={index+1}
              duration={clip.frames}
              stepSize={stepSize}
              // images={getThumbListForScene(index)}
              // image={thumbnailUrlList[index]||""}
              // clip={clip}
            />
          )
        })
      } */}
      {/* <VideoItem sceneName="Secen" order={1} duration={5} stepSize={stepSize} images={[thumbSample, thumbSample, thumbSample, thumbSample, thumbSample, thumbSample, thumbSample, thumbSample, thumbSample, thumbSample]}/>
      <VideoItem sceneName="Secen" order={2} duration={7} stepSize={stepSize} images={[thumbSample1]}/> */}
      <video ref = {videoThumbRef}
        style={{display:"none"}}
        src={editorContext.outputVideoUrl}
        onLoadedMetadata={handleLoadThumbVideo}
        crossOrigin="anonymous"
        ></video>
    </Stack>
  )
}

export const MemoizedVideoTrack = memo(VideoTrack)