import React, { useEffect, useRef } from "react";

import {
  ScenarioFrameStyle,
  ScenarioSubFrameTopStyle, ScenarioSubFrameTopTitleStyle, ScenarioSubFrameTopTextWrapperStyle, ScenarioConetentsFrameStyle, ScenarioConetentsFrameItemTextStyle, ScenarioConetentsFrameItemStyle,

} from "./Scenario.styled";
import { ContainerSubFrameStyle } from "../../../Container";
import Badge from "../../../common/Badge";

function Scenario(props){
  const scenarioRef = useRef(null);

  useEffect(()=>{
    const element = (scenarioRef.current as HTMLElement)
    if(element){
      if(props.selected){
        if(!element.classList.contains("selected")){
          element.classList.add("selected")
        }
      }else{
        if(element.classList.contains("selected")){
          element.classList.remove("selected")
        }
      } 

    }

  },[props.selected])

  const handleOnClick = (e:any) => {
    if(props.onClick){
      props.onClick(e, props.index);
    }
  }

  return (
    <ScenarioFrameStyle
      ref={scenarioRef}
      onClick={handleOnClick}
    >
      <ScenarioSubFrameTopStyle>
        <ScenarioSubFrameTopTitleStyle>
          시나리오 {props.index+1}
        </ScenarioSubFrameTopTitleStyle>           
        <ScenarioSubFrameTopTextWrapperStyle>
          {props.scenario.title}
        </ScenarioSubFrameTopTextWrapperStyle>
      </ScenarioSubFrameTopStyle>
      <ScenarioConetentsFrameStyle>
        <ScenarioConetentsFrameItemStyle>
          <Badge variant="brand-subtle" text="줄거리"></Badge>
          <ScenarioConetentsFrameItemTextStyle>
            {props.scenario.story}
          </ScenarioConetentsFrameItemTextStyle>
        </ScenarioConetentsFrameItemStyle>
        <ScenarioConetentsFrameItemStyle>
          <Badge variant="brand-subtle" text="분위기"></Badge>
          <ScenarioConetentsFrameItemTextStyle>
            {props.scenario.mood}
          </ScenarioConetentsFrameItemTextStyle>
        </ScenarioConetentsFrameItemStyle>
      </ScenarioConetentsFrameStyle>
    </ScenarioFrameStyle>
  )
}

export default Scenario