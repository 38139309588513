import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EffectTabIcon4 } from "../../../../../Components/pages/icons/EffectTabIcon4";
import { EffectTabIcon5 } from "../../../../../Components/pages/icons/EffectTabIcon5";
import { EffectTabIcon1 } from "../../../../../Components/pages/icons/EffectTabIcon1";
import { EffectTabIcon2 } from "../../../../../Components/pages/icons/EffectTabIcon2";
import { EffectTabWrapper } from "../../../../../Components/pages/Editor/EffectTabWrapper";
import { EffectTabIcon3 } from "../../../../../Components/pages/icons/EffectTabIcon3";

const StyledTabHeader = styled.div`
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--tokens-color-border-secondary-duplicate);
  box-shadow: 0px 2px 6px #00000033;
  display: flex;
  gap: var(--tokens-spacing-04-duplicate);
  left: 0;
  padding: var(--tokens-spacing-none-duplicate) 16px var(--tokens-spacing-none-duplicate) 16px;
  position: absolute;
  top: 0;
  width: 432px;

  & .tab-2 {
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
  }

  & .tab-icon-2 {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
`;

interface Props {
  selectNum: number;
  onClick: any;
}

export const TabHeader = ({selectNum, onClick}: Props): JSX.Element => {
  
  const [isSelected, setIsSelected] = useState([true, false, false, false, false]);
  
  useEffect(()=>{
    let newList = [false, false, false, false, false];
    newList =isSelected.map((val, index)=>{
      if(selectNum == index) {
        return true;
      } else {
        return false;
      }
    });
    setIsSelected(newList);  
  }, [selectNum]);

  return (
    <StyledTabHeader>
      <EffectTabWrapper 
        index={0}
        className="tab-2" 
        icon={<EffectTabIcon1 state={isSelected[0]?"selected":"default"}/>} 
        isSelected={isSelected[0]}
        text="장면추가"
        onClick={onClick}
      />
      <EffectTabWrapper
        style={{display:"none"}}
        index={1}
        className="tab-2"
        icon={<EffectTabIcon2 state={isSelected[1]?"selected":"default"}/>}
        isSelected={isSelected[1]}
        text="이미지수정"
        onClick={onClick}
      />
      <EffectTabWrapper
        index={2}
        className="tab-2"
        icon={<EffectTabIcon3 state={isSelected[2]?"selected":"default"} />}
        isSelected={isSelected[2]}
        text="전환효과"
        onClick={onClick}
      />
      <EffectTabWrapper
        index={3}
        className="tab-2"
        icon={<EffectTabIcon4 state={isSelected[3]?"selected":"default"} />}
        isSelected={isSelected[3]}
        text="글자효과"
        onClick={onClick}
      />
      <EffectTabWrapper
        index={4}
        className="tab-2"
        icon={<EffectTabIcon5 state={isSelected[4]?"selected":"default"} />}
        isSelected={isSelected[4]}
        text="배경음악"
        onClick={onClick}
      />
    </StyledTabHeader>
  );
};