import React, { useEffect, useState, useContext, useRef } from "react";
import { Box, Typography, TextField, Button, Slider, ToggleButton, ToggleButtonProps, Stack, Avatar } from "@mui/material";

import styled from "styled-components";
import { InputStepper } from "../../../../../../../Components/pages/Editor/InputStepper";

import { ReactComponent as MusicPlayIcon } from "../../../../../../../Assets/icon-editor/music-play.svg";
import { ReactComponent as MusicPauseIcon } from "../../../../../../../Assets/icon-editor/music-pause.svg";

import characterSample from "../../../../../../../Assets/CharacterAsset/character.svg";
import { EditorPageContext } from "../../../../../editorPageContext";

import actorDataJson from "../../../../../../../Data/actorData.json"
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { WebGetCreateSpeak, WebPostCreateSpeak } from "../../../../../../../Api/ai";
import { WebGetUrl } from "../../../../../../../Api/resource";
import LoadingScreen from "../../../../../../../Components/common/LoadingScreen";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";

interface Props {
  name: string;
  state: string;
  onCommand: any;
}

const VoicePlayButton = ({ name, state, onCommand }: Props): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "2px 8px",
        borderRadius: 1,
        bgcolor: state=== 'play'? "#B9BEF0" : "#EDEEFB",
        '&:hover': {
          bgcolor: state=== 'play' ? "#B9BEF0" : "#CACEF4",
        },
        width: "fit-content",
        cursor: 'pointer'
      }}
      gap={1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={ ()=> {
        if (state === 'play') onCommand('pause');
        else onCommand('play');
      }}
    >
      <Typography sx={{
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "170%", /* 20.4px */
        letterSpacing: "-0.14px" }} color="#3617CE">
        {name}
      </Typography>
     
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {state === "play" ? (
          <MusicPauseIcon width={16} height={16}/>
        ) : (
          <MusicPlayIcon width={16} height={16}/>
        )}
      </Box>
    </Box>
  );
};

const CharacterButton = styled((props: ToggleButtonProps) => (
  <ToggleButton sx={{
    borderColor: props.selected === true ? '#3617CE':'#E2E4E7',
    borderRadius: "100%",
    width:"56px",
    height:"56px",
    padding: 1,
    whiteSpace: 'normal',
    display:"flex",
  }} {...props} />
))((theme) => {
  return {
  // width: 56,
  // height: 56,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
}});

export const CharacterToggleButtons = ({value='귀여운 여자아이', onChange}): JSX.Element => {

  const [character, setCharacter] = React.useState<string | null>(value);

  useEffect(()=>{setCharacter(value)},[value])

  const handleChangeCharacter = (
    newCharacter: string | null,
  ) => {
    setCharacter(newCharacter);
    onChange(newCharacter);
  };

  return (
    <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
      {actorDataJson.map(actor=>{
        return(
          <div style={{width:"56px", height:"56px"}}>
            <CharacterButton value={actor.name} selected={character===actor.name} onClick={()=> handleChangeCharacter(actor.name)}>
              {actor.name}
              {/* <Avatar alt={actor.name} src={characterSample} /> */}
            </CharacterButton>
          </div>
        )
      })}
      {/* <CharacterButton value="귀여운 남자아이" selected={character==='귀여운 남자아이'} onClick={()=> handleChangeCharacter('귀여운 남자아이')}>
        <Avatar alt="귀여운 남자아이" src={characterSample} />
      </CharacterButton>
      <CharacterButton value="귀여운 여자아이" selected={character==='귀여운 여자아이'} onClick={()=> handleChangeCharacter('귀여운 여자아이')}>
        <Avatar alt="귀여운 여자아이" src={characterSample} />
      </CharacterButton>
      <CharacterButton value="성인 남성" selected={character==='성인 남성'} onClick={()=> handleChangeCharacter('성인 남성')}>
        <Avatar alt="성인 남성" src={characterSample} />
      </CharacterButton>
      <CharacterButton value="성인 여성" selected={character==='성인 여성'} onClick={()=> handleChangeCharacter('성인 여성')} >
        <Avatar alt="성인 여성" src={characterSample} />
      </CharacterButton> */}
      </Stack>
  );
};

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

export const NarrationTabContent = (): JSX.Element => {
  const scenarioContext = useContext(ScenarioContext);
  const [decibel, setDecibel] = useState(100);
  const [voiceCharacter, setVoiceCharacter ] = useState(scenarioContext.selectedTTSActorInfo.name);
  const [voideCommand, setVoiceCommand] = useState('pause');
  const [ntrText, setNtrText] = useState(undefined);

  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);

  const [ttsUrl, setTtsUrl] = useState(undefined)

  const audioRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const actor = scenarioContext.editedTTSActorList[editorPageContext.selectIndex];
      setVoiceCharacter(actor)
      const ntrUlr = editorPageContext.ntrUrlList[editorPageContext.selectIndex];
      // console.log(`Scene${editorPageContext.selectIndex} ntr url :: `, ntrUlr)
      setTtsUrl(ntrUlr);
      setVoiceCommand('pause');
      setDecibel(editorContext.ttsVolumeList[editorPageContext.selectIndex])
      setNtrText(scenarioContext.finalScenario.scenes[editorPageContext.selectIndex].narrationInfo?.text)
    }
  },[editorPageContext.selectIndex])


  // 음량조절
  const handleChangeDecibel = (event: Event, newValue: number | number[]) => {
    setDecibel(newValue as number);
    let volumeList = JSON.parse(JSON.stringify(editorContext.ttsVolumeList));
    volumeList[editorPageContext.selectIndex] = newValue as number;
    editorContext.setTtsVolumeList(volumeList);
  };

  // 볼륨 순서 바뀔 시 재할당
  useEffect(()=>{
    // console.log("tts volume changed",editorContext.ttsVolumeList)
    setDecibel(editorContext.ttsVolumeList[editorPageContext.selectIndex||0]);
  },[editorContext.ttsVolumeList])

  // TTS URL 순서 변경 시 재할당
  useEffect(()=>{
    // console.log("tts url list changed",editorPageContext.ntrUrlList)
    setTtsUrl(editorPageContext.ntrUrlList[editorPageContext.selectIndex||0]);
  },[editorPageContext.ntrUrlList])

  const handleChangeCharacter = async (newCharacter: string) => {
    let newList = JSON.parse(JSON.stringify(scenarioContext.editedTTSActorList));
    newList[editorPageContext.selectIndex]=newCharacter
    scenarioContext.setEditedTTSActorList(newList)

    await genTTS(newCharacter)

    setVoiceCharacter(newCharacter);

    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
    finalScenario.scenes[editorPageContext.selectIndex].narrationInfo.text=ntrText;
    scenarioContext.setFinalScenario(finalScenario);

    if(voideCommand === 'play')
      setVoiceCommand('pause');
  };

  const handleVoiceCommand = (command: string) => {
    setVoiceCommand(command);
  }

  useEffect(()=>{
    const audioEl = audioRef.current
    if(audioEl){
      switch(voideCommand){
        case "play":
          audioEl.play();
          break;
        case "pause":
          audioEl.pause();
          audioEl.currentTime=0;
          break;
      }
    }
  },[voideCommand])

  useEffect(()=>{
    const audioEl = audioRef.current
    if(audioEl){
      audioEl.volume=(decibel||100)/100
    }
  },[decibel])

  useEffect(()=>{
    // genTTS();
  },[voiceCharacter])

  async function genTTS(voiceCharacter){
    if(ntrText && (ntrText!=="" && ntrText!==" ")){
      setIsLoading(prev=>true)
      const idx = actorDataJson.findIndex(actor=>actor.name===voiceCharacter);
      const actorInfo = actorDataJson[idx];  
  
      const createTTSResponse = await WebPostCreateSpeak({
        assetId: sessionStorage.getItem("ASSET_ID"),
        token: process.env.REACT_APP_NTR_TOKEN,
        text:ntrText,
        actorId: actorInfo.id,
        language:"auto"
      })

      if(createTTSResponse.result===0){
        // console.log("tts url ::" , createTTSResponse.speakResult.speak_v2_url);
        try{
          const ttsPromise:any = await new Promise(async(resolve, reject)=>{
            const ttsInterval = setInterval(async ()=>{
              const getTTSResponse = await WebGetCreateSpeak({
                assetId: sessionStorage.getItem("ASSET_ID"),
                sceneNo: editorPageContext.selectIndex,
                speakUrl: createTTSResponse.speakResult.speak_v2_url
              },process.env.REACT_APP_NTR_TOKEN)
              if(getTTSResponse.result===0){
                switch(getTTSResponse.status){
                  case "progress":
                    console.log("create tts on progress...")
                    break;
                  case "done":
                    clearInterval(ttsInterval);
                    console.log("create tts finished")
                    resolve(getTTSResponse.speakResult);
                    break;
                  case "failed":
                    console.error("get TTS failed : ", getTTSResponse.errMsg);
                    clearInterval(ttsInterval);
                    reject(`get TTS error : ${getTTSResponse.errMsg}`)
                    break;
                }
              }else{
                console.error("get TTS error : ", getTTSResponse.errMsg);
                clearInterval(ttsInterval);
                reject(`get TTS error : ${getTTSResponse.errMsg}`)
              }
            }, 500)
          })
          const ttsUrl = await WebGetUrl({
            assetId: sessionStorage.getItem("ASSET_ID"),
            bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
            key: ttsPromise.path
          })

          if(ttsUrl.result===0){
            setTtsUrl(ttsUrl.presignedUrl)
          }

        }catch(err){console.error("TTS error ", err)}
      }
    }
    setIsLoading(prev=>false)
  }

  const handleChangeNtrText = (event:any) => {
    setNtrText(event.target.value);
  }

  return (
    <StyledFrame>
      <TextField variant="outlined" fullWidth multiline rows={4} placeholder="Text" sx={{ borderColor: "#E2E4E7", marginTop: "-14px"}} value={ntrText} onChange={handleChangeNtrText}/>
      
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}} width="100%">
        <Typography variant="body2" color="secondary" sx={{ width: 40 }}>음량</Typography>
        <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={decibel} min={0} max={100} onChange={handleChangeDecibel}/>
        <InputStepper unitText="%" value={decibel} width={140} min={0} max={100} onChange={(val:number)=>{setDecibel(val)}} />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} width="100%">
        <Typography variant="caption" color="text.secondary">나레이션 캐릭터</Typography>
        <VoicePlayButton name={voiceCharacter} state={voideCommand} onCommand={handleVoiceCommand} ></VoicePlayButton>
        <audio ref={audioRef} onEnded={()=>{setVoiceCommand("pause")}} src={ttsUrl}/>
      </Box>
      
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} width="100%">
        <CharacterToggleButtons value={voiceCharacter} onChange={handleChangeCharacter}/>
      </Box>
      {isLoading &&
        <div style={{position:"absolute",width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center", backgroundColor:"rgba(0,0,0,0.5)"}}>
          <LoadingScreen text=""/>
        </div>
      }
    </StyledFrame>
  );
};