import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter, Link, Route, Router, Routes, useNavigate, useLocation } from "react-router-dom"
import MyAd from "../MyAd"
import Modal from "../Modal"

import Alert from "../Alert"
import ProgressBar from "../common/ProgressBar"
import Button from "../common/Button"
import Icon from "../Icon"

import styled, { keyframes } from "styled-components"

import AlertModal from "../common/AlertModal"
import { ReactComponent as CloseIcon } from "../../Assets/ModalIllu/Close.svg"


import { AsideStyle, ProgressStyle, AsideFrameStyle, AsideTextFrameStyle, LinkStyle, MainTextStyle, SubTextStyle, NameTextStyle} from './Aside.styled'
import { ShopInfoContext } from "../../Contexts/ShopInfoContext"

const CALLBACK_URL = `https://${process.env.REACT_APP_BTV_TOWN_CALLBACK_URL}`



function Aside(props){
  const [exitAlert, setExitAlert] = React.useState<boolean>(false);

  const location = useLocation();

  useEffect(()=>{
    switch(props.step){
      case 1:
        nav('/')
        break;
      case 2:
        nav('/point')
        break;
      case 3:
        nav('/scenario')
        break;
      case 4:
        nav("/edit")
        break;
    }
  },[props.step])

  const onClickExitButton = (e:React.MouseEvent) =>{
    // console.log("return to my ad...")
    setExitAlert(true);
  }
  const closeExitAlert = (e:any) => {
    // console.log("close exit alert")
    setExitAlert(false)
  }
  const alertIcon = <CloseIcon/>
  const alertText=<>
    <span>AI 우리광고 만들기를 벗어나면<br/></span>
    <span>진행된 내용은 저장되지 않습니다.</span>
  </>

  const handleOK = () => {
    window.parent.location.replace(CALLBACK_URL)
  }

  const nav = useNavigate();
  const handleStep = (stepNumber)=>{
    if(stepNumber <= shopInfoContext.completedStep+1){
      console.log("step to ", stepNumber)
      props.setStep(stepNumber)
      switch(stepNumber){
        case 1:
          nav('/');
          break;
        case 2:
          nav('/point');
          break;
        case 3:
          nav('/scenario');
          break;
        case 4:
          nav('/edit');
          break;
      }
    }
  }

  const [mainText, setMainText] = useState(<></>);
  const [subText, setSubText] = useState(<></>);

  const [userName,setUserName]=useState(sessionStorage.getItem("USER_NAME"));
  const shopInfoContext = useContext(ShopInfoContext);
  const {shopName, isGenerating} = shopInfoContext

  const [slicedShopName, setSlicedShopName] = useState(undefined);
  useEffect(()=>{
    if(shopName.length < 15){
      setSlicedShopName(shopName)
    }else{
      const sliced = shopName.slice(0,15) + "...";
      setSlicedShopName(sliced);
    }
  },[shopName])

  useEffect(()=>{
    setUserName(props.name);
  },[props.name])

  useEffect(()=>{
    if(!userName){
      const nameOnStorage = sessionStorage.getItem("USER_NAME")
      if(nameOnStorage){
        setUserName(nameOnStorage);
      }
    }
  },[userName])


  useEffect(()=>{
    switch(props.step){
      case 1:
        setMainText(
          <TextWrapper>
            <NameTextStyle>{userName}</NameTextStyle>
            {" "}
            님 반갑습니다!
            <br />
            함께 광고를 만들어볼까요?
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
            놀라운 광고를 함께 만들어내는 여정에 동참해주셔서 감사합니다.<br/>
            AI와 함께 창의적이고 효과적인 광고를 만들어 보아요.<br/>
            지금 저와 같이 시작해요~<br/>
          </TextWrapper>
        )
        break;
      case 2:
        setMainText(
          <TextWrapper>
            AI가 <NameTextStyle>{shopName}</NameTextStyle>의 정보를 분석해봤어요.<br/>
            탄탄한 정보를 바탕으로 광고를 만들어요.
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
            AI가 고객님들이 {userName}님의 가게를 어떻게 이용하시는지,<br/>
            데이터를 분석해봤습니다.
          </TextWrapper>
        )

        break;
      case 3:
        setMainText(
          <TextWrapper>
            AI가 <NameTextStyle>{shopName}</NameTextStyle>의<br/>시나리오를 만들었어요.<br/>
            멋진 광고가 탄생할 것<br/>같아요~!
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
            AI가 멋진 시나리오 세 가지를 만들어 보았습니다.
          </TextWrapper>
        )
        break;
      case 4:
        if(isGenerating){
          setMainText(
            <TextWrapper>
              AI가 현재 광고를<br/>
              만들고 있습니다.
            </TextWrapper>
          );
          setSubText(
            <TextWrapper>
              AI가 고객님들이 {userName}님의 가게를 어떻게 이용하시는지,<br/>
              데이터를 분석해봤습니다.
            </TextWrapper>
          );
        }else{
          setMainText(
            <TextWrapper>
              AI가 <NameTextStyle>{shopName}</NameTextStyle>의 멋진 광고를 <NameTextStyle>{userName}</NameTextStyle>님과 함께 제작해보았습니다.<br/>
            </TextWrapper>
          );
          setSubText(
            <TextWrapper>
              AI가 멋진 시나리오 세 가지를 만들어보았습니다.
            </TextWrapper>
          );
        }
        break;
    }
  },[props.step, userName, isGenerating])

  const [move,setMove] = useState(false);

  return (
    <AsideStyle style={{display:props.step===5?"none":"flex"}}>
      <AsideFrameStyle>
        <Button
          $buttonType="text-l"
          showLeftIcon={true}
          leftIcon="House"
          text={"나의 광고로 돌아가기"}
          onClick={onClickExitButton}
        />
        <AsideTextFrameStyle>
          <MainTextStyle className={move?"move":undefined}>{mainText}</MainTextStyle>
          <SubTextStyle>{subText}</SubTextStyle>
        </AsideTextFrameStyle>
      </AsideFrameStyle>
      <ProgressStyle>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(1)}} number={1} text="우리 매장 정보 입력" status={getStatus(1,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(2)}} number={2} text="AI로 우리가게 특징 찾기" status={getStatus(2,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(3)}} number={3} text="AI 시나리오 만들기" status={getStatus(3,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(4)}} number={4} text="광고 영상 만들기" status={getStatus(4,props.step)}/>
      </ProgressStyle>
      <AlertModal
        icon={alertIcon}
        description={alertText}
        show={exitAlert}
        onCancel={closeExitAlert}
        onConfirm={handleOK}
      />      
    </AsideStyle>
  )

  function getStatus(num, step){
    if(num>step){
      return "todo"
    }else if(num===step){
      return "ing"
    }else{
      return "done"
    }
  }
}

export default Aside

const TextWrapper = styled.div`
  word-break: break-all;
`