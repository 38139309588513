import styled from "styled-components";

export const stateEnum={
  "default":"default",
  "filled":"filled",
  "active":"active",
  "invalid":"invalid",
  "disabled":"disabled"
}
export const sizeEnum={
  "sm":"sm",
  "md":"md",
  "lg":"lg"
}

export const InputTextStyle = styled.input`
  background:none;
  border: ${(props)=>props.theme.border["border-02"]} solid;
  
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  position:relative;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]}`
  }};
  width:100%;
  box-sizing: border-box;
  ${(props)=>props.theme.typography["pre-body-02"]};
  ${(props)=> matchSize(props.size, props.theme)};
  ${(props)=> matchState(props.state, props.theme)};

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none; 
  }

  &::placeholder{
    color:${(props)=>props.theme.colors["text-quaternary"]};
  }

  &:focus{
    outline:none;
  }
`
const matchSize = (size, theme) => {
  switch(size){
    case sizeEnum["sm"]:
      return {
        height: "50px"
      }
    case sizeEnum["md"]:
      return {
        height: "50px"
      }
    case sizeEnum["lg"]:
      return {
        height: "50px"
      }
    default:
      return {
        height: "50px"
      }
  }
}
const matchState = (state, theme) => {
  switch(state){
    case stateEnum['default']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"]
      }
    case stateEnum['filled']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-primary"]
      }
    case stateEnum['active']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-active"]
      }
    case stateEnum['invalid']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["support-error"]
      }
    case stateEnum['disabled']:
      return{
        color: theme.colors["text-quaternary"],
        opacity: 0.4,
        borderColor: theme.colors["border-primary"]
      }
    default:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"]
      }
  }
}