import React from "react";
import { BadgeStyle } from "./Badge.styled";

type BadgeColor = "brand-subtle"|"brand2"|"white"|"gray"

interface BadgeInterface extends React.ComponentProps<any>{
  text?:string,
  color?:BadgeColor,
}

function Badge(props){
  return (
    <BadgeStyle {...props}>
      {props.text}
    </BadgeStyle>
  )
}
export default Badge