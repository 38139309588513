import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { InputStepper } from "../../../../../../../Components/pages/Editor/InputStepper";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { ToggleButtons } from "../../../../../../../Components/pages/Editor/ToggleButtons";
import { EditorPageContext } from "../../../../../editorPageContext";
import { ImageListItem } from "../../../../../../../Components/pages/Editor/ImageListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const StyledFilterBox = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  border: 1px solid;
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-radius: var(--tokens-radius-none-duplicate);
  height: 72px;
  position: relative;
  width: 128px;
`
const StyledFilterWrapper = styled.div`
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-04-duplicate);
  justify-content: center;
  padding: 0px 0px var(--tokens-spacing-07-duplicate) 0px;
  position: relative;
  width: 100%;

  .frame-20 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .text-wrapper-13 {
    color: var(--tokens-color-text-secondary-duplicate);
    flex: 1;
    font-family: var(--pre-caption-01-font-family);
    font-size: var(--pre-caption-01-font-size);
    font-style: var(--pre-caption-01-font-style);
    font-weight: var(--pre-caption-01-font-weight);
    letter-spacing: var(--pre-caption-01-letter-spacing);
    line-height: var(--pre-caption-01-line-height);
    margin-top: -1px;
    position: relative;
  }

  .icon-29-instance {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }

  .placeholder-5 {
    color: var(--tokens-color-text-tertiary-duplicate);
    flex: 1;
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    position: relative;
  }

  .img-2 {
    background-color: var(--tokens-color-background-secondary-duplicate);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-none-duplicate);
    height: 72px;
    position: relative;
    width: 128px;
  }

  .frame-21 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-04-duplicate);
    position: relative;
    width: 100%;
  }

  .frame-22 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  .placeholder-6 {
    color: var(--tokens-color-text-tertiary-duplicate);
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    margin-top: -1px;
    position: relative;
    width: 40px;
  }

  .bar-7 {
    flex: 1 !important;
    flex-grow: 1 !important;
    position: relative !important;
    width: unset !important;
  }

  .bar-8 {
    width: 52px !important;
  }

  .bar-9 {
    width: 46px !important;
  }

  .bar-10 {
    left: 40px !important;
  }

  .input-stepper-3 {
    align-items: center;
    background-color: var(--tokens-color-background-primary-duplicate);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: var(--tokens-spacing-06-duplicate);
    height: 32px;
    padding: 0px var(--tokens-spacing-04-duplicate) 0px var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  .frame-23 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    position: relative;
  }

  .placeholder-7 {
    color: var(--tokens-color-text-primary-duplicate);
    font-family: var(--pre-body-06-font-family);
    font-size: var(--pre-body-06-font-size);
    font-style: var(--pre-body-06-font-style);
    font-weight: var(--pre-body-06-font-weight);
    letter-spacing: var(--pre-body-06-letter-spacing);
    line-height: var(--pre-body-06-line-height);
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }

  .step-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .icon-4 {
    height: 12px !important;
    position: relative !important;
    width: 12px !important;
  }
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const VideoTabContent = ({index}): JSX.Element => {
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [degree, setDegree] = useState(0);
  const [rotation, setRotation] = useState('left');
  const [opacity, setOpacity] = useState(0);

  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext)
  const shopInfoContext = useContext(ShopInfoContext)

  // useEffect(()=>{
  //   const targetClip = editorPageContext.selectedItem.clip;
  //   if(targetClip){
  //     targetClip.setContainerValue("lock", "none")
  //     targetClip.setContainerValue("offW",true)
  //     targetClip.setContainerValue("offS",true)
  //   }
  // },[editorPageContext.selectedItem.clip])


  const handleChangeX = (event: Event, newValue: number | number[]) => {
    const sizeX = newValue as number;
    setSizeX(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("width", sizeX/100);
    // }
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    const sizeY = newValue as number;
    setSizeY(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("height", sizeY/100);
    // }
  };

  const handleChangeOpacity = (event: Event, newValue: number | number[]) => {
    setOpacity(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // /// editorPageContext에서 현재 선택된 클립 선택
    // targetClip.setContainerValue("opacity", (newValue as number)/100)
    // //clip.setContainerValue, clip.setContentValue 등으로 수치 조정
  };


  const handleChangePositionX = (newValue: number | number[]) => {
    const posX = newValue as number; //pixel
    const posXConvert = posX/1920; //ratio
    // console.log(`posX${posX} / ${posXConvert}`)
    setPositionX(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("x", posXConvert);
    // }
  };

  const handleChangePositionY = (newValue: number | number[]) => {
    const posY = newValue as number; // pixel
    const posYConvert = posY/1080; //ratio
    setPositionY(newValue as number);
    // const targetClip = editorPageContext.selectedItem.clip;
    // if(targetClip){
    //   console.log("clip :: ", targetClip)
    //   targetClip.setContainerValue("y", posYConvert);
    // }
  };

  // useEffect(()=> {
  //   console.log(rotation);
  // }, [rotation]);

  const [url, setUrl] = useState(undefined);
  const [scene, setScene] = useState(undefined);
  useEffect(()=>{
    console.log(scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex]);
    const scene = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex];
    if(!scene){return}
    const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName);
    if(fileIndex<0){
      setUrl(undefined)
      return
    }
    const file = shopInfoContext.editedFiles[fileIndex].file;
    const url = URL.createObjectURL(file);
    setScene(scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex]);
    setUrl(url);
  },[editorPageContext.selectIndex,scenarioContext.finalScenario])

  return(
    <StyledFrame>
      {url&&
        <ImageListItem
          contentType={scene.type}
          property1="file"
          state="default"
          fileName={scene.fileName}
          url={url}
          onAdd={undefined}
          onDelete={undefined}
        />      
      }
    </StyledFrame>
  )

  // return (
  //   <StyledFrame>
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel1-content"
  //         id="panel1-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption"  sx={{ flex: 1, color: 'grey.700' }}>사이즈</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //         <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <Typography variant="body2" color="secondary" sx={{ width: 40 }}>X</Typography>
  //           <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={sizeX} onChange={handleChangeX}/>
  //           <InputStepper unitText="%" value={sizeX} width={140} min={0} max={100} onChange={(val:number)=>{setSizeX(val)}} />
  //         </Box>
  //         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //           <Typography variant="body2" color="secondary" sx={{ width: 40 }}>Y</Typography>
  //           <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={sizeY} onChange={handleChangeY}/>
  //           <InputStepper unitText="%" value={sizeY} width={140} min={0} max={100} onChange={(val:number)=>{setSizeY(val)}}/>
  //         </Box>
  //       </AccordionDetails>
  //     </Accordion >
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel2-content"
  //         id="panel2-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>위치</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //         <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <LabeledInputStepper value={positionX} text="X" width={140} onChange={handleChangePositionX}/>
  //           <LabeledInputStepper value={positionY} text="Y" width={140} onChange={handleChangePositionY}/>
  //         </Box>
  //         {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //           <LabeledInputStepper value={degree} text="각도" width={140} onChange={(val:number)=>setDegree(val)}/>
  //           <ToggleButtons value={rotation} onChange={(val:string)=>setRotation(val)} />
  //           <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //             <IconButton>
  //             </IconButton>  
  //           </Box>
  //         </Box>     */}
  //       </AccordionDetails>
  //     </Accordion>
  //     <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
  //       <AccordionSummary
  //         expandIcon={<ArrowDropDownIcon />}
  //         aria-controls="panel3-content"
  //         id="panel3-header"
  //         sx={{ padding:0, minHeight:21, height: 21 }}
  //       >
  //         <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>필터</Typography>
  //       </AccordionSummary>
  //       <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
  //           <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  //             <Typography variant="body2" color="secondary" sx={{ width: 60 }}>영상 필터 : </Typography>
  //             <Typography variant="body2" color="secondary" sx={{ flex: 1 }}>&amp;&amp;&amp;&amp;&amp;&amp;</Typography>
  //             <StyledFilterBox />
  //           </Box>
  //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
  //             <Typography variant="body2" color="secondary" sx={{ width: 40 }}>투명도</Typography>
  //             <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={opacity} onChange={handleChangeOpacity}/>
  //             <InputStepper unitText="%" value={opacity} width={140} min={0} max={100} onChange={(val:number)=>{setOpacity(val)}} />
  //           </Box>
  //       </AccordionDetails>
  //     </Accordion>
  //     <Box display="flex" alignItems="center" gap={2} width="100%" flex="0 0 auto" position="relative">
  //       <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', flex: 1 }}>
  //         <Typography variant="body2" color="secondary">변환하기 </Typography>
  //         <Typography variant="caption" color="grey.400">이미지를 동영상으로, 동영상을 이미지로 </Typography>
  //       </Box>
  //       <Box display="inline-flex" height={28} alignItems="center" justifyContent="center" gap={1} pr={1} pl={1} py={0} position="relative" flex="0 0 auto" bgcolor="grey.100" borderRadius={1}>
  //         <Button variant="text" sx={{ p: 0, minWidth: 'fit-content', color: "rgba(68,75,82,1)" }}>적용</Button>
  //       </Box>
  //     </Box>
  //   </StyledFrame>
  // );
};