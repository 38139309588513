import React from "react";
import styled from "styled-components";

const DotDivStyle = styled.div`
  width:4px;
  height:4px;
`

const DotStyle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props)=>props.theme.colors["border-brand"]}
`

function Dot(){
  return (
    <DotDivStyle>
      <DotStyle/>
    </DotDivStyle>
  )
}

export default Dot