import styled from "styled-components";
import { ReactComponent as ResizerIcon } from "../../../Assets/Icons/textarea-resizer.svg";

export const TextareaStyle = styled.textarea`
  align-items: flex-start;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border:${(props)=>props.theme.border["border-02"]} solid;
  border-color:${(props)=>props.theme.colors["border-secondary"]};
  border-radius:${(props)=>props.theme.radius["radius-02"]};
  display:flex;
  width: calc(100% - ${(props)=>props.theme.spacing["spacing-11"]});
  max-width: calc(100% - ${(props)=>props.theme.spacing["spacing-11"]});

  resize:both;

  gap:${(props)=>props.theme.spacing["spacing-01"]};
  
  overflow: scroll;
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-04"]} ${spacing["spacing-07"]} ${spacing["spacing-04"]} ${spacing["spacing-07"]}`
  }};
  position:relative;
  ${(props)=>props.theme.typography["pre-body-02"]}
  ${(props)=> matchState(props.state, props.theme)}

  &:disabled{
    color:${(props)=>props.theme.colors["text-quaternary"]};
    border: ${(props)=>props.theme.border["border-02"]} soild;
    border-color:${(props)=>props.theme.colors["border-secondary"]};
    opacity: 0.4;
  }

  &.pull-tab{
    
  }
`

function matchState(state, theme){
  switch(state){
    case "default":
      return{
        color: theme.colors["text-quaternary"],
        border: `${theme.border["border-02"]} solid`,
        borderColor: theme.colors["border-secondary"]
      }
    case "filled":
      return{
        color: theme.colors["text-primary"],
        border: `${theme.border["border-02"]} solid`,
        borderColor: theme.colors["border-secondary"]
      }
    case "active":
      return{
        color: theme.colors["text-primary"],
        border: `${theme.border["border-02"]} solid`,
        borderColor: theme.colors["border-active"]
      }
    case "invalid":
      return{
        color: theme.colors["text-quaternary"],
        border: `${theme.border["border-03"]} solid`,
        borderColor: theme.colors["support-error"]
      }
    case "disabled":
      return{
        color: theme.colors["text-quaternary"],
        border: `${theme.border["border-02"]} solid`,
        borderColor: theme.colors["border-secondary"],
        opacity: 0.4,
      }
    default:
      return{
        color: theme.colors["text-quaternary"],
        border: `${theme.border["border-02"]} soild`,
        borderColor: theme.colors["border-secondary"]
      }
  }
}

export const TextareaResizerStyle = styled.div`
  height: 8px;
  width: 8px;
  position:absolute;
  right: 10px;
  bottom: 10px;
`

export const ResizerIconStyle = styled(ResizerIcon)`
  cursor:pointer;
`