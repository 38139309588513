import React, { useContext } from "react";
import styled from "styled-components";
import { Icon7 } from "../../icons/Icon7";
import { useNavigate } from "react-router-dom";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { EditorContext } from "../../../../Contexts/EditorContext";

const StyledHeader = styled.div`
  background-color: var(--tokens-color-background-primary-duplicate);
  display: flex;
  // width: 1440px;
  height: 64px;
  // padding: 14px 16px;
  justify-content: center;
  align-items: center;
  // gap: 801px;

  .frame-14 {
    align-items: center;
    display: inline-flex;
    gap: var(--tokens-spacing-04-duplicate);
    left: 16px;
    position: absolute;
    // top: 18px;
  }

  .p {
    color: var(--color-text-primary, #17191C);
pre/body/03 
font-family: "Pretendard Variable";
font-size: 16px;
font-style: normal;
font-weight: 600;
// line-height: 170%; /* 27.2px */
letter-spacing: -0.16px;
  }

  .frame-15 {
    align-items: center;
    background-color: var(--tokens-color-badge-brand2-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 0px var(--tokens-spacing-04-duplicate) 0px var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  .text-wrapper-9 {
    color: var(--tokens-color-text-primary-inverse-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    
    margin-top: 0px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-16 {
    align-items: center;
    display: inline-flex;
    
    gap: 8px;
    right: 16px;
    position: absolute;
    // top: 14px;
  }

  .button-capsule {
    align-items: center;
    background-color: var(--tokens-color-button-brand1-subtle-duplicate);
    border: 1.5px solid var(--color-border-brand, #3617CE);
    // border-color: var(--tokens-color-border-brand-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    height: 36px;
    justify-content: center;
    padding: 3px var(--tokens-spacing-07-duplicate) 0px var(--tokens-spacing-07-duplicate);
    position: relative;
    cursor: pointer;
  }

  .icon-7 {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
    margin-bottom: 0px;
  }

  .button {
    all: unset;
    box-sizing: border-box;
    color: var(--tokens-color-text-brand1-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .button-wrapper {
    align-items: center;
    background-color: var(--tokens-color-button-brand1-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    height: 36px;
    justify-content: center;
    padding: 3px var(--tokens-spacing-07-duplicate) 0px var(--tokens-spacing-07-duplicate);
    position: relative;
    cursor: pointer;
  }

  .button-2 {
    all: unset;
    box-sizing: border-box;
    color: var(--tokens-color-text-primary-inverse-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;


export const Header = (props): JSX.Element => {
  const nav = useNavigate();
  const shopInfoContext = useContext(ShopInfoContext)
  const editorContext = useContext(EditorContext);
  
  const handleOnClickReturn=()=>{
    console.log("go back to pages")
    
    console.log("step :: ", shopInfoContext.completedStep)

    switch(shopInfoContext.completedStep){
      case 0:
        props.setStep(1)
        nav("/")
        break;
      case 1:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/point")
        break;
      case 2:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/scenario")
        break;
      case 3:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/edit")
        break;
      case 4:
        props.setStep(4)
        nav("/edit")
        break;
    }
  }

  const handleExportVideo = () => {
    editorContext.setVideoCreated(false);
    editorContext.setIsEdited(true);
    props.setStep(4)
    nav("/edit")
  }


  return (
    <StyledHeader>
      <div className="frame-14">
        <p className="p">[{shopInfoContext.shopName}] Ai 광고 만들기</p>
        <div className="frame-15">
          <div className="text-wrapper-9">광고 수정중</div>
        </div>
      </div>
      <div className={`frame-16`}>
        <div className="button-capsule" onClick={handleOnClickReturn}>
          <Icon7 className="icon-7" color="#3617CE" />
          <button className="button">AI 시나리오로 돌아가기</button>
        </div>
        <div className="button-wrapper" onClick={handleExportVideo}>
          <button className="button-2">광고 내보내기</button>
        </div>
      </div>
    </StyledHeader>
  );
};