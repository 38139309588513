import styled from "styled-components"


export const BadgeStyle= styled.div`
  user-select:none;
  align-items:center;
  text-align: center;
  border-radius:9999px;
  height:24px;
  width: ${(props)=> props.width? props.width : `fit-contents` };
  
  padding: 0px ${(props)=>props.theme.spacing["spacing-04"]};
  text-decoration: none;
  box-sizing: border-box;

  ${(props)=> matchColors(props.variant, props.theme)};
  ${(props)=> props.theme.typography["pre-body-05"]}
`

function matchColors(variant, theme){
  switch(variant){
    case "brand-subtle":
      return{
        backgroundColor : theme.colors["badge-brand1"],
        color : theme.colors["text-brand1"]
      }
    case "brand2":
      return{
        backgroundColor : theme.colors["badge-brand2"],
        color : theme.colors["text-primary-inverse"]
      }
    case "white":
      return{
        backgroundColor : theme.colors["badge-whitle"],
        color : theme.colors["text-primary"]
      }
    case "gray":
      return{
        backgroundColor : theme.colors["badge-gre-subtle"],
        color : theme.colors["text-secondary"]
      }
  }
}