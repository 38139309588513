import React, { useEffect, useRef, useState } from "react";

import { InputSearchStyle,InputStyle } from "./InputSearch.styled";

import Icon from "../Icon";

type Size = "sm"|"md"|"lg"
type State = "default"|"filled"|"active"|"invalid"|"disabled"

interface InputSearchInterface{
  size?:Size,
  state?:State,
  placeholder?:string,
  width?:number,
  height?:number,
  radius?:number,
  border?:number,
  padding?:number,
  gap?:number,
  value?:any,
  onChange?:(e:any)=>void,
  disabled?:boolean
}

const st = ["default","filled","active","invalid","disabled"];



function InputSearch(props){
  const ref = useRef(null);

  useEffect(()=>{
    if(props.disabled){
      if(ref.current){
        const el = (ref.current as HTMLElement)
        if(!el.classList.contains("disabled")){
          el.classList.add("disabled")
        }
      }
    }
  },[props.disabled])

  const handleOnFocus = (e:any)=>{
    if(ref.current){
      const el = ref.current as HTMLElement
      
      if(!el.classList.contains("active")){
        el.classList.add("active")
      }
    }
  }

  const handleOnBlur = (e:any)=>{
    if(ref.current){
      const el = ref.current as HTMLElement
      
      if(el.classList.contains("active")){
        el.classList.remove("active")
      }
    }
  }


  return (
    <InputSearchStyle
      ref={ref}            
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}      
    >
      <InputStyle
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        style={{
          width:"100%",
          height:"100%",
          boxSizing:"border-box",
          outline:"none",
          border:"none"
        }}
        disabled={props.disabled}
      />
      <Icon color={"icon-primary"} icon={"MagnifyingGlass"}/>
    </InputSearchStyle>
  )
}

export default InputSearch