import styled from "styled-components"

export const InputLabelStyle = styled.div`
  display: inline-flex;
  align-items : center;

  gap:${(props)=>props.theme.spacing["spacing-02"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  

  &.sm{
    ${(props)=>props.theme.typography["pre-body-05"]}
  }

  &.md{
    ${(props)=>props.theme.typography["pre-body-03"]}
  }
  width:100%;
`
export const InputLabelTextWrapperStyle = styled.div`
  width:fit-content;
`