import React, {ReactNode, useState, useEffect, useContext, useRef} from "react";
import { useSearchParams } from "react-router-dom";

import Container, {ContainerFrameStyle, ContainerSubFrameStyle, }  from "../../Components/Container";
import QuestionTitle from "../../Components/common/QuestionTitle";

import "./ShopInfo.css"

import styled from "styled-components";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import { ShopPointContext } from "../../Contexts/ShopPointContext";

import Button from "../../Components/common/Button";
import InputText from "../../Components/common/InputText";
import InputTextGroup from "../../Components/common/InputTextGroup";
import InputSelectGroup from "../../Components/common/InputSelectGroup";
import ButtonQuestion from "../../Components/common/ButtonQuestion";
import InputLabel from "../../Components/common/InputLabel";
import Divider from "../../Components/common/Divider";
import ContentUnit from "../../Components/common/ContentUnit";
import ModalAddress from "../../Components/pages/ShopInfo/ModalAddress";
import Question from "../../Components/common/Question";

import AlertModal from "../../Components/common/AlertModal";

import categories from '../../Assets/category.json'

import { ReactComponent as QuestionIcon } from "../../Assets/ModalIllu/Question.svg";
import { useNavigate } from "react-router-dom";
import AiImage from "../../Components/pages/ShopInfo/AiImage";
import VideoTimeline from "../../Components/pages/ShopInfo/VideoTimeline";

import {CallFrameStyle, CallInputsStyle, FileListStyle, ModalFrameStyle, TextStyle, ModalTextWrapperStyle, ModalPStyle, CallLabelWrapperStyle} from './ShopInfo.styled'
import { WebGetAnalyzeImage, WebGetAnalyzeStore, WebPostAnalyzeImage, WebPostAnalyzeStore } from "../../Api/ai";

import {WebPostCreateScenarioCandidate, WebGetCreateScenarioCandidate} from "../../Api/ai"
// 매장특징분석페이지 API

import { WebCreateBGM, WebGetCreateBGMTag, WebGetCreateNarration, WebGetCreateScenario, WebGetCreateSpeak, WebGetGenLayout, WebGetSelectTTSActor, WebPostCreateBGMTag, WebPostCreateBGMTagCode, WebPostCreateNarration, WebPostCreateScenario, WebPostCreateSpeak, WebPostFinalScenario, WebPostGenLayout, WebPostSelectTTSActor, WebPostText2Image, WebGetAIGernatingStatus, WebPostImage2Video } from "../../Api/ai";
// AI 시나리오 만들기 페이지 API

import actorData from '../../Data/actorData.json'
import screenEffect from '../../Data/screenEffect.json'
import textEffect from '../../Data/textEffect.json'
import fontData from '../../Data/fontData.json'
import templateData from '../../Data/templateData.json'
import {v4 as uuidv4} from 'uuid';

import aiSampleImageData from "../../Data/aiSampleImageData.json"

import LoadingScreen from "../../Components/common/LoadingScreen";
import VideoLoading from "../../Components/pages/EditVideo/VideoLoading";
import { GeneratedImageInterface, ScenarioContext } from "../../Contexts/ScenarioContext";

import { isLogging } from "../../App";
import { ENABLE_I2V } from "../CreateScenario";
import { EditorContext } from "../../Contexts/EditorContext";
import { WebGetFile, WebLoad } from "../../Api/resource";

import {fileTypeFromBlob} from 'file-type';

const SCENE_TEXT_EXCEPTIONS=["없음","null","undefined",null,undefined];

function formatting(value){
  return ('00'+value.toString()).slice(-2);      
}
const getTimeString = () => {
  const currentTime = new Date();

  const YY = formatting(currentTime.getFullYear());
  const MM = formatting(currentTime.getMonth()+1);
  const DD = formatting(currentTime.getDate());
  const hh = formatting(currentTime.getHours());
  const mm = formatting(currentTime.getMinutes());
  const ss = formatting(currentTime.getSeconds());

  return `${YY}${MM}${DD}_${hh}${mm}${ss}`
}

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function ShopInfo(props){
  const [userName, setUserName] = React.useState<string>("장윤주");
  const [step, setStep] = useState(1);
  const shopInfoContext = useContext(ShopInfoContext);
  const shopPointContext = useContext(ShopPointContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const {setProsList, setTargetList} = shopPointContext;

  const {shopName, shopAddress, shopCall, shopCategory, includeAiImage, adTime, files, editedFiles, aiImages, imageAnalysisInfo, isGenerating} = shopInfoContext
  const {setShopName, setShopAddress, setShopCall, setShopCategory, setAdTime, setIncludeAiImage, setAiImages, addFile, removeFile, setImageAnalysisInfo, setIsGenerating} = shopInfoContext

  const nav = useNavigate();

  const [name, setName] = useState(undefined);
  const [address, setAddress] = useState("");
  const [callNumberFirst, setCallNumberFirst] = useState<string|undefined>(undefined)
  const [callNumberSecond, setCallNumberSecond] = useState<string|undefined>(undefined)
  const [callNumberThird, setCallNumberThird] = useState<string|undefined>(undefined)
  const [selectedTime, setSelectedTime] = useState(undefined)
  const [category, setCategory] = useState(undefined);
  const [stepOneClear, setStepOneClear] = useState(false);


  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = () => {setShowAlert(false)};
  const [prevCategory, setPrevCategory] = useState(undefined);
  const [alertIcon, setAlertIcon] = useState(undefined);
  const [alertTitle, setAlertTitle] = useState(undefined);
  const [alertDescription, setAlertDescription] = useState(undefined);

  const [showPost, setShowPost] = useState<boolean>(false);
  const closePost = () => {setShowPost(false)}
  const openPost = () =>{setShowPost(true)}

  const [isValid, setIsValid] = useState(false);

  const [loadingText, setLoadingText] = useState("AI가 정보를 분석하고 있습니다");

  useEffect(()=>{
    
    if(isGenerating){
      setIsValid(false)
    }else if(editedFiles.length < 5 || editedFiles.length > 10){
      setIsValid(false)
    }else{
      if(selectedTime===15){
        if(editedFiles.length>=4){
          setIsValid(true);
        }else{
          setIsValid(false);
        }
      }else if(selectedTime===30){
        if(editedFiles.length>=7){
          setIsValid(true);
        }else{
          setIsValid(false);
        }
      }
    }
  },[isGenerating, editedFiles, selectedTime])

  function formatting(value){
    return ('00'+value.toString()).slice(-2);      
  }
   function getUUID(){    
    ///////////// todo ////////////////
    //
    //    iframe 에서 직접 받아오기    
    //
    //////////////////////////////////
    
    let uuid;

    const currentTime = new Date();    

    const YY = formatting(currentTime.getFullYear());
    const MM = formatting(currentTime.getMonth()+1);
    const DD = formatting(currentTime.getDate());
    const hh = formatting(currentTime.getHours());
    const mm = formatting(currentTime.getMinutes());
    const ss = formatting(currentTime.getSeconds());
    
    return `test-${YY}${MM}${DD}-${hh}${mm}${ss}`
  }
  
  useEffect(()=>{
    if(name&&address&&callNumberFirst&&callNumberSecond&&callNumberThird&&selectedTime&&category){
      setStepOneClear(true);
    }else{
      setStepOneClear(false);
    }
  },[name, address, callNumberFirst, callNumberSecond, callNumberThird, selectedTime, category])


  useEffect(()=>{
    if(shopName){ setName(shopName) }
    if(shopAddress){ setAddress(shopAddress) }
    if(shopCall){
      if(shopCall[0]){setCallNumberFirst(shopCall[0])};
      if(shopCall[1]){setCallNumberSecond(shopCall[1])};
      if(shopCall[2]){setCallNumberThird(shopCall[2])};
    }    
    if(shopCategory){ setCategory(shopCategory) }
    if(adTime){ setSelectedTime(adTime) }
  },[])


  useEffect(()=>{
    if(!shopInfoContext.hasLoaded && shopInfoContext.hasAssetId){
      loadData();
    }
  },[shopInfoContext.hasLoaded, shopInfoContext.hasAssetId])

  async function loadData(){
    const loadRequest = {
      assetId: sessionStorage.getItem("ASSET_ID")
    }
    console.log(`loading data`)

    const assetId = sessionStorage.getItem("ASSET_ID");

    const loadResponse = await WebLoad({assetId:assetId})
    if(loadResponse.result===0  && loadResponse.finalInfo!==""){
      shopInfoContext.setIsGenerating(true);
      setLoadingText("정보를 불러오는 중입니다")
      console.log('data loaded');

      const finalInfo = loadResponse.finalInfo

      const setResult = await settingContextData(finalInfo);
      if(setResult.result===0){
        shopInfoContext.setHasLoaded(true)
        shopInfoContext.setCompletedStep(4);
        setLoadingText("AI가 정보를 분석하고 있습니다");
        props.setStep(4);
        nav('/edit')
      }else{
        shopInfoContext.setHasLoaded(true)
        setLoadingText("AI가 정보를 분석하고 있습니다");
        console.error("error : ",setResult.errMsg);
      }
    }else{
      shopInfoContext.setHasLoaded(true)
      setLoadingText("AI가 정보를 분석하고 있습니다");
      console.log('no data');
    }
  }

  function base64ToFile(base64Data, fileName, fileType) {
    const byteString = atob(base64Data.split(',')[1]);
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }  
    const blob = new Blob([byteArray], { type: fileType });
    return new File([blob], fileName, { type: fileType });
  }

  async function settingContextData(data){
    // shopInfoContext.setIsGenerating(true);
    try{
      const { infoData, pointData, scenarioData, editorData } = data
  
      ////////////////////////// info data /////////////////////////////
      const completedStep = infoData.completedStep;
      const shopName =  infoData.shopName;
      const shopAddress = infoData.shopAddress;
      const shopCall = infoData.shopCall;
      const shopCategory = infoData.shopCategory;
      const adTime = infoData.adTime;
      const filesData = infoData.files;
      const editedFilesData= infoData.editedFiles;
      const aiImages = infoData.aiImages;
      const includeAiImage = infoData.includeAiImage;
      const isGenerating = infoData.isGenerating;
      const imageAnalysisInfo = infoData.imageAnalysisInfo;


      const fileInfo = [];
      const editedFileInfo = [];

      for(let i=0; i<filesData.length; i++){
        try{
          const fileRequest = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
            key: filesData[i].s3Key
          }
          const fileResponse = await WebGetFile(fileRequest);
          if(fileResponse.result){
            console.error('file request error :: ', fileResponse.errMsg)
          }else{

            const fileType = await fileTypeFromBlob(fileResponse);            
            const file = new File([fileResponse], filesData[i].s3Key.split('/')[filesData[i].s3Key.split("/").length-1], { type: fileType.mime });

            const retreivedInfo={
              file: file,
              s3Key: filesData[i].s3Key,
              mainCategory: filesData[i].mainCategory,
              subCategory: filesData[i].subCategory,
              editInfo: filesData[i].editInfo,
            }
            fileInfo.push(retreivedInfo);
          }
        }catch(error){
          console.error('loading file error :: ', error)
        }     
      }

      for(let i=0; i<editedFilesData.length; i++){
        try{
          const fileRequest = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
            key: editedFilesData[i].s3Key
          }
          const fileResponse = await WebGetFile(fileRequest);
          if(fileResponse.result){
            console.error('file request error :: ', fileResponse.errMsg)
          }else{
            const fileType = await fileTypeFromBlob(fileResponse);            
            const file = new File([fileResponse], filesData[i].s3Key.split('/')[filesData[i].s3Key.split("/").length-1], { type: fileType.mime });

            const retreivedInfo = {
              file: file,
              s3Key: editedFilesData[i].s3Key,
              mainCategory: editedFilesData[i].mainCategory,
              subCategory: editedFilesData[i].subCategory,
              editInfo: editedFilesData[i].editInfo,
            }
            editedFileInfo.push(retreivedInfo);
          }
        }catch(err){
          console.error("loading edited file error :: ", err)
        }      
      }
      
  
      shopInfoContext.setCompletedStep(4)
      shopInfoContext.setShopName(shopName)
      shopInfoContext.setShopAddress(shopAddress)
      shopInfoContext.setShopCall(shopCall)
      shopInfoContext.setShopCategory(shopCategory)
      shopInfoContext.setAdTime(adTime)
      shopInfoContext.addFile(fileInfo)
      shopInfoContext.setEditedFiles(editedFileInfo)
      shopInfoContext.setAiImages(aiImages)
      shopInfoContext.setIncludeAiImage(includeAiImage)
      shopInfoContext.setImageAnalysisInfo(imageAnalysisInfo)
  
  
  
      ////////////////////////// point data /////////////////////////////
      const prosList= pointData.prosList;
      const targetList= pointData.targetList;
      const customRequirement= pointData.customRequirement;
      const requiredTextInfo= pointData.requiredTextInfo;
      const customFeatureList= pointData.customFeatureList;
      const customCustomerList= pointData.customCustomerList;
      const customMoodList= pointData.customMoodList;
      const fontFeature= pointData.fontFeature;
      const bgmFeature= pointData.bgmFeature;
      const narrationCharacterFeature= pointData.narrationCharacterFeature;
  
      shopPointContext.setProsList(prosList);
      shopPointContext.setTargetList(targetList);
      shopPointContext.setCustomRequirement(customRequirement);
      shopPointContext.setRequiredTextInfo(requiredTextInfo);
      shopPointContext.setCustomFeatureList(customFeatureList);
      shopPointContext.setCustomCustomerList(customCustomerList);
      shopPointContext.setCustomMoodList(customMoodList);
      shopPointContext.setFontFeature(fontFeature);
      shopPointContext.setBgmFeature(bgmFeature);
      shopPointContext.setNarrationCharacterFeature(narrationCharacterFeature);
  
  
  
      ////////////////////////// scenario data /////////////////////////////
      const finalScenario= scenarioData.finalScenario;
      const scenario= scenarioData.scenario;
      const scene= scenarioData.scene;
      const selectedScenario= scenarioData.selectedScenario;
      const selectedTemplate= scenarioData.selectedTemplate;
      const useLastEnding= scenarioData.useLastEnding;
      const generatedImages= scenarioData.generatedImages;
      const generatedVideos= scenarioData.generatedVideos;
      // const useTemplate= scenarioData.useTemplate;
      const BGMInfo= scenarioData.BGMInfo;
      const selectedBgmIndex = scenarioData.selectedBgmIndex||0;
      const selectedTTSActorInfo = scenarioData.selectedTTSActorInfo;
      const editedTTSActorList = scenarioData.editedTTSActorList;
  
      scenarioContext.setFinalScenario(finalScenario)
      scenarioContext.setScenario(scenario)
      scenarioContext.setScene(scene)
      scenarioContext.setSelectedScenario(selectedScenario)
      scenarioContext.setSelectedTemplate(selectedTemplate)
      scenarioContext.setUseLastEnding(useLastEnding)
      scenarioContext.setGeneratedImages(generatedImages)
      scenarioContext.setGeneratedVideos(generatedVideos)
      // scenarioContext.setUseTemplate(useTemplate)
      scenarioContext.setBGMInfo(BGMInfo)
      scenarioContext.setSelectedBgmIndex(selectedBgmIndex)
      scenarioContext.setSelectedTTSActorInfo(selectedTTSActorInfo)
      scenarioContext.setEditedTTSActorList(editedTTSActorList)
  
  
      ////////////////////////// editor data /////////////////////////////
      const videoCreated = editorData.videoCreated;
      let outputVideoUrl= editorData.outputVideoUrl;
      const outputPresignedUrl= editorData.outputPresignedUrl;
      const outputVideoKey = editorData.outputVideoKey;
      const outputVideoBucket = editorData.outputVideoBucket;
      const isEdited = editorData.isEdited;
      const screenEffectList = editorData.screenEffectList
      const sceneTextShadowEffectList = editorData.sceneTextShadowEffectList;
      const ttsVolumeList = editorData.ttsVolumeList;
      const bgmVolume = editorData.bgmVolume;

      try{
        const fileRequest={
          assetId:sessionStorage.getItem("ASSET_ID"),
          bucket: outputVideoBucket,
          key: outputVideoKey,
        }
        const videoResponse = await WebGetFile(fileRequest)
        if(videoResponse.result){
          console.error('file response error : ', videoResponse.errMsg)
        }else{
          const blobUrl = URL.createObjectURL(videoResponse);
          outputVideoUrl = blobUrl;
        }
      }catch(err){
        console.error("loading file error : ", err)
      }
      
      editorContext.setVideoCreated(videoCreated);
      editorContext.setOutputVideoUrl(outputVideoUrl);
      editorContext.setOutputPresignedUrl(outputPresignedUrl);
      editorContext.setOutputVideoBucket(outputVideoBucket);
      editorContext.setOutputVideoKey(outputVideoKey);
      editorContext.setIsEdited(isEdited);
      editorContext.setScreenEffectList(screenEffectList);
      editorContext.setSceneTextShadowEffectList(sceneTextShadowEffectList)
      editorContext.setTtsVolumeList(ttsVolumeList)
      editorContext.setBgmVolume(bgmVolume)
  
      shopInfoContext.setIsGenerating(false);
      return({result:0, errMsg:""})
    }catch(err){
      console.error('load error ',err)
      shopInfoContext.setIsGenerating(false);
      setAlertTitle(undefined);
      setAlertDescription("불러오기에 실패했어요.");
      setShowAlert(true);
      return({result:-1, errMsg:`load error ${err}`})
    }
  }


  const handleChangeShopName=(e:any)=>{
    const val = e.currentTarget.value;
    
    setName(val)
  }
  const handleChangeAddress = (e:any)=>{
    const val = e.currentTarget.value;
    
    setAddress(val);
  }

  const handleInputCallKeyDown = (e:any)=>{
    // console.log("key : ", e.key)

    if( ("0"<=e.key && e.key<="9")
      || e.key==="Backspace"
      || e.key==="Tab" 
      || e.key==="Delete"
      || e.key==="Enter"
    ){
      //// allow input
      if(e.key === "Enter"){
        moveTelNext(e)
      }
    }else{
      e.preventDefault();
    }
  }

  function moveTelNext(e:any){
    const id = e.currentTarget.getAttribute("id")
    const parentEl = e.currentTarget.parentElement;
    
    switch(id){
      case "call-first":
          parentEl.children[1].focus();
        break;
      case "call-second":
          parentEl.children[2].focus();
        break;
      case "call-third":
        break;
      default:
        break;
    }
  }

  const callRef1=useRef(null)
  const callRef2=useRef(null)
  const callRef3=useRef(null)

  useEffect(()=>{
    if(callNumberFirst){
      if(callNumberFirst.length>=4){
        const callElems = document.getElementsByClassName("shopcall")
        
        if(callElems.length>=2){
          (callElems[1] as HTMLElement).focus()
        }
      }
    }
  },[callNumberFirst])
  useEffect(()=>{
    if(callNumberSecond){
      if(callNumberSecond.length>=4){
        const callElems = document.getElementsByClassName("shopcall")
        
        if(callElems.length>=2){
          (callElems[2] as HTMLElement).focus()
        }
      }
    }
  },[callNumberSecond])

  const handleCallFirst = (e:any) => {
    const val = e.currentTarget.value.toString()
    
    if(val.length<=4){
      if(val===undefined || val===""){
        setCallNumberFirst(val)
      }
      else if("0"<=val[val.length-1] && val[val.length-1]<="9"){
        setCallNumberFirst(val)
      }
    }
  } 
  const handleCallSecond = (e:any) => {
    const val = e.currentTarget.value.toString()
    
    if(val.length<=4){
      if(val===undefined || val===""){
        setCallNumberSecond(val)
      }
      else if("0"<=val[val.length-1] && val[val.length-1]<="9"){
        setCallNumberSecond(val)
      }
    }
  }
  const handleCallThrid = (e:any) => {
    const val = e.currentTarget.value.toString()
    
    if(val.length<=4){
      if(val===undefined || val===""){
        setCallNumberThird(val)
      }
      else if("0"<=val[val.length-1] && val[val.length-1]<="9"){
        setCallNumberThird(val)
      }
    }
  }  

  const handleClickAdTime = (e:any)=>{
    console.log(e.currentTarget)
    console.log(`${adTime} ==>` ,e.currentTarget.getAttribute("value"));
    const val = (e.currentTarget.getAttribute("value"));
    if(val){
      setSelectedTime(parseInt(val));
    }
  }
  useEffect(()=>{
    console.log(`adTime changed ${adTime}`)
  },[adTime])


  const handleChangeCategory = (value)=>{
    setPrevCategory(category);
    console.log(`set category ${value}`)
    setCategory(value)
  }

  // const handleGetFile = (file)=>{
  //   console.log("got file ::: ", file)
  //   console.log("add to ", files)
  //   addFile(file);
  // }

  // const handleDeleteFile = (fileName)=>{
  //   removeFile(fileName)
  // }
  


  const revertSelect = () => {
    setCategory(prevCategory);
    closeAlert();
  }
  const confirmSelect = () => {
    closeAlert();
  }
   
  useEffect(()=>{
    if(category==="분양광고"){
      setAlertIcon(<QuestionIcon/>)
      setAlertTitle("분양광고 심의필증 필수 첨부")
      const desc = 
        <>
          <span>분양광고는 한국광고자율심의기구의<br/></span>
          <span>심의필증을 첨부하여야 청약 가능합니다.<br/></span>
          <span>미 첨부시 자동 반려처리 되오니<br/></span>
          <span>문의 필요시 채널톡으로 문의 바랍니다<br/></span>
        </>
      setAlertDescription(desc);

      setShowAlert(true);
    }else{
      setShowAlert(false);
    }
  },[category])


  const handleToScenario = () => {

    props.setStep(4);
    nav('/scenario');
  }

  const [isVideoInvalid, setIsVideoInvalid] = useState(false);

  useEffect(()=>{
    // console.log("video valid ?", isVideoInvalid)
    if(isVideoInvalid){
      console.log("video invalid");
      setAlertIcon(<QuestionIcon/>)
      setAlertTitle("동영상 시간 초과")
      setAlertDescription("동영상 길이는 5초를 초과할 수 없어요.")
      setShowAlert(true);
    }
  },[isVideoInvalid])

  function handleClickToPoint( ){ 
    for(let i=0; i< shopInfoContext.editedFiles.length; i++){
      // console.log('file :: ', shopInfoContext.editedFiles[i])
      const info = shopInfoContext.editedFiles[i].editInfo;
      if(info){
        const segmentLength = info.end - info.start
        if(segmentLength>5){
          // console.log('video over 5');
          setIsVideoInvalid(true);          
          return;
        }
      } 
    }
  }

  const confirmVideoAlert = () => {
    setShowAlert(false);
    setIsVideoInvalid(false);
  }


  const [isError, setIsError] = useState(false);

  const handleToPoint = async () => {

    //// 순서 : 
    //// 1. 매장정보 분석              : WebPostAnalyzeStore
    //// 2. 매장정보 분석결과 확인      : WebGetAnalyzeStore - 반복
    //// 3. 광고 소재 분석             : WebPostAnalyzeImage 
    //// 4. 광고 소재 분석 결과 확인    : WebGetAnalyzeImage - 반복
    //// 매장 특징 페이지로 이동

    for(let i=0; i< shopInfoContext.editedFiles.length; i++){
      // console.log('file :: ', shopInfoContext.editedFiles[i])
      const info = shopInfoContext.editedFiles[i].editInfo;
      if(info){
        const segmentLength = info.end - info.start
        if(segmentLength>5){
          // console.log('video over 5');
          setIsVideoInvalid(true);          
          return;
        }
      } 
    }

    try{
      setIsGenerating(true);
      const storeInfo = {
        name: name,
        address: address,
        contract: `${callNumberFirst}-${callNumberSecond}-${callNumberThird}`,
        type: category
      }
      const analyzeStoreResult = await analyzeStore({storeInfo:storeInfo});
      if(isLogging){
        console.log("store analysis ::: ", analyzeStoreResult)
      }
      if(analyzeStoreResult){
        if(editedFiles.length!==0){
          const analyzeImageResult:any = await analyzeImage({storeInfo:storeInfo});
          if(isLogging){
            console.log("image analysis ::: ", analyzeImageResult);        
          }
          setImageAnalysisInfo(analyzeImageResult);
          if(!analyzeImageResult){
            setFailMessage();
            throw Error("Image analysis failed")
          }
        }
        setIsGenerating(false);
        shopInfoContext.setCompletedStep(1);
        props.setStep(2);
        nav('/point');
      }else{
        throw Error("Store analysis failed");
      }
    }catch(err){
      setFailMessage();
      console.error(err)
    }
  }

  useEffect(()=>{
    console.log('show post : ',showPost)
  },[showPost])

  const handleToStepOne = () => {
    setStep(1);
  }

  const handleToStepTwo = () => {
    setShopName(name);
    setShopAddress(address);
    setShopCall([callNumberFirst,callNumberSecond,callNumberThird]);
    setAdTime(selectedTime);
    setShopCategory(category);
    if(isLogging){
      console.log("cat::: ",category)        
    }
    setStep(2);
  }

  async function analyzeStore({storeInfo}){
    try{
      const analyzeStorePromise = await new Promise( async (resolve, reject)=>{
  
        const analyzeRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo: storeInfo
        }
        if(isLogging){
          console.log("analyze store request :: ", analyzeRequest)        
        }
        const analyze = await WebPostAnalyzeStore(analyzeRequest)  
        if(analyze.result === 0){
          try{
            const analyzeResultPromise = await new Promise(async(resolve, reject)=>{
              const analyzeInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const analyzeResult = await WebGetAnalyzeStore(request);
                if(analyzeResult.result === 0){
                  switch(analyzeResult.status){
                    case "progress":
                      console.log("analyzing store on progress");
                      break;
                    case "done":
                      clearInterval(analyzeInterval);
                      console.log("analyzing store completed");
                      if(isLogging){
                        console.log(analyzeResult.storeAnalysisInfo);        
                      }
    
                      const features = analyzeResult.storeAnalysisInfo.featureList.map(item=>{return({text:item.feature, icon:undefined, desc:item.desc})});
                      const customers = analyzeResult.storeAnalysisInfo.customerList.map(item=>{return({text:item.customer, icon:undefined, desc:item.desc})});
                      if(isLogging){
                        console.log(" features :: ", features)
                        console.log(" customers :: ", customers)        
                      }
      
                      shopPointContext.setProsList(features);
                      shopPointContext.setTargetList(customers);
                      resolve(analyzeResult);
                      break;
                    case "failed":
                      clearInterval(analyzeInterval);
                      console.log("with status failed", analyzeResult.errMsg);
                      setFailMessage();
                      reject(`with status failed ${analyzeResult.errMsg}`)
                  }
                }else{
                  clearInterval(analyzeInterval);
                  setFailMessage();
                  reject(`${analyzeResult.errMsg}`)
                } 
             }, 2000)
            })
            resolve(analyzeResultPromise)
          }catch(err){
            setFailMessage();
            reject(`get store analysis failed :: ${err}`)
          }
        }else{
          setFailMessage();
          reject(`post store analysis failed :: ${analyze.errMsg}`)
        }  
      })
      return analyzeStorePromise
    }catch(err){
      console.error("Failed to analyze store ::: ", err)
      setFailMessage();
      return undefined;
    }
  }

  function setFailMessage(){
    setIsGenerating(false);
    setAlertDescription(<><span>생성에 실패했어요.</span><br/><span>다시 시도해주세요.</span></>)
    setAlertTitle(undefined);
    setAlertIcon(<QuestionIcon/>)
    setShowAlert(true);
  }

  async function analyzeImage({storeInfo}){
    try{
      const analyzeImagePromise = await new Promise(async (resolve, reject)=>{
        const s3KeyList = editedFiles.map((file)=> ({path:file.s3Key}));
        const analyzeImageRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo: storeInfo,
          fileList: s3KeyList
        }
        if(isLogging){
          console.log("analyzeImageRequest : ", analyzeImageRequest)        
        }
        const analyzeImageResult:any = await WebPostAnalyzeImage(analyzeImageRequest)
  
        if(analyzeImageResult.result === 0){  
          try{
            const analyzeImageResultPromise:any = await new Promise((resolve, reject)=>{
              const analyzeImageInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const imageAnalysis:any = await WebGetAnalyzeImage(request)
                if(imageAnalysis.result===0){
                  switch(imageAnalysis.status){
                    case "progress":
                      console.log("image analyzing on progress");
                      break;
                    case "done": 
                      clearInterval(analyzeImageInterval);
                      console.log("image analyzing completed");
                      resolve(imageAnalysis.imageAnalysisInfo)
                      break;
                    case "failed":
                      clearInterval(analyzeImageInterval);
                      console.error("image analyzing failed");
                      reject("with status failed");
                  }
                }else{
                  clearInterval(analyzeImageInterval);
                  setFailMessage()
                  reject(`${imageAnalysis.errMsg}`)
                }
              }, 5000)
            })
            resolve(analyzeImageResultPromise);
          }catch(err){
            setFailMessage();
            reject(`get image analysis error ::: ${err}`)
          }
        }else{
          setFailMessage();
          reject(`Failed to analyze image :: ${analyzeImageResult.errMsg}`)
        }
      })
      return analyzeImagePromise
    }catch(err){
      console.error("Failed to analyze image :: ", err)
      setFailMessage();
      return undefined
    }
  }


///////////////  head  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const head= isGenerating? <></> :
    step===1?
      undefined
      :
      <Button
        $buttonType="text-l"
        $variant="beige"
        showLeftIcon
        leftIcon="CaretLeft"
        text={"우리 매장 정보 입력하기"}
        onClick={handleToStepOne}
      />


  ///////////////  content  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectRef = useRef(null);

  const contents= isGenerating?
  <LoadingScreen text={loadingText}/>
  :
  step===1?
    <>
      <ContainerFrameStyle>
        <QuestionTitle text="멋진 광고를 만들기 위한 고객님의 정보를 알려주세요!"/>
        <ContainerSubFrameStyle>
          <InputTextGroup
            showLabel
            label="매장명"
            className="shop-name"
            placeholder="정확한 매장의 상호명을 입력해주세요"
            onChange={handleChangeShopName}
            onKeyDown={(e:any)=>{
              console.log(e);
              if(e.code==="Enter"){
                const nextEl = document.getElementsByClassName("shop-address")[0] as HTMLElement;
                nextEl.focus();
              }
            }}
            value={name}
            required
          />
          <InputTextGroup
            showLabel
            label="매장 주소지"
            className="shop-address"
            showButton
            buttonLabel="주소검색"
            onClickButton={openPost}
            placeholder="주소를 입력해주세요"
            onKeyDown={(e:any)=>{
              console.log(e);
              if(e.code==="Enter"){
                const nextEl = document.getElementsByClassName("shopcall")[0] as HTMLElement;
                nextEl.focus();
              }
            }}
            // onChange={handleChangeAddress}
            value={address}
            required
          />
          <CallFrameStyle>
            <CallLabelWrapperStyle>
              <InputLabel size="sm" style={{width:"80px"}} label={"매장 연락처"} required/>
            </CallLabelWrapperStyle>
            <CallInputsStyle>
              <InputText
                type="number"
                ref={callRef1}
                className={'shopcall 1'}
                value={callNumberFirst}
                onChange={handleCallFirst}
                onKeyDown={(e:any)=>{
                  console.log(e);
                  if(e.code==="Enter"){
                    const nextEl = document.getElementsByClassName("shopcall")[1] as HTMLElement;
                    nextEl.focus();
                  }
                }}
                placeholder="02"
              />
              <Divider orientation="horizontal" width={1} length={7} color={"text-quaternary"}/>
              <InputText
                type="number"
                ref={callRef2}
                className={'shopcall 2'}
                value={callNumberSecond}
                onChange={handleCallSecond}
                onKeyDown={(e:any)=>{
                  console.log(e);
                  if(e.code==="Enter"){
                    const nextEl = document.getElementsByClassName("shopcall")[2] as HTMLElement;
                    nextEl.focus();
                  }
                }}
                placeholder="0000"
              />
              <Divider orientation="horizontal" width={1} length={7} color={"text-quaternary"} />
              <InputText
                type="number"
                ref={callRef3}
                className={'shopcall 3'}
                value={callNumberThird}
                onChange={handleCallThrid}
                onKeyDown={(e:any)=>{
                  console.log(e);
                  if(e.code==="Enter"){
                    let nextEl = document.getElementById("shop-category") as HTMLElement;
                    // nextEl = selectRef.current;
                    console.log("GOING to select", nextEl)
                    if(nextEl){
                      nextEl.focus();
                    }
                  }
                }}
                placeholder="0000"
              />
            </CallInputsStyle>
          </CallFrameStyle>
          <InputSelectGroup
            ref={selectRef}
            onChange={handleChangeCategory}
            value={category}
            className="shop-category"
            id="shop-category"
            showLabel
            label="매장 업종"
            required
            placeholder="업종을 선택하세요"
            list={categories}
            // style={{width:"100%", boxSizing:"border-box"}}
          />
        </ContainerSubFrameStyle>
      </ContainerFrameStyle>
      <ContainerFrameStyle>
        <QuestionTitle text="생각하신 광고시간은 어떻게 될까요?"/>
        <SelectTimeWrapper>
          <InputLabel required label="광고 시간 선택하기"/>
          <ButtonQuestionWrapper>
            <ButtonQuestion key={`time-15`} selected={(selectedTime===15)} onClick={handleClickAdTime} value={15} text={"15초 광고"} showInfoText infoText="광고주님들이 가장선호하는 광고 시간이에요"/>
            <ButtonQuestion key={`time-30`} selected={(selectedTime===30)} onClick={handleClickAdTime} value={30} text={"30초 광고"}/>
          </ButtonQuestionWrapper>
        </SelectTimeWrapper>
      </ContainerFrameStyle>
      
      {
        showAlert &&
        <AlertModal
          icon={alertIcon}
          title={alertTitle}
          description={alertDescription}
          show={showAlert}
          onCancel={revertSelect}
          onConfirm={confirmSelect}
        />
      }
      {
        showPost&&
        <ModalAddress show={showPost} onClose={closePost} setAddress={setAddress}/>
      }
    </>
    :
    <>
      <ContainerFrameStyle>
        <QuestionTitle text="광고제작에 사용할 소재 이미지를 등록해 주세요."/>
        <ContainerSubFrameStyle>
          <Question
            label="광고 소재 (이미지, 동영상) 업로드하기"
            description="매장의 특징과 모습이 담긴 이미지나 영상을 업로드해주세요.(최소 5개, 최대 10개)"
            required
          >
            <ContentUnit type="addBtn" onClick={()=>{}}/>   
            <FileListStyle>              
              {editedFiles.map((fileInfo)=>{
                const file:any = fileInfo.file
                if(file && ((file instanceof File) || (file instanceof Blob))){
                  let url = ''
                  try{
                    url = URL.createObjectURL(fileInfo.file);
                  }catch(err){
                    console.error("Error on creating file preview url :: ",err)
                    shopInfoContext.removeFile(fileInfo);
                    return undefined
                  }
                  if(fileInfo.file.type.includes("image")){
                    return(<ContentUnit fileInfo={fileInfo} type="image" name={fileInfo.file.name} sourceURL={url}/>)
                  }else if(fileInfo.file.type.includes("video")){
                    return(<ContentUnit fileInfo={fileInfo} type="video" name={fileInfo.file.name} sourceURL={url}/>)
                  }else{
                    return undefined
                  }
                }else{
                  console.error("invalid file info:: ", JSON.parse(JSON.stringify(fileInfo)));
                  shopInfoContext.removeFile(fileInfo);
                  return undefined
                }
              })}            
            </FileListStyle>
          </Question>
          <Question
            label="매장의 특성이 담긴 이미지를 AI가 만들어 드립니다."
            hasSwitch
            checked={includeAiImage}
            setChecked={setIncludeAiImage}
            switchLabel="AI 이미지 포함"
            description={<><span>매장의 특성을 담은 이미지를 AI가 생성하여 광고에 활용하실 수 있습니다.</span><br/><span>(아래는 AI가 생성한 샘플 이미지입니다)</span></>}
            required
          >
            <FileListStyle>
              {
                aiSampleImageData.map((sample, index)=>
                  <AiImage index={index} name={sample.name} sourceURL={sample.sourceUrl}/>
                )
              }
            </FileListStyle>              
          </Question>
        </ContainerSubFrameStyle>
      </ContainerFrameStyle>
      {
        showAlert &&
        <AlertModal
          icon={alertIcon}
          title={alertTitle}
          description={alertDescription}
          show={showAlert}
          onConfirm={confirmVideoAlert}
          onlyConfirm
        />
      }
    </>  

  ///////////////  foot  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // 시나리오 후보 생성 
  async function getScenarioCandidate({storeInfo, storeAnalysisInfo, imageAnalysisInfo, requiredMoodList}){
    try{
      const scenarioCandidatesPromise = new Promise(async(resolve, reject)=>{
  
        const createScenarioRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo: storeInfo,
          storeAnalysisInfo: storeAnalysisInfo,
          imageAnalysisInfo: imageAnalysisInfo,
          requiredMoodList:requiredMoodList
        }
        if(isLogging){
          console.log("createScenarioRequest : ", createScenarioRequest)        
        }
        const postScenarioCandidateResult = await WebPostCreateScenarioCandidate(createScenarioRequest);
    
        if(postScenarioCandidateResult.result===0){
          try{
            const scenarioCandidate = await new Promise(async (resolve, reject)=>{
              const waitingInterval = setInterval(async () => {
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const scenarioCandidateResult = await WebGetCreateScenarioCandidate(request)
                if(scenarioCandidateResult.result===0){
                  switch(scenarioCandidateResult.status){
                    case "progress":
                      console.log("Creating scenario candidate on progress");
                      break;
                    case "done":
                      clearInterval(waitingInterval)
                      if(isLogging){
                        console.log("Creating scenario candidate completed", scenarioCandidateResult);        
                      }
                      resolve(scenarioCandidateResult);
                      break;
                    case "failed":
                      clearInterval(waitingInterval)
                      setFailMessage();
                      reject(`with status failed ${scenarioCandidateResult.errMsg}`)
                  }
                }else{
                  clearInterval(waitingInterval)
                  setFailMessage();
                  reject(scenarioCandidateResult.errMsg)
                }          
              }, 2000);
            })
            resolve(scenarioCandidate);
          }catch(err){
            console.error(`get scenario candidate failed :: ${err}`)
            setFailMessage();
            reject(`get scenario candidate failed :: ${err}`)
          }
        }else{
          setFailMessage();
          reject(`${postScenarioCandidateResult.errMsg}`);
        }
      })
      return scenarioCandidatesPromise;
    }catch(err){
      console.error("create scenario candidate failed :: ", err)
      setFailMessage();
      return undefined;
    }
  }

  // 장면 생성
  async function createScenario({
    time, useGenAiImage, textEffectList, sceneEffectList, storeInfo, storeAnalysisInfo, imageAnalysisInfo, scenarioInfo
  }){
    try{
      const createScenarioPromise = new Promise(async(resolve,reject)=>{
        const selectedFeature = shopPointContext.prosList.filter(item=>item.checked).map(item=>({feature:item.text, desc:item.desc}))
        const selectedCustomer = shopPointContext.targetList.filter(item=>item.checked).map(item=>({customer:item.text, desc:item.desc}))

        const includeAiImage = shopInfoContext.includeAiImage? 1 : 0;
  
        const scenarioRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          time: time,
          useGenAiImage: useGenAiImage,
          textEffectList: textEffectList,
          sceneEffectList: sceneEffectList,
          storeInfo: storeInfo,
          storeAnalysisInfo: storeAnalysisInfo,
          imageAnalysisInfo: imageAnalysisInfo,
          scenarioInfo: scenarioInfo,
          requiredMoodList:[],
        }
        if(isLogging){
          console.log("create scene request :: ", scenarioRequest);        
        }
        const postScenarioResult = await WebPostCreateScenario(scenarioRequest);
        if(postScenarioResult.result===0){
          try{
            const waitingPromise = await new Promise((resolve,reject)=>{
              const scenarioResultInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const scenarioResult = await WebGetCreateScenario(request);
                if(scenarioResult.result===0){
                  switch(scenarioResult.status){
                    case "progress":
                      console.log("Creating scene on progress")
                      break;
                    case "done":
                      console.log("Creating scene completed");
                      clearInterval(scenarioResultInterval);
                      resolve(scenarioResult);
                      break;
                    case "failed":
                      console.log("Creating scene failed");
                      clearInterval(scenarioResultInterval);
                      // setFailMessage();
                      reject(`with status failed ${scenarioResult.errMsg}`)
                  }
                }else{
                  clearInterval(scenarioResultInterval);
                  // setFailMessage();
                  reject(scenarioResult.errMsg)      
                }
              }, 5000)
            })
            resolve(waitingPromise);
          }catch(err){
            console.error("get create scene failed :: ", err)
            // setFailMessage();
            reject(`get create scene failed :: ${err}`)
          }
        }else{
          // setFailMessage();
          reject(postScenarioResult.errMsg)
        }
      })
      return createScenarioPromise
    }catch(err){
      console.error("Error during creating scene :: ", err )
      // setFailMessage();
      return undefined
    }
  }

  async function genNarration({
    scenario,
    totalTime,    
  }){
    try{
      const narrationPromise:any = await new Promise(async(resolve, reject)=>{
        const narrationRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          scenario: scenario,
          totalTime: totalTime,
          silentDuration:0.3
        }
        if(isLogging){
          console.log("narration request ::", narrationRequest)        
        }
  
        const postNarrationResult:any = await WebPostCreateNarration(narrationRequest);
        if(postNarrationResult.result===0){  
          try{
            const narrationIntervalPromise:any = await new Promise(async (resolve,reject)=>{    
              const narrationInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                };
                const getNarrationResult:any = await WebGetCreateNarration(request);
                if(getNarrationResult.result===0){
                  switch(getNarrationResult.status){
                    case "progress":
                      console.log("create narration on progress")
                      break;
                    case "done":
                      clearInterval(narrationInterval);
                      console.log("create narration completed")
                      resolve(getNarrationResult)
                      break;
                    case "failed":
                      clearInterval(narrationInterval);
                      console.log("create narration failed")
                      setFailMessage();
                      reject(getNarrationResult.errMsg)
                      break;
                  }
                }else{
                  clearInterval(narrationInterval);
                  setFailMessage();
                  reject(getNarrationResult.errMsg)
                }
              },1000)
            })
            resolve(narrationIntervalPromise)
          }catch(err){
            setFailMessage();
            console.error("get narration error ", err)
            reject(`get narration error ${err}`)
          }
        }else{
          setFailMessage();
          reject(postNarrationResult.errMsg);
        }
      })
      return narrationPromise;
    }catch(err){
      console.error("gen narration failed", err)
      setFailMessage();
      return undefined
    }
  }

  async function genT2I(sceneIndex:number, promptT2I:string):Promise<any>{
    try{
      const t2iPromise = await new Promise(async(resolve, reject)=>{

        const assetId = sessionStorage.getItem("ASSET_ID");
        const t2iReqAssetId = `${assetId}_t2i_${sceneIndex}_${getTimeString()}`

        const t2iRequest = {
          assetId: t2iReqAssetId,
          guide_prompt: promptT2I, 
          output_file: `t2i_client/${assetId}/output-${sceneIndex}-${getTimeString()}`, 
          // batch_size, width, height, seed 는 고정 값
          batch_size: 1, 
          width: 1920,
          height: 1080, 
          seed: -1
        }
  
        const response = await WebPostText2Image(t2iRequest);
        if(response.result===0){
          try{
            const t2iGetPromise:GeneratedImageInterface = await new Promise(async (resolve, reject)=>{
              const t2iInterval = setInterval(async () => {
                const request = {
                  assetId: t2iReqAssetId,
                  moduleType:"t2i",
                }
                const t2iResult = await WebGetAIGernatingStatus(request);
                if(t2iResult.result===0){
                  switch(t2iResult.state){
                    case "ready":
                      console.log(`T2I for scene${sceneIndex+1} ready`);
                      break;
                    case "running":
                      console.log(`T2I for scene${sceneIndex+1} running`);
                      break;
                    case "succeeded":
                      console.log(`T2I for scene${sceneIndex+1} succeeded`);
                      if(t2iResult.output_list.length!==0){
                        clearInterval(t2iInterval);
                        const t2iInfo:GeneratedImageInterface = {
                          sceneNo:sceneIndex+1,
                          bucket:t2iResult.bucket,
                          s3Keys:t2iResult.output_list,
                          selectedS3KeyIndex:0,
                        }
                        resolve(t2iInfo)                    
                      }
                      break;
                    case "failed":
                      clearInterval(t2iInterval);
                      console.log(`T2I for scene${sceneIndex+1} failed`);
                      setFailMessage();
                      reject(`with status failed ${t2iResult.errMsg}`)
                      break;
                  }  
                }else{
                  clearInterval(t2iInterval);
                  setFailMessage();
                  reject(t2iResult.errMsg)
                }            
              }, 5000);
            })  
            resolve(t2iGetPromise);
          }catch(err){
            setFailMessage();
            reject(err);
          }
        }else{
          setFailMessage();
          reject(`T2I error :: ${response.errMsg}`);
        }        
      })
      return t2iPromise
    }catch(err){
      console.error("T2I failed ::: ", err);
      setFailMessage();
      return undefined
    }
  }

  async function genI2v(sceneIndex:number, imageS3Key:string, promptI2V:string, keyIndex:number):Promise<any>{
    try{
      const i2vPromise = await new Promise(async(resolve,reject)=>{
        const assetId = sessionStorage.getItem("ASSET_ID");
        // const reqAssetId = `${assetId}_i2v_${sceneIndex}_${keyIndex}`
        const i2vReqAssetId = `${assetId}_i2v_${sceneIndex}_${keyIndex}_${getTimeString()}`
        const i2vRequest = {
          assetId: i2vReqAssetId,
          guide_prompt: promptI2V,
          input_file: imageS3Key,
          negative_prompt: "",
          output_file_prefix: `scene_${sceneIndex}_${keyIndex}_${getTimeString()}_`,
          output_path: `i2v_client/${assetId}`,
          video_format: "video/h264-mp4",
          seed: -1,
          width: 1920,
          height: 1080,
        }
  
        const i2vResponse = await WebPostImage2Video(i2vRequest);
        
        if(i2vResponse.result===0){
          try{
            const i2vGetPromise = await new Promise(async(resolve, reject)=>{
              const i2vGetInterval = setInterval(async()=>{
                const i2vStatusRequest={
                  assetId: i2vReqAssetId,
                  moduleType:"i2v",
                }
                const i2vStatusResponse = await WebGetAIGernatingStatus(i2vStatusRequest)
    
                if(i2vStatusResponse.result===0){
                  switch(i2vStatusResponse.state){
                    case "ready":
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} ready`);
                      break;
                    case "running":
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} running`);
                      break;
                    case "succeeded":
                      clearInterval(i2vGetInterval);
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} succeeded`);
                      if(i2vStatusResponse.output_list.length!==0){
                        
                        const videoFileIndex = i2vStatusResponse.output_list.findIndex(file=>file.slice(-4)===".mp4")
    
                        if(videoFileIndex<0){
                          reject(`no matching video file for ${i2vReqAssetId}`);
                        }
                        
                        const i2vInfo:GeneratedImageInterface = {
                          sceneNo:sceneIndex,
                          bucket:i2vStatusResponse.bucket,
                          s3Keys:[i2vStatusResponse.output_list[videoFileIndex]],
                          selectedS3KeyIndex:0,
                        }
                        resolve(i2vInfo)                    
                      }
                      break;
                    case "failed":
                      clearInterval(i2vGetInterval);
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} failed`);
                      setFailMessage();
                      reject(`with status failed ${i2vStatusResponse.errMsg}`)
                      break;
                  }  
                }else{
                  clearInterval(i2vGetInterval);
                  setFailMessage();
                  reject(`i2v get error :: ${i2vStatusResponse.errMsg}`)
                }              
              },5000)
            })
            resolve(i2vGetPromise);
          }catch(err){
            setFailMessage();
            reject(err)
          }
        }else{
          setFailMessage();
          reject(`i2v error :: ${i2vResponse.errMsg}`)
        }
      })
      return i2vPromise
    }catch(err){ 
      console.error("I2V Error ", err)
      setFailMessage();
      return undefined
    }
  }


  async function createBGM({
    storeInfo,
    scenarioInfo,
    duration,
    bgmFeature
  }){
    try{
      const createTagPromise:any = await new Promise(async (resolve,reject)=>{
        const createTagRequest={
          assetId: sessionStorage.getItem("ASSET_ID"),
          scenarioInfo: scenarioInfo,
          storeInfo: storeInfo,
          requiredBgmFeatureList: bgmFeature,
          requiredMoodList:[],
        }
        if(isLogging){
          console.log("create tag request ::: ", createTagRequest)        
        }

        // BGM 태그 생성 요청

        const createTag:any = await WebPostCreateBGMTag(createTagRequest);
        if(createTag.result===0){
          console.log("create tag request succeeded");
          const tagResultPromise = await new Promise(async (resolve,reject)=>{
            
            const tagRequest={
              assetId: sessionStorage.getItem("ASSET_ID"),
            }
            if(isLogging){
              console.log("tag request ::: ", tagRequest)        
            }
            const tagResultInterval = setInterval(async () => {
              const tagResult:any = await WebGetCreateBGMTag(tagRequest);
              if(tagResult.result===0){
                switch(tagResult.status){
                  case "progress":
                    console.log("get tag result on progress")
                    break;
                  case "done":
                    clearInterval(tagResultInterval);
                    console.log("get tag result completed")
                    resolve(tagResult)
                    break;
                  case "failed":
                    clearInterval(tagResultInterval);
                    console.log("get tag result failed")
                    setFailMessage();
                    reject(`with get status failed ${tagResult.errMsg}`)
                    break;
                }
              }else{
                clearInterval(tagResultInterval);
                setFailMessage();
                reject(`failed to get tag result ${tagResult.errMsg}`);
              }
            }, 1000);
          })          
          resolve(tagResultPromise)
        }else{
          setFailMessage();
          reject(`faile to post bgm tag ${createTag.errMsg}`)
        }
      })

      const BGM_TAG_LIST = createTagPromise.bgmTagList;

      const createBGMPromise = await new Promise(async(resolve,reject)=>{
        const bgmTagCodeRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          userId: process.env.REACT_APP_BGM_USER_ID,
          secretKey: process.env.REACT_APP_BGM_SECRET_KEY,
          bgmTagList:BGM_TAG_LIST
        }
        if(isLogging){
          console.log("bgmTagCodeRequest ::: ", bgmTagCodeRequest)        
        }
  
  
        // BGM 태그 생성 요청
  
        const bgmTagCodeResult = await WebPostCreateBGMTagCode(bgmTagCodeRequest);
        if(isLogging){
          console.log("bgm tag code result ", bgmTagCodeResult)        
        }
        if(bgmTagCodeResult.result===0){
  
          // 성공한 경우 => GET 요청 없이 바로
  
          const BGM_TAG_CODE_LIST = bgmTagCodeResult.hashTagCodeList;
  
          const bgmRequest = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            userId: process.env.REACT_APP_BGM_USER_ID,
            secretKey: process.env.REACT_APP_BGM_SECRET_KEY,
            hashTagCodeList:BGM_TAG_CODE_LIST,
            duration: duration
          }
          if(isLogging){
            console.log("bgmRequest ::: ", bgmRequest)          
          }
           // BGM 생성 요청
  
          const createBGMResult = await WebCreateBGM(bgmRequest);
          if(isLogging){
            console.log("createBGM result ::: ", createBGMResult);          
          }
          
          if(createBGMResult.result===0 && createBGMResult.bgmList){
            resolve(createBGMResult.bgmList);
          }else{
            setFailMessage();
            reject(`failed to createBGM :: ${createBGMResult.errMsg}`)
          }
        }else{
          setFailMessage();
          reject(`failed to post createBGM :: ${bgmTagCodeResult.errMsg}`)
        }
      })
      return createBGMPromise
    }catch(err){
      console.error("failed to create BGM ",err)
      setFailMessage();
      return undefined;
    }  
  }

  const [ttsActorInfo, setTtsActorInfo] = useState({
    id:actorData[0].id,
    name:actorData[0].name
  });

  async function genTTSActor({
    storeInfo,
    scenarioInfo,
    actorList,
    narrationCharacterFeatureList
  }){
    try{
      let TTS_ACTOR_INFO
      let TTS_INFO
      const ttsSelectPromise:any = await new Promise(async (resolve,reject)=>{
        const request={
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo: storeInfo,
          scenarioInfo: scenarioInfo,
          actorList: actorList,
          requiredCharactorFeatureList: narrationCharacterFeatureList
        }
        if(isLogging){
          console.log("tts select req ::: ", request)          
        }
        const postSelectActortResult = await WebPostSelectTTSActor(request);
        if(postSelectActortResult.result===0){
          try{
            const ttsSelectIntervalPromise = await new Promise(async(resolve,reject)=>{
              const ttsSelectInterval = setInterval(async ()=>{
                const ttsRequest={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const ttsSelectResult = await WebGetSelectTTSActor(ttsRequest);
                if(ttsSelectResult.result===0){
                  switch(ttsSelectResult.status){
                    case "progress":
                      console.log("tts selecting actor on progress")
                      break;
                    case "done":
                      clearInterval(ttsSelectInterval);
                      console.log("tts selecting actor completed");
                      resolve(ttsSelectResult);
                      break;
                    case "failed":
                      clearInterval(ttsSelectInterval);
                      setFailMessage();
                      reject("with status failed");
                      break;
                  }
                }else{
                  clearInterval(ttsSelectInterval);
                  setFailMessage();
                  reject(`failed to get tts actor select :: ${ttsSelectResult.errMsg}`)
                }
              }, 1000)
            })
            if(isLogging){
              console.log('ttsSelectIntervalPromise', ttsSelectIntervalPromise)          
            }
            resolve(ttsSelectIntervalPromise);
          }catch(err){
            setFailMessage();
            reject(err);
          }
        }else{  
          setFailMessage();
          reject(`TTS select actor error : ${postSelectActortResult.errMsg}`)
        }
      })
      
      if(isLogging){
        console.log('tts actor selected ::: ', ttsSelectPromise.actorInfo);          
      }
      if(!ttsSelectPromise.actorInfo.id){
        TTS_ACTOR_INFO = {
          id:actorList[0].id,
          name:actorList[0].name
        }
      }else{
        TTS_ACTOR_INFO = ttsSelectPromise.actorInfo;
      }

      return TTS_ACTOR_INFO
    }catch(err){
      console.error(`gen TTS actor error :: ${err}`)
      setFailMessage();
      return undefined
    }
  }


  async function genTTS({
    scenarioResult,
    ttsActorInfo,
  }){
    try{
      let TTS_INFO = []

      const sceneList = scenarioResult.sceneList;

      for(let i=0; i< sceneList.length; i++){
        const ttsCreatePromise:any = await new Promise(async (resolve,reject)=>{
          const request={
            assetId: sessionStorage.getItem("ASSET_ID"),
            token: process.env.REACT_APP_NTR_TOKEN,
            text: sceneList[i].narration,
            actorId: ttsActorInfo.id,
            language: "auto",
          }
          if(isLogging){
            console.log(`tts_${i} req ::: `, request)          
          }
          
          const postCreateTTSResult = await WebPostCreateSpeak(request);
          
          if(postCreateTTSResult.result===0){
            const createTTSPromise = await new Promise(async(resolve,reject)=>{
              const createTTSInterval = setInterval(async ()=>{
                const ttsCreateRequest={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                  sceneNo: i,
                  speakUrl: postCreateTTSResult.speakResult.speak_v2_url
                }
                const ttsCreateResult = await WebGetCreateSpeak(ttsCreateRequest, process.env.REACT_APP_NTR_TOKEN);
                if(isLogging){
                  console.log('tts create result ::: ', ttsCreateResult)          
                }
                if(ttsCreateResult.result===0){
                  if(ttsCreateResult.speakResult!==undefined){
                    clearInterval(createTTSInterval);
                    console.log(`tts_${i} generating completed`);
                    resolve(ttsCreateResult);
                  }else{
                    switch(ttsCreateResult.status){
                      case "progress":
                        console.log(`tts_${i} generating on progress`)
                        break;
                      case "done":
                        clearInterval(createTTSInterval);
                        console.log(`tts_${i} generating completed`);
                        resolve(ttsCreateResult);
                        break;
                      case "failed":
                        clearInterval(createTTSInterval);
                        console.log(`tts_${i} generating failed`)
                        setFailMessage();
                        reject(`tts_${i} generating failed`);
                        break;
                    }
                  }
                }else{
                  clearInterval(createTTSInterval);
                  setFailMessage();
                  reject(ttsCreateResult.errMsg)
                }
              }, 1000)
            })
            resolve(createTTSPromise);
          }else{  
            setFailMessage();
            reject(postCreateTTSResult.errMsg)
          }
        })
        if(isLogging){
          console.log(`Speak info ${i} :: `, ttsCreatePromise.speakResult)          
        }
        TTS_INFO.push(ttsCreatePromise.speakResult)
      }
      if(isLogging){
        console.log("TTS INFO ::: ", TTS_INFO);          
      }
      return TTS_INFO
    }catch(err){
      console.error(`gen TTS error :: ${err}`)
      setFailMessage();
      return undefined
    }
  }
  
  async function genLayout({
    editedFiles,
    fontData,
    templateData,
    storeInfo,
    scenarioResult,
    generatedImages,
    generatedVideos,
    requiredTextList,
    scenarioInfo,
    fontFeatureList,
    fontColor
  }){
    try{
      const genLayoutPromise:any = await new Promise(async (resolve, reject)=>{

        const fileList = editedFiles.map(item=>({path:item.s3Key}));

        const designatedFontList = fontData.map(item=>item.fontName);

        const randomTemplateIndex = Math.floor(Math.random()*templateData.baseScene.length)
        scenarioContext.setSelectedTemplate(randomTemplateIndex);
        const selectedTemplate = templateData.baseScene[randomTemplateIndex];
        if(isLogging){
          console.log("scene data ::: ", scenarioResult)          
        }
        let coordinateList = [];
        const sceneLength = scenarioResult.sceneList.length;
        
        for(let sceneIndex=0; sceneIndex<sceneLength; sceneIndex++){
          console.group(`=========== text${sceneIndex} ===============`)
          const templateIndex = sceneIndex%selectedTemplate.textPos.length

          let posX = selectedTemplate.textPos[templateIndex].posX
          let posY = selectedTemplate.textPos[templateIndex].posY
          
          let fontSize = selectedTemplate.textPos[templateIndex].height
          const text = scenarioResult.sceneList[sceneIndex].text

          let longestFontIndex = 0;
          let tempWidth = 0;
          fontData.map((font, index)=>{
            const width = getTextWidth(text, `${fontSize}px ${font['font-family']}`);
            if(tempWidth < width){
              longestFontIndex = index;
              tempWidth = width
            }
          })

          const longestFont = fontData[longestFontIndex]['font-family'];
          
          let textWidth = getTextWidth(text, `${fontSize}px ${longestFont}`);
          
          if(isLogging){
            console.log("largest font :: ",longestFont)
            console.log(`original${sceneIndex}      x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)          
          }

          if(textWidth > 1720){
            const ratio = 1720/textWidth;
            fontSize = Math.floor(fontSize*ratio);
            posX = 100;
            textWidth = getTextWidth(text, `${fontSize}px GmarketSansBold`);
          }

          if(posX + textWidth > 1820){
            posX = 1820 - textWidth;
          }

          if(posY + fontSize > 1030){
            posY = 1030 - fontSize
          }
          if(isLogging){
            console.log(`altered${sceneIndex}       x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)          
          }

          coordinateList.push({
            no: sceneIndex,
            coordinates:{
              x1: posX,
              y1: posY,
              x2: posX + textWidth,
              y2: posY + fontSize
            }
          }
          )
          console.groupEnd();
        }

        const aiImageInfoList = generatedImages
        .map(image=>({
          sceneNo: image.sceneNo,
          fileList:image.s3Keys.map(key=>({path:key}))
        }))

        const aiVideoInfoList = generatedVideos
        .map(video=>{
          if(isLogging){
            console.log(`scene ${video.sceneNo} selected i2v index : ${video.selectedS3KeyIndex}`)          
          }
          const keyList = video.s3Keys;
          if(isLogging){
            console.log('changing list from : ', JSON.stringify(keyList))          
          }
          const selectedKey = keyList.splice(video.selectedS3KeyIndex, 1)[0];
          keyList.unshift(selectedKey);
          if(isLogging){
            console.log('changed list : ', JSON.stringify(keyList))          
          }
          return{
            sceneNo: video.sceneNo,
            fileList: keyList.map(key=>({path:key}))
          }
        })
        
        const genLayoutRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo: storeInfo,
          scenarioSceneInfo: scenarioResult,
          fontList: designatedFontList,
          fileList: fileList,
          aiImageInfoList:aiImageInfoList,
          aiVideoInfoList:aiVideoInfoList,
          requiredTextList: requiredTextList,
          baseWidth:1920,
          baseHeight:1080,
          scenarioInfo: scenarioInfo,
          textCoordinatesList: coordinateList,
          requiredFontFeatureList: fontFeatureList,
          requiredFontColor: fontColor,
          requiredMoodList:[],
          useTemplate: 1
        }
        if(isLogging){
          console.log("genLayout request ::: ", genLayoutRequest);                      
        }

        const postGenLayoutResult = await WebPostGenLayout(genLayoutRequest)
        if(isLogging){
          console.log("postGenLayoutResult", postGenLayoutResult)          
        }
        
        if(postGenLayoutResult.result===0){
          try{
            const getGenLayoutPromise = await new Promise(async (resolve, reject)=>{
              const getGenLayoutInterval = setInterval(async ()=>{  
                const request={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                
                const genLayoutResult = await WebGetGenLayout(request);
                if(genLayoutResult.result===0){
                  switch(genLayoutResult.status){
                    case "progress":
                      console.log("gen layout on progress");
                      break;
                    case "done":
                      console.log("gen layout completed");
                      clearInterval(getGenLayoutInterval);
                      resolve(genLayoutResult);
                      break;
                    case "failed":
                      console.log("gen layout failed");
                      clearInterval(getGenLayoutInterval);
                      setFailMessage();
                      reject(`get genLayout failed with status failed ${genLayoutResult.errMsg}`)
                      break;
                  }
                }else{  
                  clearInterval(getGenLayoutInterval);
                  setFailMessage();
                  reject(`get genLayout failed with result 0 ${genLayoutResult.errMsg}`)
                }
              },5000)
            })
            resolve(getGenLayoutPromise);    
          }catch(err){
            setFailMessage();
            console.error("get gen layout failed ::: ", err)
            reject(`get gen layout failed ::: ${err}`)
          }
        }else{
          setFailMessage();
          reject(`genLayout Failed :: ${postGenLayoutResult.errMsg}`)
        }
      })
      return genLayoutPromise;
    }catch(err){
      console.error("Gen layout error ::: ",err)
      setFailMessage();
      return undefined
    }    
  }

  async function genFinalScenario({scenarioSceneInfo, ttsActorInfo, bgmList, narrationList, layoutSceneList}){
    try{

      const dice = Math.random()
      let isUseLastEnding = dice>(1/3) ? 1 : 0;

      if(isUseLastEnding===0){
        scenarioContext.setUseLastEnding(false)
      }else{
        scenarioContext.setUseLastEnding(true)
      }

      const finalScenarioRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        width: 1920,
        height:1080,
        totalTime:shopInfoContext.adTime,
        useLastEnding:scenarioContext.useLastEnding? 1:0,
        endingDuration:1.5,
        scenarioSceneInfo: scenarioSceneInfo,
        narrationActor:{
          name: ttsActorInfo.name,
          id: ttsActorInfo.id
        },
        narrationList: narrationList,
        bgmList: bgmList,
        layoutSceneList: layoutSceneList,        
      }
      if(isLogging){
        console.log('final req ::: ', finalScenarioRequest)          
      }
      if(isLogging){
          
      }
      const postCreateFinalScenario = await WebPostFinalScenario(finalScenarioRequest);
      if(postCreateFinalScenario.result===0){
        if(isLogging){
          console.log('created final scenario ::: ', postCreateFinalScenario)          
        }
        return postCreateFinalScenario;
      }else{
        setFailMessage();
        throw new Error(postCreateFinalScenario.errMsg)
      }
    }catch(err){
      console.error(`Failed to gen final scenario ${err}`)
      setFailMessage();
      return undefined
    }   
  }


  const generateVideo = async () =>{

    for(let i=0; i< shopInfoContext.editedFiles.length; i++){
      if(isLogging){
        console.log('file :: ', shopInfoContext.editedFiles[i])          
      }
      const info = shopInfoContext.editedFiles[i].editInfo;
      if(info){
        const segmentLength = info.end - info.start
        if(segmentLength>5){
          console.log('video over 5');
          setIsVideoInvalid(true);          
          return;
        }
      } 
    }

    try{
      setIsGenerating(true);

      const storeInfo = {
        name: name,
        address: address,
        contract: `${callNumberFirst}-${callNumberSecond}-${callNumberThird}`,
        type: category
      };

      //매장 분석
      console.group("analyze store")
      const analyzeStoreResult:any = await analyzeStore({storeInfo:storeInfo});
      if(!analyzeStoreResult){
        console.error('store analysis failed')
        throw Error("store analysis failed")
      }
      if(isLogging){
        console.log("store analysis ::: ", analyzeStoreResult)          
      } 
      const storeAnalysisInfo = analyzeStoreResult.storeAnalysisInfo
      console.groupEnd();

      // 이미지 분석
      console.group("analyze image")
      const analyzeImageResult:any = await analyzeImage({storeInfo:storeInfo});
      if(!analyzeImageResult){
        console.error('image analysis failed')
        throw Error("image analysis failed")
      }
      shopInfoContext.setImageAnalysisInfo(analyzeImageResult);
      const imageAnalysisInfo = analyzeImageResult
      if(isLogging){
        console.log('analyzeImageResult : ', analyzeImageResult)          
      }
      console.groupEnd()

      // 시나리오 후보 생성
      console.group("create scenario candidate")
      const scenarioCandidateResult:any = await getScenarioCandidate({
        storeInfo:storeInfo,
        storeAnalysisInfo: storeAnalysisInfo,
        imageAnalysisInfo: imageAnalysisInfo,
        requiredMoodList:[]
      });
      if(!scenarioCandidateResult){
        console.error("create scenario candidate failed")
        throw Error("create scenario candidate failed")
      }
      if(isLogging){
        console.log("scenarioCandidateResult : ", scenarioCandidateResult)          
      }
      scenarioContext.setScenario(scenarioCandidateResult.candidateList);
      scenarioContext.setSelectedScenario(scenarioCandidateResult.recommendationNo);
      const candidateList =  scenarioCandidateResult.candidateList
      const recommandationNo = scenarioCandidateResult.recommendationNo
      const selectedScenario = candidateList[recommandationNo]
      console.groupEnd()

      //장면 생성
      console.group("create scene data")
      let createSceneResult:any
      for(let retry=0; retry<5; retry++){
        try{
          createSceneResult = await createScenario({
            time: selectedTime,
            useGenAiImage: includeAiImage? 1 : 0,
            textEffectList: textEffect.map(item=>({effect:item.effect})),
            sceneEffectList: screenEffect.map(item=>({effect:item.effect})),
            storeInfo: storeInfo,
            storeAnalysisInfo: storeAnalysisInfo,
            imageAnalysisInfo: imageAnalysisInfo,
            scenarioInfo: selectedScenario
          });
          if(createSceneResult){
            break;
          }
        }catch(err){
          console.error(`create scene attempt ${retry} failed`)
          if(retry>=4){
            throw Error("create scene failed")
          }
        }
      }
      if(isLogging){
        console.log("scene result :: ",createSceneResult)          
      }
      scenarioContext.setScene(createSceneResult);
      console.groupEnd()

      // 나레이션 생성
      console.group("create narration")
      let tempSceneData  = createSceneResult;
      let narrationTextList = []
      try{
        const narrationPromise:any = await new Promise(async (resolve,reject)=>{
          let newNarrationList = [];
          while(tempSceneData.scenarioResult.sceneList.length !== newNarrationList.length){
            const newNarrationResponse = await genNarration({
              scenario:tempSceneData.scenarioResult,
              totalTime: selectedTime,
            });
            if(newNarrationResponse){
              if(isLogging){
                console.log(`narration list :: ${newNarrationResponse.narrationList}`)          
              }
              newNarrationList = newNarrationResponse.narrationList
            }else{
              setFailMessage();
              reject("Gen narration error");
              break;
            }
          }
          resolve(newNarrationList)
        })
        narrationTextList = narrationPromise;
      }catch(err){
        console.error("narration error :: ", err)
        setFailMessage();
        return undefined
      }

      
      for(let idx=0; idx<tempSceneData.scenarioResult.sceneList.length; idx++){
        tempSceneData.scenarioResult.sceneList[idx].narration = narrationTextList[idx]
        const sceneText = tempSceneData.scenarioResult.sceneList[idx].text
        const regex = /[a-zA-Z가-힣0-9!]/;
        if(!regex.test(sceneText[sceneText.length-1])){
          tempSceneData.scenarioResult.sceneList[idx].text = sceneText.slice(0,-1);
          //자막 마지막의 특문 제거( 느낌표 제외 )
        }
        // 없음, null, undefined 등 텍스트 제거
        if(SCENE_TEXT_EXCEPTIONS.includes(tempSceneData.scenarioResult.sceneList[idx].text)){
          tempSceneData.scenarioResult.sceneList[idx].text = ""
        }
      }
      console.groupEnd()

      // T2I 생성 ( prompt가 있는 경우 )
      console.group("create t2i")
      const NULLPROMPT = [undefined, null, ""," ","없음","none"]
      let genImages:GeneratedImageInterface[] = [];
      for(let sceneIndex=0; sceneIndex<tempSceneData.scenarioResult.sceneList.length; sceneIndex++){        
        const promptT2I = tempSceneData.scenarioResult.sceneList[sceneIndex]["promptT2I"]
        if(isLogging){
          console.log(`scene ${sceneIndex+1} t2i prompt ::: ${promptT2I}`)          
        }
        if(!NULLPROMPT.includes(promptT2I)){
          const t2iInfo:GeneratedImageInterface = await genT2I(sceneIndex, tempSceneData.scenarioResult.sceneList[sceneIndex]["promptT2I"]);
          if(isLogging){
            console.log(`T2I_${sceneIndex+1} ::: `, t2iInfo);          
          }
          genImages.push(t2iInfo)
        }
      }
      console.groupEnd()

      // I2V 생성
      console.group("create i2v")
      let genVideos = JSON.parse(JSON.stringify(genImages));;  
      for(let t2iIndex=0; t2iIndex<(ENABLE_I2V?genImages.length:0); t2iIndex++){
        const sceneIndex = genImages[t2iIndex].sceneNo;
        let i2vKeyList = []
        let i2vBucket = ""
        let i2vModule = ""  
        for(let keyIndex=0; keyIndex<genImages[t2iIndex].s3Keys.length; keyIndex++){
          const key = genImages[t2iIndex].s3Keys[keyIndex];
          const i2vPrompt = tempSceneData.scenarioResult.sceneList[sceneIndex-1]["promptI2V"]
          if(isLogging){
            console.log(`scene ${t2iIndex+1}_${keyIndex+1} i2v prompt ::: ${i2vPrompt}`)
          }
          const i2vInfo = await genI2v(sceneIndex, key, i2vPrompt, keyIndex);
          i2vKeyList.push(i2vInfo.s3Keys[0]);
          i2vBucket = i2vInfo.bucket
          i2vModule = i2vInfo.module
        }
        genVideos[t2iIndex].s3Keys = i2vKeyList
        genVideos[t2iIndex].bucket = i2vBucket
        genVideos[t2iIndex].module = i2vModule
      }

      scenarioContext.setGeneratedImages(genImages);
      scenarioContext.setGeneratedVideos(ENABLE_I2V?genVideos:[]);
      scenarioContext.setScene(tempSceneData);

      const generatedImages = genImages
      const generatedVideos = ENABLE_I2V? genVideos : []
      const scene = tempSceneData
      console.groupEnd()

      // BGM 생성
      console.group("create bgm")
      const bgmResult:any = await createBGM({
        storeInfo: storeInfo,
        scenarioInfo: selectedScenario,
        duration: selectedTime,
        bgmFeature:[]
      })
      if(!bgmResult){
        console.error("create bgm failed")
        throw Error("create bgm failed")
      }
      const bgmList = bgmResult;
      console.groupEnd()

      // TTS 액터 선택 
      console.group("get tts actor")
      const ttsActorResult:any = await genTTSActor({
        storeInfo: storeInfo,
        scenarioInfo: selectedScenario,
        actorList: actorData,
        narrationCharacterFeatureList: []
      })
      if(!ttsActorResult){
        console.error("create TTS actor failed")
        throw Error("create TTS actor failed")
      }
      const ttsActorInfo = ttsActorResult;
      console.groupEnd()

      // TTS 생성 
      console.group("gen tts")
      const ttsResult:any = await genTTS({
        scenarioResult: scene.scenarioResult,
        ttsActorInfo: ttsActorInfo,
      });
      if(!ttsResult){
        console.error("create TTS failed")
        throw Error("create TTS failed")
      }
      const narrationList = ttsResult.map(item=>({
        duration: item.duration,
        textCount: item.textCount,
        path: item.path,
      }))
      console.groupEnd()

      // 레이아웃 생성
      console.group("gen layout")
      const layoutResult:any = await genLayout({
        editedFiles: shopInfoContext.editedFiles,
        fontData: fontData,
        templateData: templateData,
        storeInfo: storeInfo,
        scenarioResult: scene.scenarioResult,
        generatedImages: generatedImages,
        generatedVideos: generatedVideos,
        requiredTextList:[],
        scenarioInfo: selectedScenario,
        fontFeatureList:[],
        fontColor:""
      });
      if(!layoutResult){
        console.error("gen layout failed")
        throw Error("gen layout failed")
      }
      let layoutInfo = layoutResult.sceneList;
      if(isLogging){
        console.log("layout info :: ", layoutInfo)          
      }
      console.groupEnd()


      // 탬플릿 생성

      const randomTemplateIndex = Math.floor(Math.random()*templateData.baseScene.length)
      const selectedTemplate = templateData.baseScene[randomTemplateIndex];

      const randomFontIndex = Math.floor(Math.random()*fontData.length);
      const selectedFont = fontData[randomFontIndex].fontName;

      const templateLayoutList = []

      const templateSceneList = scene.scenarioResult.sceneList;

      for(let i=0; i<templateSceneList.length; i++){
        const textInfo = selectedTemplate.textPos[i%(selectedTemplate.textPos.length)]
        const sceneInfo = templateSceneList[i]

        const fileIndex = shopInfoContext.editedFiles.findIndex(item=>item.file.name === sceneInfo.fileName)
        let filePath = 'none'
        if(fileIndex<0){
          filePath = 'none'
        }else{
          filePath = shopInfoContext.editedFiles[fileIndex].s3Key;
        }

        const TemplateLayout = {
          backgroundColor:"#000000",
          layoutList:[
            {
              tabIndex:0,
              type: sceneInfo.sceneEffect,
              width: 1920,
              height: 1080,
              path: filePath,
              posX: 0,
              posY: 0
            },
          ],
          textList:[
            {
              text: sceneInfo.text,
              effect: sceneInfo.textEffect,
              font: selectedFont,
              fontColor: "#FFFFFF",
              posX:textInfo.posX,
              posY:textInfo.posY,
              width:1000,
              height:textInfo.height
            }
          ]
        }
        
        templateLayoutList.push(TemplateLayout)
      }

      const imageAnalysis = imageAnalysisInfo;
      if(isLogging){
        console.log("image analysis ::: ", imageAnalysis)          
      }

      let templateSceneData = scene.scenarioResult;
      if(isLogging){
        console.log('template scene data :: ', templateSceneData)          
      }
      for(let idx=0; idx < templateSceneData.sceneList.length; idx++ ){
        const fileName = templateSceneData.sceneList[idx].fileName;
        if(isLogging){
          console.log(`scene data ${idx} ::: ${templateSceneData.sceneList[idx]}`)          
        }
        const matchedIdx = imageAnalysis.descList.findIndex(item=>item.fileName === fileName)
        let matchedColorList=[]
        if(matchedIdx<0){
          matchedColorList = ["#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000"]
        }else{
          matchedColorList = imageAnalysis.descList[matchedIdx].colorList.map(item=>item.hex);
        }
        if(isLogging){
          console.log("matched color list :: ", matchedColorList)          
        }
        templateSceneData.sceneList[idx].colorList = matchedColorList;
      }

      if(isLogging){
        console.log("alternative template scene data ::: ", templateSceneData)          
      }





      console.group("gen final scenario")
      const dice = Math.random()
      let isUseLastEnding = dice>(1/3) ? 1 : 0;

      if(isUseLastEnding===0){
        scenarioContext.setUseLastEnding(false)
      }else{
        scenarioContext.setUseLastEnding(true)
      }

      const finalScenarioRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        width: 1920,
        height:1080,
        totalTime: selectedTime,
        useLastEnding: isUseLastEnding,
        endingDuration:1.5,
        scenarioSceneInfo: templateSceneData,
        narrationActor: ttsActorInfo,
        narrationList: narrationList,
        bgmList: bgmList,
        layoutSceneList: layoutInfo,        
      }

      if(isLogging){          
        console.log('final req ::: ', finalScenarioRequest)
      }      

      const finalScenarioPromise:any = await new Promise(async(resolve, reject)=>{
        const postCreateFinalScenario = await WebPostFinalScenario(finalScenarioRequest);
        if(postCreateFinalScenario.result===0){
          if(isLogging){
            console.log('created final scenario ::: ', postCreateFinalScenario)          
          }
          resolve(postCreateFinalScenario);
        }else{
          reject(postCreateFinalScenario.errMsg)
        }
      })
      scenarioContext.setFinalScenario(finalScenarioPromise.scenarioResult);
      shopInfoContext.setCompletedStep(3);

      editorContext.setOutputPresignedUrl(undefined);
      editorContext.setOutputVideoUrl(undefined);
      editorContext.setVideoCreated(false);

      console.groupEnd()
      setIsGenerating(false);
      props.setStep(4);
      nav("/edit");
    }catch(err){
      console.error(`One shot generate failed :: ${err} / assetId : ${sessionStorage.getItem("ASSET_ID")}`)
      setFailMessage();
    }
  }

  const foot= isGenerating? <></> :
    step===1?
      <Button
        $buttonType="capsule"
        $variant="brand1"
        showRightIcon
        rightIcon="CaretRight"
        text={"광고 분위기와 소재 만들기"}
        onClick={handleToStepTwo}
        disabled={!stepOneClear}
      />
      :
      <>
        <Button
          $buttonType="capsule"
          $variant="brand1-subtle"
          showRightIcon
          rightIcon="CaretRight"
          text={"AI로 우리매장 특징 찾기"}
          onClick={(e:any)=>{handleToPoint()}}
          disabled={!isValid}
        />
        <Button
          $buttonType="capsule"
          $variant="brand1"
          showRightIcon
          rightIcon="CaretRight"
          text={"AI 광고 영상으로 바로 만들기"}
          onClick={generateVideo}
          disabled={!isValid}
        />
      </>

  return (
    <Container
      head={head}
      content={contents}
      foot={foot}
    />
  )
}

export default ShopInfo

const ButtonQuestionWrapper = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
`

const SelectTimeWrapper = styled.div`
  align-items: flex-start;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
  box-sizing: border-box;
  width:100%;
`