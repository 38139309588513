import React, {useEffect, useRef, useState} from "react";

import styled, {keyframes} from "styled-components";
import PlayerController from "../Player";
import PlayButton from "../PlayButton";

import {VideoPlayerStyle, PlayButtonScreenStyle, PlayButtonWrapperStyle, PlayerControllerStyle} from "./VideoPlayer.styled"


function VideoPlayer(props){

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(15);

  const videoRef = useRef(null);

  useEffect(()=>{
    // console.log("video rerender")
  },[])

  useEffect(()=>{
    const videoEl = videoRef.current as HTMLVideoElement
    if(videoEl){
      if(isPlaying){
        videoEl.play();
      }else{
        videoEl.pause();
      }
    }
  },[isPlaying])

  const handleClickPlay = () => {
    setIsPlaying(!isPlaying);    
  }

  const handleClickBack = () => {
    // setCurrentTime(currentTime=>currentTime-5);
    const videoEl = videoRef.current as HTMLVideoElement;
    if(videoEl){
      videoEl.currentTime = videoEl.currentTime - 5
    }
    
  }
  const handleClickForward = () => {
    // setCurrentTime(currentTime=>currentTime+5);
    const videoEl = videoRef.current as HTMLVideoElement;
    if(videoEl){
      videoEl.currentTime = videoEl.currentTime + 5
    }
  }

  const controllerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
    // console.log("mouse enter")
  }
  const handleMouseLeave = () => {
    setIsHovering(false);
    // console.log("mouse leave")
  }

  useEffect(()=>{
    const controller = controllerRef.current as HTMLElement
    // console.log(controller)
    if(isHovering){
      if(controller){
        if(!controller.classList.contains("active")){
          console.log("activate")
          controller.classList.add("active")
        }
      }
    }else{
      if(controller){
        if(controller.classList.contains("active")){
          console.log("deactivate")
          controller.classList.remove("active")
        }
      }
    }
  },[isHovering])

  const handleDragHandle = (time) => {
    // console.log("drag", time)
    setCurrentTime(time);
  }

  // useEffect(() => {
  //   let interval;

  //   if (isPlaying) {
  //     interval = setInterval(() => {
  //       setCurrentTime((prevTime) =>{
  //         if(prevTime+0.1 > duration){
  //           clearInterval(interval);
  //           setIsPlaying(false);
  //           return duration
  //         }else{
  //           return prevTime + 0.1
  //         }
  //       });
  //     }, 100);
  //   } else {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   }

  //   // Clean up the interval on component unmount or when isPlaying changes
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [isPlaying]);

  const handleLoadMetadata = (e:any) => {
    if(props.onLoadedMetadata){
      props.onLoadedMetadata(e);
    }
    const videoEl = videoRef.current as HTMLVideoElement;
    if(videoEl){
      setDuration(videoEl.duration);
    }
  }

  const handleTimeUpdate = (e:any)=>{
    const videoEl = videoRef.current as HTMLVideoElement;
    if(videoEl){
      setCurrentTime(videoEl.currentTime);
      if(videoEl.currentTime>=videoEl.duration){
        setIsPlaying(false);
      }
    }
  }

  
  return(
    <VideoPlayerStyle
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <video
        ref={videoRef}
        src={props.src}
        width={"100%"}
        onLoadedMetadata={handleLoadMetadata}
        onTimeUpdate={handleTimeUpdate}
      />
      <PlayButtonScreenStyle >
        <PlayButtonWrapperStyle>
          <PlayButton isPlaying={isPlaying} onClick={handleClickPlay}/>
        </PlayButtonWrapperStyle>
      </PlayButtonScreenStyle>
      <PlayerControllerStyle
        active={isHovering}
        isPlaying={isPlaying}
        duration={duration}
        current={currentTime}
        onDragHandle={handleDragHandle}
        onClickPlay={handleClickPlay}
        onClickBack={handleClickBack}
        onClickForward={handleClickForward}
      />
    </VideoPlayerStyle>
  )
}

export default VideoPlayer
