import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import PropTypes from "prop-types"
import theme from "../../../styles/theme";

import { InputTextStyle, stateEnum, sizeEnum } from "./InputText.styled";

type StateTypes = "default"|"filled"|"active"|"invalid"|"disabled"
type SizeTypes = "sm" | "md" | "lg"

interface InputTextProps extends React.ComponentProps<any>{
  state: StateTypes|undefined,
  size: StateTypes|undefined,
  value: SizeTypes|undefined,
  disabled:boolean|undefined
}



function InputText(props){
  const [state, setState] = useState(stateEnum["default"]);
  const ref= useRef(null);

  useEffect(()=>{
    if(props.state){
      setState(stateEnum[`${props.state}`])
    }else{
      if(props.value){
        if(state!==stateEnum["active"]){
          setState(stateEnum["filled"])
        }
      }else{
        setState(stateEnum["default"])
      }
    }
  },[props.state, props.value])

  

  function changeState(newState){
    if(!props.state){
      setState(newState);
    }
  }

  const handleFocus = (e:any) => {
    changeState(stateEnum["active"]);
    if(props.onFocus){
      props.onFocus(e);
    }
  }
  const handleBlur = (e:any) => {
    const element = (e.currentTarget as HTMLInputElement);
    if(element.value){
      changeState(stateEnum["filled"]);
    }else{
      changeState(stateEnum["default"]);
    }
    if(props.onBlur){
      props.onBlur(e);
    }
  }

  useEffect(()=>{
    if(props.state){
      setState(props.state)
    }
  },[props.state])

  return(
    <InputTextStyle
      state={state}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={props.placeholder?props.placeholder:"placeholder"}
      disabled={props.state==="disabled" || props.disabled}
      {...props}
    />
  )
}

export default InputText


