import React, {useState} from "react";

import styled from "styled-components";

import Icon from "../../../Icon";

function AiImage(props){

  const [isActive, setIsActive] = useState(false);

  const handleOnClick = (e:any)=>{
    if(props.onClick && props.selectable){
      props.onClick(props.index);
      setIsActive(!isActive)
    }
  }
  
  return(
    <AiImageStyle {...props} onClick={handleOnClick}>
      <ContentUnitStyle {...props} className={isActive?"active":undefined} {...props}/>
      <TextWrapperStyle className={isActive?"active":undefined} {...props}>
        {props.name}
      </TextWrapperStyle>
    </AiImageStyle>
  )
}

export default AiImage

const AiImageStyle = styled.div`
  align-items: center;
  display:flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-02"]};
  position:relative;
  width: 160px;
  cursor:${(props:any)=>props.selectable? 'pointer' : 'default'};
  user-select:none;
`

const ContentUnitStyle = styled.div`
  align-self: stretch;
  background-image: url(${(props:any)=>props.sourceURL});
  background-position: 50% 50%;
  background-size: cover;

  border-radius:${(props)=>props.theme.radius["radius-02"]};
  border: ${(props)=>props.theme.border["border-02"]} solid;
  border-color: ${(props)=>props.theme.colors["border-primary"]};
  height:108px;
  position:relative;
  width:100%;
  box-sizing:border-box;
  

  &.active{
    border: ${(props)=>props.theme.border["border-03"]} solid;
    border-color: ${(props)=>props.theme.colors["border-active"]};
  }

  &:hover{
    ${(props:any)=> (borderStyle(props.selectable, props.theme))};
    // border: ${(props)=>props.theme.border["border-03"]} solid;
    // border-color: ${(props)=>props.theme.colors["border-active"]};
  }
`

function borderStyle(selectable, theme){
  switch(selectable){
    case true:
      return({
        'border':`${theme.border['border-03']} solid`,
        'border-color':theme.colors['border-active']
      })
    case false:
      return
  }
}

const TextWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-caption-01"]};
  color: ${(props)=>props.theme.colors["text-primary-inverse"]};
  position:absolute;
  flex:1;
  display:flex;
  justify-content: flex-end;
  align-items:center;
  width: 100%;
  box-sizing:border-box;
  
  padding-top: ${(props)=>props.theme.spacing["spacing-04"]};
  padding-right: ${(props)=>props.theme.spacing["spacing-06"]};  

  &.active{
    color: ${(props)=>props.theme.colors["text-brand1"]};
  }
`