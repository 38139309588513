import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, IconButton, Slider, Stack } from "@mui/material";

import { ReactComponent as ControlForwardIcon } from '../../../../Assets/icon-editor/player-control-forward.svg';
import { ReactComponent as ControlBackwardIcon } from '../../../../Assets/icon-editor/player-control-backwack.svg';
import { ReactComponent as ControlPlayIcon } from '../../../../Assets/icon-editor/player-control-play.svg';
import { ReactComponent as ControlStopIcon } from '../../../../Assets/icon-editor/player-control-stop.svg';
import { ReactComponent as ControlPauseIcon } from '../../../../Assets/icon-editor/player-control-pause.svg';
import { ReactComponent as PauseIcon } from '../../../../Assets/icon-editor/Pause.svg';
import { ReactComponent as ControlSoundOnIcon } from '../../../../Assets/icon-editor/player-control-sound-on.svg';
import { ReactComponent as ControlSoundOffIcon } from '../../../../Assets/icon-editor/player-control-sound-off.svg';
import { ReactComponent as ControlFocusIcon } from '../../../../Assets/icon-editor/player-control-focus.svg';
import { EditorContext } from "../../../../Contexts/EditorContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../editorPageContext";
import { WebGetUrl } from "../../../../Api/resource";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
// import { testVideo } from "../../../../../test.mp4";


const SectionPlayerContainer = styled.div`
  height: 400px;
  left: 432px;
  position: absolute;
  top: 0;
  width: 672px;
  background-color: #ffffff;
`;

const OverlapGroupWrapper = styled.div`
  background-color: var(--tokens-color-background-tertiary-duplicate);
  height: 400px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 672px;
`;

const OverlapGroup3 = styled.div`
  background-image: url(../../../../../../../static/img/image-2-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 400px;
  left: 9px;
  position: relative;
  width: 672;
`;

const Frame29 = styled.div`
  align-items: center;
  background-color: #00000080;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  left: 522px;
  padding: 0px 8px;
  position: relative;
  top: 287px;
`;

const TextWrapper20 = styled.div`
  color: #ffffff;
  font-family: "Pretendard Variable-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 34px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

const PlayerBody = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  // gap: var(--tokens-spacing-04-duplicate);
  left: 16px;
  padding: var(--tokens-spacing-05-duplicate) var(--tokens-spacing-04-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-04-duplicate);
  position: absolute;
  top: 16px;
  width: 640px;
  height: 384px;
`;


// const BodyStyle = styled.div`
//   height :440px;
//   width: 100%;
//   box-sizing: border-box;
//   background-color: #000000;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   position:relative;
// `
const PlayerStyle= styled.video`
  background-color: #f0f2f3;
  width: 640px;
  height: 308px;
  position:relative;
`
const HiddenVideo= styled.video`
  position:relative;
  display: none;
`

const Frame32 = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
`;

const TextWrapper21 = styled.div`
  color: var(--tokens-color-text-primary-duplicate);
  font-family: var(--pre-body-04-font-family);
  font-size: var(--pre-body-04-font-size);
  font-style: var(--pre-body-04-font-style);
  font-weight: var(--pre-body-04-font-weight);
  letter-spacing: var(--pre-body-04-letter-spacing);
  line-height: var(--pre-body-04-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

//#EDEEFB



export const SectionPlayer = (): JSX.Element => {

  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);

  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [thumbs, setThumbs] = useState<any[]>([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isMuted, setIsMuted] = useState(false); 

  const [isPaused, setIsPaused] = useState(true);

  const editorContext = useContext(EditorContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext= useContext(EditorPageContext);
  const shopInfoContext = useContext(ShopInfoContext)
  /// 미리보기 페이지 컨텍스트
  let outputUrl = editorContext.outputVideoUrl;
  /// 출력 영상 URL : 출력 영상의 파일 Blob URL
  const outputUrlPresigned = editorContext.outputPresignedUrl;
  /// 출력 영상 presigned url : AWS S3에 업로드된 출력 영상 파일의 presigned url

  const videoPlayerRef =  useRef(null);
  const bgmRef = useRef(null);
  const textVideoRef= useRef(null);
  const ttsRef = useRef(null);
  const bgVideoRef = useRef(null);


  // useEffect(() => {
  //   const fileUrl = URL.createObjectURL("../../../../../test.mp4") // 테스트용 임시 
  //   outputUrl = fileUrl;
  // }, []);

  function formatMilliseconds(ms) {
    // 초, 분, 시간 계산
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    // 두 자리 형식으로 변환 (padStart로 0 채우기)
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

  const handleLoadMetaData = async (e:any)=>{
    // console.log("============= video load metadata ===============================")
    const element = videoPlayerRef.current as HTMLVideoElement;
    setVideoDuration(element.duration);

    //genThumbnails(1);
    //console.log(element.duration);

    // if(props.fileInfo.editInfo) {
    //   if(isLogging){
    //     console.log("edit info on load metadata :: ", props.fileInfo.editInfo)
    //   }
    //   const editInfo = props.fileInfo.editInfo;
    //   const duration = editInfo.end - editInfo.start;
    //   setVideoDuration(duration);
    //   if(duration > minDuration){
    //     setLoopSegment([editInfo.start, editInfo.start+minDuration])
    //   }else{
    //     setLoopSegment([editInfo.start, editInfo.end])
    //   }
    // } else {
    //   setVideoDuration(element.duration);
    //   setLoopSegment([0, minDuration]);
    // }
    // try{
    //   await element.play();
    // }catch(err){
    //   console.error(err)
    // }

    setIsVideoLoaded(true);
  }

  const [sceneTimeList, setSceneTimeList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bgUrlList, setBgUrlList] = useState([]);
  useEffect(()=>{
    setList()
  },[scenarioContext.finalScenario])

  async function setList(){
    const newList=[]
    const newBgList = []

    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      const scene = scenarioContext.finalScenario.scenes[i];

      if(newList.length===0){
        newList.push(scene.time);
      }else{
        newList.push(newList[newList.length-1] + scene.time);
      }
      const layoutPath = scene.layoutList[0].path;
      if(layoutPath==="search"){
        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;
        let searchBg = `./searchBarW_U3.png`
        const nameLength=name.length
        if(consonantCode === 0){ //를
          if(nameLength<=3){searchBg = `./searchBarW1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW4.png`}
          else {searchBg = `./searchBarW5.png`}
        }else{//을
          if(nameLength<=3){searchBg = `./searchBarW_U1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW_U2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW_U3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW_U4.png`}
          else {searchBg = `./searchBarW_U5.png`}
        }
        newBgList.push(searchBg)
      }else{
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: layoutPath.includes("i2v_client")? process.env.REACT_APP_OUTPUT_BUCKET_NAME :process.env.REACT_APP_INPUT_BUCKET_NAME,
          key : layoutPath
        })
        if(response.result===0){
          newBgList.push(response.presignedUrl)
        }else{
          newBgList.push("")
        }
      }      
    }
    // console.log("time array :: ", newList)
    setSceneTimeList(newList);
    setBgUrlList(newBgList);
  }

  function findCurrentIndex(now){
    return sceneTimeList.findIndex(time=>time>now);    
  }

  useEffect(()=>{
    const currentTimeIndex = findCurrentIndex(currentTime)
    if(currentTimeIndex>=0){
      // console.log(`current index :: ${currentTimeIndex} at ${currentTime}s`)
      setCurrentIndex(findCurrentIndex(currentTime));
  
      const textVideoEl = textVideoRef.current
      if(textVideoEl){
        textVideoEl.currentTime = currentTime - (sceneTimeList[currentTimeIndex]-sceneTimeList[0])
      }
      const ttsEl = ttsRef.current
      if(ttsEl){
        // ttsEl.currentTime = currentTime - (sceneTimeList[currentTimeIndex]-sceneTimeList[0])
      }

      const bgVideoEl = bgVideoRef.current
      if(bgVideoEl){
        bgVideoEl.currentTime = currentTime - 3
      }
    }

  },[currentTime])


  const [ttsUrlList, setTtsUrlList] = useState(undefined);
  const [ttsUrl, setTtsUrl] = useState(undefined)
  useEffect(()=>{
    getTTSUrls();
  },[])
  async function getTTSUrls(){
    const scenes = scenarioContext.finalScenario.scenes
    const newList = [];
    for(let i=0; i< scenes.length; i++){
      const assetId = sessionStorage.getItem("ASSET_ID")
      const fileName = `scene`+("00"+i.toString()).slice(-2)+".mp3"
      // console.log("request tts :: ", fileName)
      const urlResponse = await WebGetUrl({
        assetId: assetId,
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: `adv_api_server/${assetId}/tts/${fileName}`
      })
      const newUrl = urlResponse.presignedUrl;
      newList.push(newUrl);
    }
    // console.log("tts urls :: ", newList);
    setTtsUrlList(newList);
  }


  function isNear(value, target){
    return Math.abs(value-target)>0.4? false : true;
  }  

  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);

  const [textVideoUrlList, setTextVideoUrlList] = useState([]);
  const [textVideoUrl, setTextVideoUrl] = useState(undefined);
  const handleVideoTimeUpdate = () => {
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element) {
      setCurrentTime(element.currentTime);
      // console.log("Player time ::: ", element.currentTime)

      const textVideoEl = textVideoRef.current

      const ttsAudioEl = ttsRef.current
      // if(element.currentTime<4){
      //   if(isNear(element.currentTime,0)){
      //     setTextVideoUrl('./scene1Text.webm')
      //     setTtsUrl(ttsUrlList? ttsUrlList[0] : undefined)
      //   }
      //   if(textVideoEl){textVideoEl.currentTime=element.currentTime}
      //   if(ttsAudioEl)(ttsAudioEl.play())

      // }else if(element.currentTime<7){
      //   if(isNear(element.currentTime,4)){
      //     setTextVideoUrl('./scene2Text.webm')
      //     setTtsUrl(ttsUrlList? ttsUrlList[1] : undefined)
      //   }
      //   if(textVideoEl){textVideoEl.currentTime=element.currentTime-4}
      //   if(ttsAudioEl)(ttsAudioEl.play())
      // }else if(element.currentTime<11){
      //   if(isNear(element.currentTime,7)){
      //     setTextVideoUrl('./scene3Text.webm')
      //     setTtsUrl(ttsUrlList? ttsUrlList[2] : undefined)
      //   }
      //   if(textVideoEl){
          
      //       textVideoEl.currentTime=element.currentTime-7
          
      //   }
      //   if(ttsAudioEl)(ttsAudioEl.play())
      // }
      // setPlayerTime(element.currentTime);
      // if(element.currentTime < loopSegment[0] || element.currentTime >= loopSegment[1]) { // 구간 반복
      //   element.currentTime = loopSegment[0];
      //   try{
      //     await element.play();
      //   }catch(err){
      //     console.error(err);
      //   }
      // }
    }
  }
  
  const handleTimeChange = (event: Event, newValue: number | number[]) => {
    const time = newValue as number;
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element) {
      setCurrentTime(time);
      element.currentTime = time;
    }
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      bgmAudioEl.currentTime = time;
    }
  }
  useEffect(()=>{
    const element = videoPlayerRef.current as HTMLVideoElement
    // console.log(editorPageContext.ntrUrlList , "==>", editorPageContext.ntrUrlList[currentIndex]);
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl && element){
      if(!element.paused){
        ttsAudioEl.play()
      }
    }
  },[currentIndex])

  const handleStop = () => {
    console.log('player handleStop');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      element.pause();
      element.load();
      element.currentTime=0;
    }
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      try{
        bgmAudioEl.pause();
        bgmAudioEl.load();
        bgmAudioEl.currentTime =0;
      }catch(err){
        console.error("bgm audio pause error ", err)
      }
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      try{
        ttsAudioEl.pause()
        ttsAudioEl.load()
        ttsAudioEl.currentTime = 0;
      }catch(err){
        console.error("tts audio pause error ", err)
      }
    }
    setIsPaused(true)
  }

  const handlePlay = () => {
    console.log('player handlePlay');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      element.play();
    }

    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      bgmAudioEl.play();
    }

    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){ttsAudioEl.play()}

    setIsPaused(false)
  }

  const handlePause = () => {
    console.log('player handlePause');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      element.pause();
    }
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      bgmAudioEl.pause();
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      ttsAudioEl.pause()
    }
    setIsPaused(true)
  }

  const handleBackward = () => {
    console.log('player handleBackward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime - 5;
      if(seekTime < 0) seekTime = 0;
      element.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  }

  const handleForward = () => {
    console.log('player handleForward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime + 5;
      if(seekTime > videoDuration) seekTime = videoDuration;
      element.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  }

  const handleSound = () => {
    console.log('player handleSound');
    setIsMuted(!isMuted);
  }

  const handleFocus = () => {
    console.log('player handleFocus');
  }


  const [bgmUrl, setBgmUrl] = useState(undefined);
  useEffect(()=>{
    setBgmUrl(editorPageContext.bgmUrlList[scenarioContext.selectedBgmIndex])
  },[scenarioContext.selectedBgmIndex])

  const [scenePlayerEnable, setScenePlayerEnable] = useState([]);
  useEffect(()=>{
    const length = scenarioContext.finalScenario?.scenes?.length;
    if(length){
      console.log(`${length} player needed`)
      const arr = new Array(length).fill(false);
      setScenePlayerEnable(arr);
    } 
  },[])




  return (
    <SectionPlayerContainer>
      {/* <OverlapGroupWrapper>
        <OverlapGroup3>
          <Frame29>
            <TextWrapper20>Title</TextWrapper20>
          </Frame29>
        </OverlapGroup3>
      </OverlapGroupWrapper> */}
      <PlayerBody>
        <div style={{position:"relative",width:"640px", height:"360px", display:"flex", alignItems:"center", justifyContent:"center", overflow:"hidden", border:"none", zIndex:1}}>
          <audio preload="" ref={bgmRef} onError={(err)=>{console.error("bgm error ", err)}} src={bgmUrl} />
          <audio preload="" ref={ttsRef} onError={(err)=>{console.error("tts error ", err)}} src={editorPageContext.ntrUrlList[currentIndex]}/>

          <video style={{position:"absolute", height:"100%", zIndex:8, top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={bgUrlList[currentIndex]} preload="" muted />
          <img style={{position:"absolute", height:"100%", width:"100%",zIndex:9, objectFit:"cover", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={bgUrlList[currentIndex]} alt={"bg"}/>  
          
          {/* {
            scenarioContext.finalScenario.scenes[currentIndex].type==="동영상"?
              <video style={{position:"absolute", height:"100%", zIndex:8, top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={bgUrlList[currentIndex]} preload="" muted />
            :
              <img style={{position:"absolute", height:"100%", width:"100%",zIndex:9, objectFit:"cover", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={bgUrlList[currentIndex]} alt={"bg"}/>  
          } */}
          {/* <img style={{position:"absolute", height:"100%", width:"100%",zIndex:9, objectFit:"cover", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={bgUrlList[currentIndex]} alt={"bg"}/> */}
          <video preload="" ref={textVideoRef} style={{position:"absolute", height:"100%", zIndex:10, top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} src={editorPageContext.textVideoUrlList[currentIndex]} key={`text-video`} muted />
        </div>
        <PlayerStyle ref = {videoPlayerRef}
          className={"preview-video-player"}
          onContextMenu={(e)=>{e.preventDefault()}}
          //src="http://prestoworks-demo.s3.ap-northeast-2.amazonaws.com/Presto-Ultra_01.mp4"
          src={editorContext.outputVideoUrl}
          onTimeUpdate={handleVideoTimeUpdate}
          onLoadedMetadata={handleLoadMetaData}
          onEnded={()=>{setIsPaused(true)}}
          //crossOrigin="anonymous"
          muted
          style={{opacity:0, position:"absolute"}}
          //loop 
        ></PlayerStyle>
        <Stack gap={2} direction="row" sx={{ width: '100%', marginTop: '4px', marginLeft: '16px', paddingRight: '20px' }}>
          <Slider defaultValue={0} min={0} max={videoDuration} value={currentTime} onChange={handleTimeChange}></Slider>
          <Box display="flex" sx={{ width: '220px' }} justifyContent="center" gap={1} alignItems="center">
            <TextWrapper21>{formatMilliseconds(currentTime*1000)}</TextWrapper21>
            <TextWrapper21>/</TextWrapper21>
            <TextWrapper21>{formatMilliseconds(videoDuration*1000)}</TextWrapper21>
          </Box>
        </Stack>
        <Box display="flex" sx={{ width: '100%', paddingX: '4px'}} justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleForward}>
              <ControlForwardIcon width={24} height={24} />
            </IconButton>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleBackward}>
              <ControlBackwardIcon width={24} height={24} />
            </IconButton>
            {
              isPaused?
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePlay}>
                  <ControlPlayIcon width={24} height={24} />
                </IconButton>
              :
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePause}>
                  <ControlPauseIcon width={24} height={24} />                  
                </IconButton>
            }
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleStop}>
              <ControlStopIcon width={24} height={24} />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleSound}>
              {!isMuted && <ControlSoundOnIcon width={24} height={24} />}
              {isMuted && <ControlSoundOffIcon width={24} height={24} />}
            </IconButton >
            {/* <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleFocus}>
              <ControlFocusIcon width={24} height={24} />
            </IconButton> */}
          </Box>
        </Box>
      </PlayerBody>
    </SectionPlayerContainer>
  );
};