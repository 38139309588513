import styled from "styled-components";

export const QuestionStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction : column;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position:relative;
  width:100%;
`

export const InputLabelStyle = styled.div`
  align-items:flex-start;
  display:flex;
  flex-direction:column;
  position: relative;  

`
export const LabelStyle = styled.div`
  width: fit-content;
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-primary"]};
`
export const DescriptionStyle = styled.p`
  align-self: stretch;
  ${(props)=>props.theme.typography["pre-body-06"]};
  color:${(props)=>props.theme.colors["text-tertiary"]};
  margin:0;

`

export const QuestionHeaderStyle= styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
`
export const SwitchWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-caption-02"]};
  color:${(props)=>props.theme.colors["text-secondary"]};
  display:flex;
  align-items: center;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
`