import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: none;
  height: 24px;
  width: 25px;
`;

const ClipPath = styled.clipPath`
  fill: white;
`;

interface Props {
  state: string;
}

export const EffectTabIcon5 = ({ state="default" }: Props): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_116_23328)">
        <g className="g" clipPath="url(#clip1_116_23328)">
          <path
            d="M8.44995 21C10.521 21 12.2 19.3211 12.2 17.25C12.2 15.1789 10.521 13.5 8.44995 13.5C6.37888 13.5 4.69995 15.1789 4.69995 17.25C4.69995 19.3211 6.37888 21 8.44995 21Z"
            fill={state=="default" ? "#A9B0B7": "#3617CE"}
            stroke={state=="default" ? "#A9B0B7": "#3617CE"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path d="M12.2 17.25V3.75L19.7 6V10.5L12.2 8.25" fill={state=="default" ? "#A9B0B7": "#3617CE"} />
          <path
            d="M12.2 17.25V3.75L19.7 6V10.5L12.2 8.25"
            stroke={state=="default" ? "#A9B0B7": "#3617CE"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
      <defs className="defs">
        <ClipPath id="clip0_116_23328">
          <path
            className="path"
            d="M0.199951 8C0.199951 3.58172 3.78167 0 8.19995 0H16.2C20.6182 0 24.2 3.58172 24.2 8V16C24.2 20.4183 20.6182 24 16.2 24H8.19995C3.78167 24 0.199951 20.4183 0.199951 16V8Z"
          />
        </ClipPath>
        <clipPath className="clip-path" id="clip1_116_23328">
          <rect className="rect" fill="white" height="24" transform="translate(0.199951)" width="24" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};