import React from "react";

interface EditorContextInterface{
  videoCreated:boolean,
  outputVideoUrl:string|undefined,
  outputPresignedUrl:string|undefined,
  outputVideoKey:string|undefined,
  outputVideoBucket: string|undefined,
  isEdited:boolean,
  screenEffectList:any[],
  sceneTextShadowEffectList:any[],
  ttsVolumeList:number[],
  bgmVolume:number,

  setVideoCreated:(created:boolean)=>void,
  setOutputVideoUrl:(newUrl:string|undefined)=>void,
  setOutputPresignedUrl:(newPresigned:string|undefined)=>void,
  setOutputVideoKey:(newKey:string|undefined)=>void,
  setOutputVideoBucket:(newBucket:string|undefined)=>void,
  setIsEdited:(newVale:boolean)=>void,
  setScreenEffectList:(newList)=>void,
  setSceneTextShadowEffectList:(newList)=>void,
  setTtsVolumeList:(newList)=>void,
  setBgmVolume:(newVal)=>void
}

const EditorContextDefault:EditorContextInterface={
  videoCreated:false,
  outputVideoUrl:undefined,
  outputPresignedUrl:undefined,
  outputVideoKey:undefined,
  outputVideoBucket:undefined,
  isEdited:false,
  screenEffectList:[],
  sceneTextShadowEffectList:[],
  ttsVolumeList:[],
  bgmVolume:100,

  setVideoCreated:()=>{},
  setOutputVideoUrl:()=>{},
  setOutputPresignedUrl:()=>{},
  setOutputVideoKey:()=>{},
  setOutputVideoBucket:()=>{},
  setIsEdited:()=>{},
  setScreenEffectList:()=>{},
  setSceneTextShadowEffectList:()=>{},
  setTtsVolumeList:()=>{},
  setBgmVolume:()=>{},
}

export const EditorContext = React.createContext(EditorContextDefault)