import React from "react";
import { ReactComponent as PlayIcon } from "../../../../Assets/PlayIcons/playButton1.svg";
import { ReactComponent as PauseIcon } from "../../../../Assets/PlayIcons/pauseButton1.svg";

import styled from "styled-components";

function PlayButton(props){
  
  return(
    <PlayButtonStyle {...props}>
      {props.isPlaying?<PauseIcon/>:<PlayIcon/>}
    </PlayButtonStyle>
  )
}

export default PlayButton

const PlayButtonStyle = styled.div`
  width: 56px;
  hieght: 56px;
  cursor:pointer;  
`