import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useContext, memo } from "react";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../editorPageContext";

interface ItemProps {
  text: string;
  duration: number;
  stepSize: number;
  onClick?:Function;
  selected?: boolean;
  clip?:any
}

const NarrationItem = ({ text="", duration=5, stepSize=40, onClick=undefined, selected=false, clip=undefined }: ItemProps): JSX.Element => {
  // const [txt, setTxt] = useState(text);

  // const {editor} = useContext(MasherContext)

  const [end, setEnd] = useState(duration * stepSize * 10);
  const [isSelected, setIsSelected] = useState(false);
  const editorPageContext = useContext(EditorPageContext);
  const handleClick = (e) => {
    // setIsSelected(prev=>!isSelected)
    editorPageContext.setSelectedItem({
      type:"ntr",
      data: text,
      // clip:clip
    });
    if(onClick){
      onClick();
    }
  }

  useEffect(()=>{
    setIsSelected(selected)
  },[selected])

  return (
    <Box display="flex" width={end} height={32} justifyContent="space-between" alignItems="center" pr={2} pl={2} py={0} position="relative" borderRadius={1} overflow="hidden" onClick={handleClick}>
      <Box position="absolute" width="100%" height="100%" top={0} left={0} bgcolor={isSelected? "var(--color-support-narrationbar-active, #6074FF)" : "var(--color-support-narrationbar, #96A3FB)"} />
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, left: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
        <Typography position="relative" flex={1} fontWeight="bold" color="white" fontSize="0.875rem" letterSpacing="0.1em" lineHeight="1.5" sx={{whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis"}}> {text} </Typography>
      </>
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, right: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
      </>
    </Box>
  )
}

interface Props {
  stepSize: number,
  onClickItem?: Function,
  clips?: any[]
}

export const NarrationTrack = ({ stepSize, onClickItem=undefined, clips=[] }: Props): JSX.Element => {

  const [narrations, setNarrations] = useState([]);
  const [trackData, setTrackData] = useState([]);

  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext)

  // const masherContext = useContext(MasherContext);
  // const {editor} = masherContext
  // const {selection} = editor

  const [ntrClips, setNtrClips] = useState<any[]>([]);

  const [sceneList, setSceneList] = useState([]);
  useEffect(()=>{
    const scenes = scenarioContext.finalScenario?.scenes;
    // 최종 시나리오의 장면 정보
    // scenes.no : 장면 번호
    // scenes.time : 장면 재생시간
    // scenes.title: 장면 제목
    // scenes.fileName : 장면에 해당하는 파일

    // console.log("scenes :: ", scenes)
    if(scenes){
      setSceneList(scenes);      
    } 
  },[scenarioContext.finalScenario?.scenes])

  // useEffect(()=>{  
  //   if(selection?.mash?.clips){
  //     // let clips = selection.mash.clips.filter((clip, index)=> (index!==0)&&((index%3)===0))
      
  //     // setNtrClips(clips)
  //   }
  // },[selection?.mash?.clips])

  useEffect(()=>{
    if(scenarioContext.finalScenario?.scenes){
      //todo : tracks 사용하도록 변경
      const ntrList = scenarioContext.finalScenario.scenes.map(scene=>({
        duration: scene.time,
        narration: scene.narrationInfo
      }));
      setNarrations(ntrList);
    }
  },[])

  const handleOnClickItem = (index)=>{
    if(onClickItem){
      if(editorPageContext.selectType==="ntr" && editorPageContext.selectIndex===index){
        onClickItem({type:undefined, index:undefined})
      }else{
        onClickItem({type:"narration", index:index})
      }
    }
  }

  return (
    // <Box sx={{ width: "100%", height: "48px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
    // </Box>
    <Stack gap={0} direction="row" sx={{ width: "100%", height: "32px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
      {
        sceneList.map((scene,index)=>{
          return(
            <NarrationItem selected={(editorPageContext.selectType==="ntr")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} key={`narration-text${index}`} text={scene.narrationInfo.text} duration={scene.time} stepSize={stepSize} />    
          )
        })
      }
      {
        // narrations.map((narration, index)=>(
        //   <NarrationItem selected={(editorPageContext.selectType==="ntr")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} key={`narration-text${index}`} text={narration.narration.text} duration={narration.duration} stepSize={stepSize} />    
        // ))
      }
      {/* {
        clips.map((clip, index)=>(
          <NarrationItem selected={(editorPageContext.selectType==="ntr")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} key={`narration-text${index}`} text={narrations[index].narration.text} duration={narrations[index].duration} stepSize={stepSize} />    
        ))
      } */}
      
      {/* <NarrationItem text="Narration Text" duration={5} stepSize={stepSize} />
      <NarrationItem text="우리동네 최고 맛집을 소개합니다" duration={7} stepSize={stepSize} /> */}
    </Stack>
  )
}

export const MemoizedNarrationTrack = memo(NarrationTrack)