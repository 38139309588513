import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: none;
  height: 24px;
  width: 25px;
`;

interface Props {
  state: string;
}

export const EffectTabIcon2 = ({ state="default" }: Props): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_116_8725)">
        <g className="g" clipPath="url(#clip1_116_8725)">
          <path
            className="path"
            d="M19.9 3.75H4.89996C4.48575 3.75 4.14996 4.08579 4.14996 4.5V19.5C4.14996 19.9142 4.48575 20.25 4.89996 20.25H19.9C20.3142 20.25 20.65 19.9142 20.65 19.5V4.5C20.65 4.08579 20.3142 3.75 19.9 3.75Z"
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M9.39996 10.5C10.2284 10.5 10.9 9.82843 10.9 9C10.9 8.17157 10.2284 7.5 9.39996 7.5C8.57154 7.5 7.89996 8.17157 7.89996 9C7.89996 9.82843 8.57154 10.5 9.39996 10.5Z"
            fill={state=="selected"? "#3617CE" : "#a9b0b7"}
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            className="path"
            d="M5.71466 20.25L15.9943 9.96935C16.064 9.89962 16.1467 9.8443 16.2378 9.80656C16.3288 9.76882 16.4264 9.74939 16.525 9.74939C16.6235 9.74939 16.7211 9.76882 16.8122 9.80656C16.9032 9.8443 16.9859 9.89962 17.0556 9.96935L20.65 13.5647"
            fill={state=="selected"? "#3617CE" : "#a9b0b7"}
          />
          <path
            className="path"
            d="M5.71466 20.25L15.9943 9.96935C16.064 9.89962 16.1467 9.8443 16.2378 9.80656C16.3288 9.76882 16.4264 9.74939 16.525 9.74939C16.6235 9.74939 16.7211 9.76882 16.8122 9.80656C16.9032 9.8443 16.9859 9.89962 17.0556 9.96935L20.65 13.5647V19.0002L19.4002 20.25H5.71466Z"
            fill={state=="selected"? "#3617CE" : "#a9b0b7"}
          />
          <path
            className="path"
            d="M5.71466 20.25L15.9943 9.96935C16.064 9.89962 16.1467 9.8443 16.2378 9.80656C16.3288 9.76882 16.4264 9.74939 16.525 9.74939C16.6235 9.74939 16.7211 9.76882 16.8122 9.80656C16.9032 9.8443 16.9859 9.89962 17.0556 9.96935L20.65 13.5647V19.0002L19.4002 20.25H5.71466Z"
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_116_8725">
          <path
            className="path"
            d="M0.399963 8C0.399963 3.58172 3.98169 0 8.39996 0H16.4C20.8182 0 24.4 3.58172 24.4 8V16C24.4 20.4183 20.8182 24 16.4 24H8.39996C3.98168 24 0.399963 20.4183 0.399963 16V8Z"
            fill="white"
          />
        </clipPath>
        <clipPath className="clip-path" id="clip1_116_8725">
          <rect className="rect" fill="white" height="24" transform="translate(0.399963)" width="24" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};
