import styled from "styled-components";


export const IconDivStyle = styled.div`
  display:flex;
  align-items:center;
  height:${(props)=>props.style.height}px;
  width:${(props)=>props.style.width}px;

  &svg{
    height:100%;
    background-color:red;
  }
`