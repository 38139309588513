import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as TimeTickIcon } from "../../../../../Assets/icon-editor/timeline-tick.svg";
import barIcon from "../../../../../Assets/icon-editor/timeline-bar-black.png";

interface Props {
  stepSize: number;
  stepCount: number;
  maxTime: number;
  currentTime: number; // 밀리세컨드
}
// 전체 길이 1796px
// 1796/15 = 119.73
// 1796/30 = 59.86

export const TimelineBar = ({ stepSize, stepCount, maxTime,  currentTime = 0 }: Props): JSX.Element => {
  
  const [posX, setPosX] = useState(0);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const tickSize = stepSize * (10/stepCount)

  useEffect(()=> {
    const tickCount = (currentTime*1000 / 100);
    setPosX(stepSize * tickCount);
  }, [currentTime]);

  function formatTime(ms) {
    // 밀리초를 초로 변환
    const totalSeconds = Math.floor(ms / 1000);
    
    // 분과 초 계산
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    
    // 초가 한 자리일 경우 앞에 0을 추가
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    
    //return `${minutes}:${formattedSeconds}`;
    return `${formattedSeconds}`; // 초만 표시하도록 변경
  }

  const handleMouseDown = (e) => {
    console.log("mouse down")
    setIsDragging(true);
    setStartX(e.clientX - posX);
  };

  const handleMouseMove = (e) => {
    
    if (isDragging) {
      console.log("mouse move")
      let newPosX = e.clientX - startX;
      if (newPosX < 0) newPosX = 0;
      requestAnimationFrame(() => setPosX(newPosX));
    }
  };

  const handleMouseUp = () => {
    console.log("mouse up")
    setIsDragging(false);
  };


  return (
    <Box sx={{
        display: "inline-flex",
        padding: "4px 0px 2px 120px",
        width: "100%",
        height: "24px",
        overflow: "auto",
        borderBottom: "1px solid var(--color-border-secondary, #E2E4E7)",  
        // backgroundColor: "red"
      }}

      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // 드래그 중 마우스가 영역을 벗어났을 때 멈추도록 처리
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent:"center", position: "absolute", paddingTop: "4px"}}>
        <Box component="img" src={barIcon} alt="Bar" left={posX-4.5} draggable="false" sx={{ position: 'absolute', width: 9, height: 240, top: 0, cursor: "pointer", zIndex: 9999 }} 
          onMouseDown={handleMouseDown}
          // onMouseUp={handleMouseUp}
        />
      
        {Array.from(Array(maxTime)).map((_, index) => (
          <>
            <Box key={index} width={tickSize} sx={{ display: "flex", height:"18px", alignItems: "center", justifyContent: "left", paddingLeft: index===0?"-5px": 0}} >
              <Typography sx={{ fontWeight: "400", fontSize: "10px", color: "var(--color-text-tertiary, #717D87)", letterSpacing: "-0.1px", lineHeight: "170%" }}>
                {formatTime(index*1000)}
              </Typography>
            </Box>
            
            {Array(stepCount-1)
              .fill(0)
              .map((_, tickIndex) => (
                <Box
                  key={`${index}-${tickIndex}`}
                  width={tickSize}
                  height={18}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <TimeTickIcon />
                </Box>
              ))}
              
              {/* <Box key={`${index}-1`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-2`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-3`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-4`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>          */}
          </>
        ))}
        <Box key={maxTime} width={stepSize} sx={{ display: "flex", height:"18px", alignItems: "center", justifyContent: "center", marginLeft:"-6px"}} >
        <Typography sx={{ fontFamily: "pre-caption-04", fontWeight: "400", fontSize: "10px", color: "var(--color-text-tertiary, #717D87)", letterSpacing: "-0.1px", lineHeight: "170%" }}>
          {formatTime(maxTime*1000)}
        </Typography>
      </Box>
      </Box>
    </Box>
  )
}