import styled from "styled-components";


export const CallFrameStyle = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  
  width:100%;
  position:relative;
`
export const CallInputsStyle = styled.div`
  display: flex;
  align-items:center;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  
  
  width:100%;
  box-sizing: border-box;

  position:relative;
`
export const FileListStyle = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  gap: ${(props)=>props.theme.spacing["spacing-04"]} ${(props)=>props.theme.spacing["spacing-04"]};
  flex-wrap:wrap;
`

export const ModalFrameStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display:flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-06"]};
  position: relative;
  width: 100%;
`
export const TextStyle = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  position: relative;
  width: 100%;
`
export const ModalTextWrapperStyle = styled.div`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-heading-04"]};
  margin-top: -1px;
  position: relative;
  text-align: center;
`
export const ModalPStyle = styled.p`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-body-04"]};
  position: relative;
  text-align: center;
  margin: 0px;
`
export const CallLabelWrapperStyle = styled.div`
  height:100%;
  width:80px;
  display:flex;
  align-items:center;
`