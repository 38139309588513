import React from 'react';

import { ModalDimStyle, ModalStyle } from './Modal.styled';


interface ModalProps extends React.ComponentProps<any>{
  show?:boolean,
  handleClose?:(props:any)=>void,
  handleConfirm?:(props:any)=>void,
  title?:string,
  text?:string|React.ReactNode,
  closeOnClickOutside?:boolean,
  width?:number|string,
}

function Modal(props:ModalProps){
  return (
    <ModalDimStyle
      className={`${props.show ? ' show' : ''}`}
      onClick={props.closeOnClickOutside?props.handleClose:undefined}
    >
      <ModalStyle
        onClick={(e)=>{e.stopPropagation()}}
      >
        {props.children}
      </ModalStyle>
    </ModalDimStyle>
  );
};

export default Modal;