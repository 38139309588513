import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { ShopPointContext } from "../../../../Contexts/ShopPointContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { WebSave } from "../../../../Api/resource";

interface Props {
  className: any;
}

const StyledHeaderSub = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--tokens-color-border-secondary-duplicate);
  display: flex;
  gap: 16px;
  height: 56px;
  padding: var(--tokens-spacing-06-duplicate) var(--tokens-spacing-07-duplicate) var(--tokens-spacing-06-duplicate)
    var(--tokens-spacing-07-duplicate);
  position: relative;
  width: 1440px;

  .frame-17 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }

  .frame-18 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  .button-box {
    align-items: center;
    background-color: var(--tokens-color-button-brand2-subtle1-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 28px;
    justify-content: center;
    padding: 0px var(--tokens-spacing-04-duplicate) 0px var(--tokens-spacing-04-duplicate);
    position: relative;
    cursor: pointer;
  }

  .button-3 {
    all: unset;
    box-sizing: border-box;
    color: var(--tokens-color-text-brand1-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .text-wrapper-10 {
    color: var(--tokens-color-text-brand2-duplicate);
    font-family: var(--pre-caption-02-font-family);
    font-size: var(--pre-caption-02-font-size);
    font-style: var(--pre-caption-02-font-style);
    font-weight: var(--pre-caption-02-font-weight);
    letter-spacing: var(--pre-caption-02-letter-spacing);
    line-height: var(--pre-caption-02-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;

export const HeaderSub = (props): JSX.Element => {

  const [lastSaveAt, setLastSaveAt] = useState(undefined)
  const [saveFailed, setSaveFailed] = useState(false)

  const handleClickSave = async () => {
    const now = new Date();

    const hh = ("00"+(now.getHours())).slice(-2);
    const mm = ("00"+(now.getMinutes())).slice(-2);
    const ss = ("00"+(now.getSeconds())).slice(-2);

    const timeString = `${hh}:${mm}:${ss}`

    const saveResult = await saveData();
    if(saveResult.result===0){
      setSaveFailed(false)
      setLastSaveAt(timeString);
    }else{
      setSaveFailed(true)
    }
  }

  const shopInfoContext = useContext(ShopInfoContext)
  const shopPointContext = useContext(ShopPointContext)
  const scenarioContext = useContext(ScenarioContext)
  const editorContext = useContext(EditorContext)

  async function saveData(){    
    const filesData=[]
    for(let i=0; i<shopInfoContext.files.length; i++){
      const fileInfo = shopInfoContext.files[i]
      filesData.push({
        s3Key: fileInfo.s3Key,
        mainCategory: fileInfo.mainCategory,
        subCategory: fileInfo.subCategory,
        editInfo: fileInfo.editInfo,
      })
    }

    const editedFilesData=[];
    for(let i=0; i<shopInfoContext.editedFiles.length; i++){
      const editedFileInfo = shopInfoContext.editedFiles[i]
      editedFilesData.push({
        s3Key: editedFileInfo.s3Key,
        mainCategory: editedFileInfo.mainCategory,
        subCategory: editedFileInfo.subCategory,
        editInfo: editedFileInfo.editInfo,
      })
    }

    const infoData = {
      completedStep: shopInfoContext.completedStep,
      shopName: shopInfoContext.shopName,
      shopAddress: shopInfoContext.shopAddress,
      shopCall: shopInfoContext.shopCall,
      shopCategory: shopInfoContext.shopCategory,
      adTime: shopInfoContext.adTime,
      files: filesData,
      editedFiles: editedFilesData,
      aiImages: shopInfoContext.aiImages,
      includeAiImage: shopInfoContext.includeAiImage,
      isGenerating: shopInfoContext.isGenerating,
      imageAnalysisInfo: shopInfoContext.imageAnalysisInfo,
    }
    const pointData = {
      prosList: shopPointContext.prosList,
      targetList: shopPointContext.targetList,
      customRequirement: shopPointContext.customRequirement,
      requiredTextInfo: shopPointContext.requiredTextInfo,
      customFeatureList: shopPointContext.customFeatureList,
      customCustomerList: shopPointContext.customCustomerList,
      customMoodList: shopPointContext.customMoodList,
      fontFeature: shopPointContext.fontFeature,
      bgmFeature: shopPointContext.bgmFeature,
      narrationCharacterFeature: shopPointContext.narrationCharacterFeature,
    }
    const scenarioData = {
      finalScenario: scenarioContext.finalScenario,
      scenario: scenarioContext.scenario,
      scene: scenarioContext.scene,
      selectedScenario: scenarioContext.selectedScenario,
      selectedTemplate: scenarioContext.selectedTemplate,
      useLastEnding: scenarioContext.useLastEnding,
      generatedImages: scenarioContext.generatedImages,
      generatedVideos: scenarioContext.generatedVideos,
      // useTemplate: scenarioContext.useTemplate,
      BGMInfo: scenarioContext.BGMInfo,
      selectedBgmIndex: scenarioContext.selectedBgmIndex,
      selectedTTSActorInfo: scenarioContext.selectedTTSActorInfo,
      editedTTSActorList: scenarioContext.editedTTSActorList,
    }
    const editorData = {
      videoCreated: editorContext.videoCreated,
      outputVideoUrl: editorContext.outputVideoUrl,
      outputPresignedUrl: editorContext.outputPresignedUrl,
      outputVideoBucket: editorContext.outputVideoBucket,
      outputVideoKey: editorContext.outputVideoKey,
      isEdited: editorContext.isEdited,
      screenEffectList: editorContext.screenEffectList,
      sceneTextShadowEffectList: editorContext.sceneTextShadowEffectList,
      ttsVolumeList: editorContext.ttsVolumeList,
      bgmVolume: editorContext.bgmVolume,
    }
    console.log("editor Data save :: ", editorData)
    const data={
      infoData: infoData,
      pointData: pointData,
      scenarioData: scenarioData,
      editorData: editorData
    }

    const assetId = sessionStorage.getItem("ASSET_ID");
    // sessionStorage.setItem(`${assetId}_SAVE`, JSON.stringify(data));

    console.log("save data :: ", JSON.stringify(data));

    // return({result:0, errMsg:""})
    
    try{
      const saveRequest={
        assetId: assetId,
        finalInfo: JSON.stringify(data)
      }
      const saveResponse = await WebSave(saveRequest);      
      if(saveResponse.result===0){
        return {result:0, errMsg:""}
      }else{
        const errorMessage = saveResponse.errMsg;
        return {result:-1, errMsg:`failed with result ${saveResponse.result} :: ${errorMessage}`}
      }
    }catch(err){
      console.error("failed to save data", err)
      return {result:-1, errMsg:`failed to save data :: ${err}`}
    }
  }


  return (
    <StyledHeaderSub>
      <div className="frame-17">
        <div className="frame-18">
          <div className="button-box" onClick={handleClickSave}>
            <button className="button-3">편집 저장하기</button>
          </div>
          {/* <div className="button-box" onClick={()=>console.log("편집 취소")}>
            <button className="button-3">편집 취소</button>
          </div> */}
          {/* <div className="button-box" onClick={()=>console.log("삭제")}>
            <button className="button-3">삭제</button>
          </div> */}
        </div>
        {
          lastSaveAt && 
          <div className="frame-18">
            <div className="text-wrapper-10">{saveFailed?"저장 실패":"마지막 저장"}</div>
            <div className="text-wrapper-10">{lastSaveAt}</div>
          </div>
        }
      </div>
    </StyledHeaderSub>
  );
};