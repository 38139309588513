import React, { ReactNode, useEffect, useState } from "react";

import Icon, {IconTypes} from "../../Icon";

import theme from "../../../styles/theme";

import { ButtonStyle, variantEnum } from "./Button.styled";


/**
 * @typedef {Object} ButtonProps
 * @property {keyof typeof variantEnum} $variant - Defines the color variant of the button, determining its background, border, and text colors.
 * @property {keyof typeof typeEnum} $buttonType - Defines the shape and size of the button, determining its height, padding, border-radius, and other styling properties.
 */

/**
 * Button component
 * @param {ButtonProps} props
 * @returns {JSX.Element}
 */

interface ButtonProps extends React.ComponentProps<any>{
  $buttonType?:string,
  $variant?:string,
  showLeftIcon?:boolean,
  leftIcon?:ReactNode|string,
  text?:ReactNode|string,
  showRightIcon?:boolean
  rightIcon?:ReactNode|string,
}

const Button = (props) => {
  const [iconColor, setIconColor] = useState("icon-brand2");
  useEffect(()=>{
    switch (props.$variant) {
      case variantEnum["brand1"]:
        setIconColor("icon-primary-inverse")
        break;
      case variantEnum["brand1-subtle"]:
        setIconColor("icon-brand1");
        break;
      case variantEnum["brand2"]:
        setIconColor("icon-primary-inverse")
        break;
      case variantEnum["brand2-subtle1"]:      
      case variantEnum["beige-subtle"]:      
        setIconColor("icon-brand2");
        break;
      default:      
        setIconColor("icon-brand2")
        break;       
    }
  },[props.$variant])
  

  return (
    <ButtonStyle {...props}>
      {props.showLeftIcon&&
        <Icon color={iconColor} height={"16px"} icon={props.leftIcon as IconTypes}/>
      }
      {props.text}
      {props.showRightIcon&&
        <Icon color={iconColor} height={"16px"} icon={props.rightIcon as IconTypes}/>
      }
    </ButtonStyle>
  )
};

export default Button;