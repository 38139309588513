import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Popover, TextField, Switch, Button, Paper, Divider, Select, MenuItem, ToggleButtonGroup, toggleButtonGroupClasses, ToggleButton } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormatAlignLeft, FormatAlignRight, FormatAlignCenter, FormatAlignJustify, FormatBold, FormatItalic, FormatUnderlined }  from '@mui/icons-material'

import { ColorPicker } from '../../../../../../../Components/pages/Editor/ColorPicker';

import styled from "styled-components";

import fontDataJson from "../../../../../../../Data/fontData.json"
import { EditorPageContext } from "../../../../../editorPageContext";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";


const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: 0,
    border: 0,
    borderRadius: `theme.shape.borderRadius`,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));


const TextAlignToggleButtons = ({value='left', onChange}): JSX.Element => {
  const [alignment, setAlignment] = React.useState<string | null>(value);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setAlignment(newAlignment);
    onChange(newAlignment);
  };

  return (
    <StyledToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="small"
    >
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenter fontSize="small" color={alignment==="center"? "primary": "secondary"} />
        {/* <ReflectX width="16" height="16" color={alignment==="left"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeft fontSize="small" color={alignment==="left"? "primary": "secondary"}/>
        {/* <ReflectY width="16" height="16" color={alignment==="center"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRight fontSize="small" color={alignment==="right"? "primary": "secondary"}/>
        {/* <RotateLeft width="16" height="16" color={alignment==="right"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified">
        <FormatAlignJustify fontSize="small" color={alignment==="justify"? "primary": "secondary"}/>
        {/* <RotateRight width="16" height="16" color={alignment==="justify"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

const TextStyleToggleButtons = ({value='', onChange}): JSX.Element => {
  const [textStyle, setTextStyle] = React.useState<string | null>(value);

  const handleTextStyle= (
    event: React.MouseEvent<HTMLElement>,
    newStyle: string | null,
  ) => {
    setTextStyle(newStyle);
    onChange(newStyle);
  };
  
  return (
    <StyledToggleButtonGroup
      value={textStyle}
      exclusive
      onChange={handleTextStyle}
      aria-label="text style"
      size="small"
    >
      <ToggleButton value="bold" aria-label="bold">
        <FormatBold fontSize="small" color={textStyle==="bold"? "primary": "secondary"} />
      </ToggleButton>
      <ToggleButton value="italic" aria-label="italic">
        <FormatItalic fontSize="small" color={textStyle==="italic"? "primary": "secondary"}/>
      </ToggleButton>
      <ToggleButton value="underline" aria-label="underline">
        <FormatUnderlined fontSize="small" color={textStyle==="underline"? "primary": "secondary"}/>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export const TextTabContent = ({index}): JSX.Element => {
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [textStyle, setTextStyle] = useState("");
  const [textAlign, setTextAlign] = useState('center');

  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);

  const [scene, setScene] = useState(undefined)

  const [selectedFont, setSelectedFont] = useState("에스코어드림5")
  const [selectedFontSize, setSelectedFontSize] = useState("100");
  const [color, setColor] = useState("#ffffff");

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const idx = editorPageContext.selectIndex;
      const targetScene = scenarioContext.finalScenario.scenes[idx];
      // console.log("target Scene :: ", targetScene)
      setScene(targetScene);
      setSelectedFont(targetScene.textList[0].font)
      setSelectedFontSize(targetScene.textList[0].height.toString())
      setColor(targetScene.textList[0].fontColor)
    }
  },[editorPageContext.selectIndex,scenarioContext.finalScenario])

  const handleChangeX = (event: Event, newValue: number | number[]) => {
    setSizeX(newValue as number);
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    setSizeY(newValue as number);
  };

  useEffect(()=> {
    // console.log(textAlign);
  }, [textAlign]);


  const hideItem = true

  const handleChangeText = (event:any) => {
    let newSceneData = JSON.parse(JSON.stringify(scene));
    newSceneData.textList[0].text = event.currentTarget.value
    setScene(newSceneData);
  }

  const handleChangeFont = (event:any)=>{
    setSelectedFont(event.target.value);
    let newSceneData = JSON.parse(JSON.stringify(scene));
    newSceneData.textList[0].font = event.target.value
    setScene(newSceneData);
  }

  const handleChangeFontSize = (event:any)=>{
    setSelectedFontSize(event.target.value);
    let newSceneData = JSON.parse(JSON.stringify(scene));
    newSceneData.textList[0].height = event.target.value
    setScene(newSceneData);
  }

  const handleChangeColor = (value:string) => {
    setColor(value);
    let newSceneData = JSON.parse(JSON.stringify(scene));
    newSceneData.textList[0].fontColor = value
    setScene(newSceneData);
  };

  useEffect(()=>{
    if(scene){
      const index = editorPageContext.selectIndex;
      let finalScenario = scenarioContext.finalScenario;
      finalScenario.scenes[index]=scene;
      scenarioContext.setFinalScenario(finalScenario);
    }
  },[scene])

  return (
    <StyledFrame>
      {/* <Box display="flex" alignItems="center" justifyContent="end" gap={2} width="100%">
        <Typography variant="caption" color="secondary">AI 텍스트 수정 적용</Typography>
        <Switch color="primary" size="small" />
      </Box> */}
      {/* <TextField variant="outlined" fullWidth multiline rows={4} placeholder="Text" sx={{ borderColor: "#E2E4E7" }} value={editorPageContext.selectedItem?.type==="text"? editorPageContext.selectedItem?.data : undefined}/> */}
      <TextField variant="outlined" fullWidth multiline rows={4} placeholder="Text" sx={{ borderColor: "#E2E4E7" }} value={scene?.textList[0].text} onChange={handleChangeText}/>
      <Divider sx={{ width: "100%" }} />
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>서체</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft:0, paddingRight:0, gap: 1 }} >
          <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <Select
              value={selectedFont}
              defaultValue="에스코어드림5"
              sx={{ flex: 1, height: "32px",  borderColor: "#E2E4E7" }}
              onChange={handleChangeFont}
              size="small"
              IconComponent={ArrowDropDownIcon}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {fontDataJson.map(font=>(<MenuItem value={font.fontName}>{font.fontName}</MenuItem>))}
              {/* <MenuItem value={"Noto Sans KR"}>Noto Sans KR</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
            <Select
              value={selectedFontSize}
              defaultValue={"85"}
              sx={{ flex: 1, height: "32px" }}
              onChange={handleChangeFontSize}
              size="small"
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Array.from({ length: 151 }, (_, i) => (
                <MenuItem key={i} value={50 + i}>
                  {50 + i}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <ColorPicker value={color} onChange={handleChangeColor}/>
          {
            !hideItem &&
            <Box pt={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
              <TextAlignToggleButtons value={textAlign} onChange={(val:string)=>setTextAlign(val)} />
              <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
              <TextStyleToggleButtons value={textStyle} onChange={(val:string)=>setTextStyle(val)} />
              {/* <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                <IconButton>
                </IconButton>  
              </Box> */}
            </Box>    
          }
        </AccordionDetails>
      </Accordion>
    </StyledFrame>
  );
};