import React, { useEffect, useState } from "react";
import { Box, IconButton, InputBase, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PropTypes from "prop-types";

interface Props {
  width: number;
  unitText: string;
  min: number;
  max: number;
  value: number;
  onChange: any;
}

export const InputStepper = ({
  width = 140, 
  unitText = "",
  min = null,
  max = null,
  value = 0,
  onChange
}: Props): JSX.Element => {

  const [myValue, setMyValue] = useState(value);

  const handleIncrease = () => {
    if( value == max) return;
    setMyValue(prevValue => (prevValue + 1));
    onChange(myValue);
  };

  const handleDecrease = () => {
    setMyValue(prevValue => (prevValue > min ? prevValue - 1 : min));
    onChange(myValue); 
  };

  const handleChange = (event: { target: { value: any; }; }) => {
    const newValue = Number(event.target.value);
    if (!isNaN(newValue)) {
      if (newValue < min || newValue > 100) {
        return;
      } 
      setMyValue(newValue);
      onChange(newValue);
    }
  };
  
  // useEffect(() => {
  //   onChange(myValue);
  // }, [myValue]);

  useEffect(() => {
    setMyValue(value);
  }, [value]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0}
      pr={0}
      pl={0}
      py={0}
      pt={0}
      pb={0}
      bgcolor="#ffffff"
      borderRadius={1}
      border={1}
      borderColor="divider"
      height={32}
      width={width}
    >
      <InputBase value={myValue} onChange={handleChange} sx={{ width: 40 , ml: 1, flex: 1}} inputProps={{style: {textAlign:'right'}}} />
      <Typography variant="body2" sx={{ width: 45, color: 'black', paddingLeft: 0.5 }}>{unitText}</Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap={0} pl={0} pr={1}>
        <IconButton size="small" sx={{width: 16, height: 15}} onClick={handleIncrease}>
          <ArrowDropUpIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" sx={{width: 16, height: 15}} onClick={handleDecrease}>
          <ArrowDropDownIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

InputStepper.propTypes = {
  width: PropTypes.number,
  unitText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number
};

