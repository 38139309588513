import React from "react";
import styled from "styled-components";
import { MusicTabContent } from "./sections/MusicTabContent"; 

const StyledTabContainerNode = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-07-duplicate);
  height: 536px;
  overflow-y: scroll;
  padding: var(--tokens-spacing-08-duplicate) 0px var(--tokens-spacing-08-duplicate) 0px;
  position: relative;
  width: 100%;
`;

export const MusicTabContainer = (): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <MusicTabContent />
    </StyledTabContainerNode>
  );
};