import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

interface Props {
  index: number;
  property1: "off" | "on";
  className: any;
  text: string;
  onClick: any;
}

const StyledTab = styled.div<{ property1: "off" | "on"; className: any }>`
  align-items: center;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  padding: var(--tokens-spacing-01-duplicate) var(--tokens-spacing-03-duplicate) var(--tokens-spacing-02-duplicate)
    var(--tokens-spacing-03-duplicate);
  position: relative;
  cursor: pointer;

  & .text-wrapper {
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  &.on {
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-color: var(--tokens-color-border-brand-duplicate);
  }

  &.off .text-wrapper {
    color: var(--tokens-color-text-quaternary-duplicate);
    margin-top: -1px;
  }

  &.on .text-wrapper {
    color: var(--tokens-color-text-brand1-duplicate);
    margin-top: -1.5px;
  }
`;

export const Tab = ({ index, property1, className, text = "광고 정보", onClick }: Props): JSX.Element => {
  return (
    <StyledTab property1={property1} className={`tab ${property1} ${className}`} onClick={()=> onClick(index)}>
      <div className="text-wrapper">{text}</div>
    </StyledTab>
  );
};

Tab.propTypes = {
  property1: PropTypes.oneOf(["off", "on"]),
  text: PropTypes.string,
};