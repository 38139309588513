import React from "react";
import styled from "styled-components";

interface Props {
  className: any;
}

const StyledSvg = styled.svg`
  &.list-btn-icon-10 {
    fill: none;
    height: 25px;
    width: 24px;
  }

  .path {
    stroke: #43465e;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
`;

export const ListBtnIcon10 = ({ className }: Props): JSX.Element => {
  return (
    <StyledSvg
      className={`list-btn-icon-10 ${className}`}
      viewBox="0 0 24 25"
    >
      <path
        className="path"
        d="M6 12.5L18 12.5"
      />
      <path
        className="path"
        d="M12 6.5L12 18.5"
      />
    </StyledSvg>
  );
};