import React, {MouseEventHandler, ReactNode, Ref, useContext, useEffect,useRef,useState} from "react";
import Modal from "../../../common/Modal";
import './VideoTrimmer.css'
import styled from "styled-components";

import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import Button from "../../../common/Button";

import Toast from "../../../common/Toast";
import VideoTimeline from "../VideoTimeline";
import PlayButton from "../../EditVideo/PlayButton";
import { WebCutVideo } from "../../../../Api/resource";
import LoadingScreen from "../../../common/LoadingScreen";

import Lottie from 'lottie-react';
import spinnerAnimation from "../../../../animation/spinner.json"

import AlertModal from '../../../common/AlertModal'
import { ReactComponent as QuestionIcon } from '../../../../Assets/ModalIllu/Question.svg'

import { isLogging } from "../../../../App";

function VideoTrimmer(props){

  const shopInfoContext = useContext(ShopInfoContext);
  const {files, editedFiles, changeEditedFile} = shopInfoContext

  const [loopSegment, setLoopSegment] = useState<[number, number]>(props.loopSegment)
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

  const [isCutting, setIsCutting] = useState<boolean>(false);

  const [showAlert, setShowAlert]=useState(false);
  const closeAlert=()=>{setShowAlert(false)}
  const alertMessage = "동영상 자르기에 실패했습니다."
  const alertIcon = <QuestionIcon/>

  useEffect(()=>{
    console.log("loop : ", loopSegment)
    
  },[])
  useEffect(()=>{
    console.log("loop changed : ", loopSegment)
  },[loopSegment])

  ////////////////////// 타임스탬프 트리밍 ////////////////////////////////////

  // function loopEventFunc(){
  //   const video = videoRef.current;
  //   if(video){
  //     if(video.currentTime>loopSegment[1]){
  //       console.log(`Edit trim ${props.file.name} reached segment end :: ${loopSegment[1]}`)
  //       video.currentTime = loopSegment[0];
  //     }else if(video.currentTimes<loopSegment[0]){
  //       console.log(`Edit trim ${props.file.name} less than segment start :: ${loopSegment[0]}}`)
  //       video.currentTime = loopSegment[0]
  //     }
  //     setCurrentPlayTime(video.currentTime);
  //   }
  // }

  // const [currentPlayTime, setCurrentPlayTime] = useState();
  // const scrubberRef = useRef(null);
  // useEffect(()=>{
  //   const scrubber = scrubberRef.current;
  //   const timeline = timeLineRef.current;
  //   const video = videoRef.current;
  //   if(scrubber && timeline && video){
  //     const width = (timeline as HTMLElement).getBoundingClientRect().width - 32;
  //     const xpos = (width)*( (currentPlayTime||0)/(video as HTMLVideoElement).duration );
  //     (scrubber as HTMLElement).style.transform = `translate(${xpos}px,0px)`;
  //   }
  // },[currentPlayTime])

  // function addLoop(){
  //   removeLoop();
  //   if(videoRef.current){
  //     console.log(`add loop ${loopSegment[0]}~${loopSegment[1]}`)
  //     const video = videoRef.current;      
  //     (video as HTMLVideoElement).addEventListener("timeupdate", loopEventFunc);
  //   }
  // }

  // function removeLoop(){
  //   if(videoRef.current){
  //     console.log(`remove loop`)
  //     const video = videoRef.current;      
  //     (video as HTMLVideoElement).removeEventListener("timeupdate", loopEventFunc,);
  //   }
  // }

  function isInit(){
    const start = parseFloat(loopSegment[0].toString());
    const end = parseFloat(loopSegment[1].toString());
    if(previewVideoRef.current){
      const initStart = start===0;
      const initEnd = end === previewVideoRef.current.duration;
      return initStart && initEnd;
    }else{
      return false;
    }
  }

  function initTimeStamp(){
    // removeLoop();
    // if(previewVideoRef.current){
    //   const duration = previewVideoRef.current.duration;
    //   setVideoDuration(duration);
    //   console.log("duration : ", duration);
    //   console.log(`initialize timestamp : ${0}~${duration}`)
    //   setLoopSegment(prev=>[0,duration])
    //   thumbs.length = 0;
    //   setIsTrimed(false);
    //   setIsEditable(true);
    // }

    console.log("revert edited file to original")
    const {files, editedFiles} = shopInfoContext;
    const editedIdx= shopInfoContext.editedFiles.findIndex(target=>target.file.name===props.fileInfo.file.name);
    shopInfoContext.changeEditedFile(editedFiles[editedIdx], files[editedIdx]);
    
    // genThumbnails(0, 49);
    //setVideoDuration[minDuration];
    //setLoopSegment([0,minDuration]);
    
  }

  const handleTimeStampReset = () => {
    initTimeStamp();
  }

  
  function checkTimeStamp(){
    const checked = (loopSegment[0]<loopSegment[1]) && ( isInit() || (loopSegment[1]-loopSegment[0]) <= 5)
    console.log(`timestamp : ${loopSegment[0]}~${loopSegment[1]} :: ${checked}`)
    return checked
  }


  const confirmTrim = () => {
    if(checkTimeStamp()){
      // ffmpegTrim();
      props.setSegment([loopSegment[0], loopSegment[1]]);
      console.log(`confirm trim ${loopSegment[0]}~${loopSegment[1]}`)
      props.handleClose();
    }else{
      alert("invalid timestamp")
    }
  }

  useEffect(()=>{
    const element = previewVideoRef.current as HTMLVideoElement
    if(props.show && element){
      try{
        element.currentTime = loopSegment[0];
        element.play();
        console.log("show and play");
      }catch(err){
        console.error(err)
      }
      //if(thumbs.length===0)
        //genThumbnails(loopSegment[0],loopSegment[1]);
    } else {
      element.pause();
    }
  },[props.show])

  useEffect(()=>{   
    console.log("change loopSegment in VideoTrimmer");
    console.log(`${props.loopSegment[0]} end : ${props.loopSegment[1]}`);
    setLoopSegment(props.loopSegment);
    //setVideoDuration(loopSegment[1]-loopSegment[0])
    if(props.show) {
      //genThumbnails(props.loopSegment[0], props.loopSegment[1]);
      setLoopSegment([0,minDuration]);
    } else {
      //genThumbnails(loopSegment[0], loopSegment[1]);
    }
  },[props.loopSegment])

  const [thumbs, setThumbs] = useState<any[]>(props.fullThumbnails);
  const [isCapturing, setIsCapturing] = useState(false);

  const thumbRef = useRef(null);

  useEffect(()=> {
    setThumbs(props.fullThumbnails);
  }, [props.fullThumbnails]);

  async function genThumbnails(start, end){
    setIsCapturing((isCapturing)=>true)
    const tempVideoEl = thumbRef.current;   
    if (!tempVideoEl) {
      console.log("video failed")
      setIsCapturing((isCapturing)=>false)
      return;
    };

    console.log("genThumbnail: ", start, end);
    if(end <= start) {
      setIsCapturing((isCapturing)=>false)
      return;  
    }

    const captureFrames = async (targetVideo:HTMLVideoElement) => {
      console.log(`function captureFrames`)
      targetVideo.currentTime = 0;
      const thumbList = [];
      const canvas = document.createElement('canvas');
      const aspectRatio = targetVideo.videoHeight/targetVideo.videoWidth;
      
      canvas.width = 180;
      canvas.height = 180*aspectRatio;
      const context = canvas.getContext('2d');
      if(!context){
        throw Error("context failed");
      }

      const interval = (end-start)/7

      for(let i=start; i < end; i+=interval){
        targetVideo.currentTime = i;
        await new Promise((resolve) => {
          targetVideo.onseeked = resolve;
        });
        console.log(`thumb at ${targetVideo.currentTime}`)
        context.drawImage(targetVideo, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL('image/png');
        thumbList.push(imageDataUrl);

      }
      setThumbs(thumbList);
      console.log(thumbList);
    };
      captureFrames(tempVideoEl);
      setIsCapturing((isCapturing)=>false)
  }

  const [playerTime, setPlayerTime] = useState(0);
  const handleVideoPreivewLoop = async (e:any)=>{     // video trimmer 구간 반복용
    const element = previewVideoRef.current as HTMLVideoElement
    if( element) {
      setPlayerTime(element.currentTime);
      if(element.currentTime < loopSegment[0] || element.currentTime >= loopSegment[1]) { // 구간 반복
        element.currentTime = loopSegment[0];
        try{
          await element.play();
        }catch(err){
          console.error(err);
        }
      }
    }
  } 

  const previewVideoRef = useRef(null);

  const handleChangeLoopSegment = (start,end) => {
    console.log(`---loop :: ${start} ~ ${end}`)
    setLoopSegment([start,end]);
  }

  const [isLoadedThumb, setIsLoadedThumb] = useState(false); 
  const minDuration = 5;
  const [videoDuration, setVideoDuration] = useState(0);
  const [isEditable, setIsEditable] = useState(true);
  const [isTrimed, setIsTrimed] = useState(false);

  useEffect(()=> {
    if(videoDuration > minDuration) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [videoDuration]);

  const handleLoadMetaData = async (e:any)=>{
    console.log("============= preview video load metadata ===============================")
    const element = previewVideoRef.current as HTMLVideoElement
    //console.log(element.duration);

    if(props.fileInfo.editInfo) {
      if(isLogging){
        console.log("edit info on load metadata :: ", props.fileInfo.editInfo)
      }
      const editInfo = props.fileInfo.editInfo;
      const duration = editInfo.end - editInfo.start;
      setVideoDuration(duration);
      if(duration > minDuration){
        setLoopSegment([editInfo.start, editInfo.start+minDuration])
      }else{
        setLoopSegment([editInfo.start, editInfo.end])
      }
    } else {
      setVideoDuration(element.duration);
      setLoopSegment([0, minDuration]);
    }
    try{
      await element.play();
    }catch(err){
      console.error(err)
    }
    
    // if(props.show) { // 초기화 됐을때...
    //   element.play();
    //   setLoopSegment([0, minDuration]);
    //   //setVideoDuration(minDuration);
    // }

    setIsTrimed(false);
    setIsVideoLoaded(true);
  }

  const handleInitLoop = ()=>{

  }

  function handleThumbLoaded() {
    setIsLoadedThumb(true);
  }

  const [isPlaying, setIsPlaying] = useState(true);

  const handleOnClickPlayButton = async ()=> {
    const element = previewVideoRef.current as HTMLVideoElement;
    if(element){
      if(isPlaying){
        element.pause()
      }else{
        await element.play()
      }
    }
    setIsPlaying(!isPlaying)
  }

  const onClickConfirm = async ()=>{
    setIsCutting(true);

    const videoTrimRequest={
      assetId: sessionStorage.getItem("ASSET_ID"),
      fileName:props.fileInfo.file.name,
      cutInfo:{
        startTime:loopSegment[0],
        cutTime:loopSegment[1]
      }
    }
    if(isLogging){
      console.log("videoTrimRequest", videoTrimRequest)
    }
    try{
      const videoTrimResponse = await WebCutVideo(videoTrimRequest);
      if(videoTrimResponse.result===0){
        const newS3Key = videoTrimResponse.resourceInfo.s3key
        if(isLogging){
          console.log("trimmed video s3Key :: ", newS3Key);
        }
        const {files, editedFiles} = shopInfoContext;
        const editedIdx= shopInfoContext.editedFiles.findIndex(target=>target.file.name===props.fileInfo.file.name);
        let newEditedFileInfo = shopInfoContext.editedFiles[editedIdx];
        newEditedFileInfo.s3Key=newS3Key
        newEditedFileInfo.subCategory="trim"
        newEditedFileInfo.editInfo={
          start:loopSegment[0],
          end:loopSegment[1],
        }
        shopInfoContext.changeEditedFile(editedFiles[editedIdx], newEditedFileInfo);
      }else{
        throw Error(videoTrimResponse.errMsg)
      }
      props.onTrimSave(loopSegment[0],loopSegment[1]);  // 저장.
      props.handleClose();
    }catch(err){
      console.error("Video trim error :: ", err)
      setShowAlert(true);
    }
    setIsCutting(false);
  }

  const onClickTrim = ()=> {
    setIsCutting(true)
    const start = loopSegment[0];
    const end = loopSegment[1];
    console.log(`trim segment: ${start} ~ ${end}`);
    genThumbnails(start,end);
    setVideoDuration(end-start);
    setIsTrimed(true);
    setIsCutting(false)
  }

  const onClickCancel = ()=> {
    props.handleClose();
  }
 
  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      handleConfirm={confirmTrim}
      hasHead={false}
      closeOnClickOutside={false}
    >
      {/* {isCapturing? <>Loading</>:<>ss</>} */}
      {
        isCutting&&
        <div style={{width:"100%", height:"100%", zIndex:9, display:"flex", justifyContent:"center", alignItems:"center", position:"absolute", backgroundColor:"rgba(0,0,0,0.3)"}}>
          <div style={{width:"50px", height:"50px"}}>
            <Lottie
              animationData={spinnerAnimation}
              loop={true}
            />
          </div>
        </div>
      }
      <video ref={thumbRef} className="thumbgen" onLoadedData={handleThumbLoaded} src={props.sourceURL} muted style={{display:"none"}}/>
      <VideoTrimmerFrame>
        <HeadStyle>
          <HeadTextWrapperStyle>
              동영상 시간 조정
            </HeadTextWrapperStyle>
            <Button
              $buttonType='text-s'
              $variant='brand2-subtle'
              text="초기화"           
              disabled={isEditable} 
              onClick={handleTimeStampReset}
            />
        </HeadStyle>
        <BodyStyle>        
          <VideoPreviewStyle
            ref = {previewVideoRef}
            onContextMenu={(e)=>{e.preventDefault()}}
            src={props.sourceURL}
            onTimeUpdate={handleVideoPreivewLoop}
            onLoadedMetadata={handleLoadMetaData}
            //autoPlay
            muted
            //loop
          />
          {
            (loopSegment[1]-loopSegment[0]>5)&&
            <ToastWrapper>
              <Toast>최대 5초까지만 제공 가능합니다</Toast>
            </ToastWrapper>
          }
          <VideoButtonScreenStyle>
            <PlayButton isPlaying={isPlaying} onClick={handleOnClickPlayButton}> </PlayButton>
          </VideoButtonScreenStyle>
        </BodyStyle>
        <ToolStyle>
          <VideoTimeline
            show={props.show}
            previewVideoRef={previewVideoRef}
            thumbnails={thumbs}
            timelineDuration={videoDuration}
            startTime={loopSegment[0]}
            endTime={loopSegment[1]}
            currentTime ={playerTime}
            isPlaying={isPlaying}
            onChange={handleChangeLoopSegment}
            onInit={handleInitLoop}
          />
        </ToolStyle>
        <FootStyle>
          <Button style={{flex:1}} onClick={onClickTrim} $buttonType='capsule' disabled={!isEditable || isCutting} $variant='brand1' text="자르기"/>
          <Button style={{flex:1}} onClick={onClickConfirm} $buttonType='capsule' disabled={!isTrimed || isCutting} $variant='brand1' text="적용하기"/>
          <Button style={{flex:1}} onClick={onClickCancel} $buttonType='capsule' $variant='brand1-subtle' text="취소" disabled={isCutting}/>
        </FootStyle>        
      </VideoTrimmerFrame>
      {showAlert&&
        <AlertModal
          show={showAlert}
          description={alertMessage}
          icon={alertIcon}
          closeAlert={closeAlert}
          onConfirm={closeAlert}
          onlyConfirm
        />
      }
    </Modal>
  )
}

export default VideoTrimmer

const VideoTrimmerFrame = styled.div`
  width:640px;
  height:100%;  
  position:relative;
  gap:0;
`

const HeadStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-08"]} ${spacing["spacing-13"]}`
  }};
  align-items:center;
  display:flex;
  justify-content: space-between;
  position:relative;
  width: 100%;
  box-sizing: border-box;
`
const HeadTextWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-heading-04"]};
  margin-top: -1px;
  position:relative;
  white-space: nowrap;
  width:fit-content;
`

const BodyStyle = styled.div`
  height :440px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000000;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
`
const VideoPreviewStyle= styled.video`
  width: 100%;
  height: 440px;
  position:relative;
`

const ToastWrapper = styled.div`
  position:absolute;
  display:flex;
  align-items:center;
  justify-content:center;
`

const VideoButtonScreenStyle = styled.div`
  width: 100%;
  height: 100%;
  left:0;
  top:0;

  display:flex;
  align-items:center;
  justify-content:center;

  opacity:0;  
  position: absolute;

  &:hover{
    opacity:1;
  }
  
`

const ToolStyle = styled.div`
  align-items:center;
  display:flex;
  width: 100%;
  box-sizing: border-box;
  gap:${(props)=>props.theme.spacing["spacing-06"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-04"]} ${spacing["spacing-08"]} ${spacing["spacing-04"]} ${spacing["spacing-08"]}`
  }};
  position:relative;
`

const FootStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-09"]} ${spacing["spacing-19"]} ${spacing["spacing-11"]} ${spacing["spacing-19"]}`
  }};
  display: flex;
  align-items:center;
  width: 100%;
  box-sizing: border-box;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
`

const FrameStyle = styled.div`
  width:640px;
`