import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tab } from "../../../../../Components/pages/Editor/Tab";

const StyledTabs = styled.div`
  align-items: flex-end;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;

  & .tab-instance {
    flex: 0 0 auto !important;
  }
`;

interface Props {
  selectNum: number;
  onClick: any;
}

export const TabHeader = ({selectNum, onClick}: Props): JSX.Element => {
  const [isSelected, setIsSelected] = useState([true]);
  
  useEffect(()=>{
    let newList = [false, false];
    newList =isSelected.map((val, index)=>{
      if(selectNum === index) {
        return true;
      } else {
        return false;
      }
    });
    setIsSelected(newList);  
  }, [selectNum]);
  
  return (
    <StyledTabs>
      <Tab index={0} className="tab-instance" property1={isSelected[0]? "on": "off"} text="나레이션" onClick={onClick}/>
    </StyledTabs>
  );
};
