import React from "react";
import { createTheme, ThemeProvider as MuiThemeProvider, CssBaseline } from "@mui/material";
import theme from "../styles/theme";

const editorTheme = createTheme({
  palette: {
    primary: {
      main: "#3617CE",
    },
    secondary: {
      main: "rgba(113, 125, 135, 1)",
    },
    background: {
      default: "rgba(255, 255, 255, 1)",
      paper: "rgba(246, 247, 253, 1)",
    },
    text: {
      primary: "rgba(23, 25, 28, 1)",
      secondary: "rgba(68, 75, 82, 1)",
      disabled: "rgba(169, 176, 183, 1)",
    },
    divider: "rgba(226, 228, 231, 1)",
  },
  typography: {
    fontFamily: "Pretendard Variable, Helvetica",
    caption: {
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "-0.12px",
      lineHeight: "170%",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.14px",
      lineHeight: "170%",
    },
    button: {
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "-0.14px",
      lineHeight: "170%",
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "-0.14px",
          lineHeight: "170%",
        },
        head: {
          fontSize: "12px",
          fontWeight: 600,
          letterSpacing: "-0.12px",
          lineHeight: "170%",
        },
        body: {
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "-0.14px",
          lineHeight: "170%",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "-0.14px",
          lineHeight: "170%",
        },
        secondary: {
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "-0.14px",
          lineHeight: "170%",
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={editorTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};