import React from "react";
import styled from "styled-components";


function ProgressBar(props){
  return(
    <ProgressBarStyle {...props}>
      <BarStyle>
        <StatusStyle {...props}>|</StatusStyle>
      </BarStyle>
      {/* <TextStyle {...props}>
        <div style={{width:"6px", height:'4px', position:"relative", transform:"translate(0px, -13px)"}}>
          <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 4L3.5 0L6.5 4H0.5Z" fill="#CACEF4"/>
          </svg>
        </div>
        <DivStyle>
          <TextWrapperStyle>{props.value}</TextWrapperStyle>
        </DivStyle>
      </TextStyle> */}
    </ProgressBarStyle>
  )
}

export default ProgressBar

const ProgressBarStyle = styled.div`
  position:relative;  
  display:flex;
  flex-direction: column;
`

const BarStyle = styled.div`
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  background-color: #ffffff99;
  overflow: hidden;
  height: 8px;
  width: 100%;
`

const StatusStyle = styled.div`
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  background-color:${(props)=>props.theme.colors["icon-brand1"]};
  height: 8px;
  width: ${(props:any)=>props.value}%;
`

const TextStyle = styled.div`
  align-items: center;
  display:flex;
  left:calc(${(props:any)=>props.value}% - 30px);
  flex-direction: column;
  position:absolute;
  top: 16px;
  width:59px;
`

const DivStyle = styled.div`
  align-items: center;
  justify-content:center;
  align-self: stretch;
  background-color: ${(props)=>props.theme.colors["background-quarternary"]};
  border-radius:${(props)=>props.theme.radius["radius-full"]};
  display:flex;
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-none"]} ${spacing["spacing-08"]} ${spacing["spacing-none"]} ${spacing["spacing-08"]}`
  }};
`

const TextWrapperStyle = styled.div`
  color: ${(props)=>props.theme.colors["text-brand1"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  text-align:center;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
`