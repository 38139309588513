import React from "react";

export interface CheckListInterface{
  icon:string|undefined,
  text:string,
  desc:string|undefined,
  checked:boolean,
}

export interface FontFeature{
  fontFeatureList:string[],
  fontColor:string,
}

interface ShopPointContextInterface{
  prosList:CheckListInterface[],
  targetList:CheckListInterface[],
  customRequirement:string,
  requiredTextInfo:any,
  customFeatureList:string[],
  customCustomerList:string[],
  customMoodList:string[],
  fontFeature:FontFeature,
  bgmFeature:string[],
  narrationCharacterFeature:string[],

  setProsList:(newList:CheckListInterface[])=>void,
  setTargetList:(newList:CheckListInterface[])=>void,
  setCustomRequirement:(newText:string)=>void,
  setRequiredTextInfo:(newTextInfo:any)=>void,
  setCustomFeatureList:(newList:string[])=>void,
  setCustomCustomerList:(newList:string[])=>void,
  setCustomMoodList:(newList:string[])=>void,
  setFontFeature:(newFeature:FontFeature)=>void,
  setBgmFeature:(newFeature:string[])=>void,
  setNarrationCharacterFeature:(newFeature:string[])=>void
}

const ShopPointContextDefault:ShopPointContextInterface = {
  prosList:[],
  targetList:[],
  customRequirement:"",
  requiredTextInfo:undefined,
  customFeatureList:[],
  customCustomerList:[],
  customMoodList:[],
  fontFeature:{fontFeatureList:[],fontColor:"#000000"},
  bgmFeature:[],
  narrationCharacterFeature:[],

  setProsList:()=>{},
  setTargetList:()=>{},
  setCustomRequirement:()=>{},
  setRequiredTextInfo:()=>{},
  setCustomFeatureList:()=>{},
  setCustomCustomerList:()=>{},
  setCustomMoodList:()=>{},
  setFontFeature:()=>{},
  setBgmFeature:()=>{},
  setNarrationCharacterFeature:()=>{}
}

export const ShopPointContext = React.createContext(ShopPointContextDefault);