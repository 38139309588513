import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import styled from "styled-components";
import { TabIcon7 } from "../../icons/TabIcon7/TabIcon7";

const StyledEffectTabWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-01-duplicate);
  height: 64px;
  padding: 12px 8px 2px;
  position: relative;
  width: 64px;
  cursor: pointer;
  ${(props)=>props.theme.typography["pre-caption-02"]};


  & .text-wrapper-5 {
    align-self: stretch;
    position: relative;
    text-align: center;
  }

  & .tab-icon {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  &.selected {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--tokens-color-border-brand-duplicate);
  }

  &.hover-active .text-wrapper-5 {
    color: var(--tokens-color-text-brand1-duplicate);
    ${(props)=>props.theme.typography["pre-caption-02"]};
  }

  &.selected .text-wrapper-5 {
    color: var(--tokens-color-text-brand1-duplicate);
   ${(props)=>props.theme.typography["pre-caption-01"]};
  }

  &.default .text-wrapper-5 {
    color: var(--tokens-color-text-quaternary-duplicate);
    ${(props)=>props.theme.typography["pre-caption-02"]};
  }
`;

interface Props {
  index: number;
  isSelected: boolean;
  className: any;
  icon: JSX.Element;
  text: string;
  onClick: any;
  style?: any
}

export const EffectTabWrapper = ({
  index,
  isSelected,
  className,
  icon,
  text,
  onClick,
  style=undefined
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    state: "default",
  });

  return (
    <StyledEffectTabWrapper
      style={{...style}}
      className={`state-default-wrapper ${className} ` + (isSelected? "selected":"default")}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={()=> onClick(index)}
    >
      {icon}
      <div className="text-wrapper-5">{text}</div>
    </StyledEffectTabWrapper>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover-active",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

// EffectTabWrapper.propTypes = {
//   stateProp: PropTypes.oneOf(["hover-active", "selected", "default"]),
//   text: PropTypes.string,
// };
