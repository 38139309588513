import apiClient from "./index.ts";

//매장정보 분석 요청
async function WebPostAnalyzeStore(request){
  try{
    const response = await apiClient.post('/ai/analyze-store', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

//매장정보 분석 결과
async function WebGetAnalyzeStore(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
  
    const response = await apiClient.get(`/ai/analyze-store?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 이미지 분석 요청
async function WebPostAnalyzeImage(request){
  try{
    const response = await apiClient.post('/ai/analyze-image', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 이미지 분석 결과
async function WebGetAnalyzeImage(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
  
    const response = await apiClient.get(`/ai/analyze-image?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
  
}

// 요청사항 분석 요청
async function WebPostAnalyzeRequirement(request){
  try{
    const response = await apiClient.post('/ai/analyze-requirement', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 요청사항 분석 결과
async function WebGetAnalyzeRequirement(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/analyze-requirement?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 시나리오 후보 생성 요청
async function WebPostCreateScenarioCandidate(request){
  try{
    const response = await apiClient.post('/ai/create-scenario-candidate', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 시나리오 후보 생성 결과
async function WebGetCreateScenarioCandidate(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-scenario-candidate?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 장면 생성 요청
async function WebPostCreateScenario(request){
  try{
    const response = await apiClient.post('/ai/create-scenario', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 장면 생성 결과
async function WebGetCreateScenario(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-scenario?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}


// 최종 장면 데이터 생성 요청
async function WebPostFinalScenario(request){
  try{
    const response = await apiClient.post('/ai/final-scenario', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}
// async function WebGetFinalScenario(request){
//   const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
//   const query = paresRequest.join("&");
//   const response = await apiClient.get(`/ai/final-scenario?${query}`);
//   const result = await response.data;
//   return result;
// }

// 광고 생성 요청
async function WebCreateAd(request){
  try{
    const response = await apiClient.post('/ai/create-ad', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// BGM 태그 생성 요청
async function WebPostCreateBGMTag(request){
  try{
    const response = await apiClient.post('/ai/create-bgmtag', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// BGM 태그 생성 결과
async function WebGetCreateBGMTag(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-bgmtag?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// BGM 태그 코드 생성 요청
async function WebPostCreateBGMTagCode(request){
  try{
    const response = await apiClient.post('/ai/create-bgmtagcode', request);
    if(response.status===200){
      const result = await response.data;
      return result;
    }else{
      console.error("response error : ", response.status);
      return Promise.reject(`response error : ${response.status}`)
    }
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// BGM 생성 요청
async function WebCreateBGM(request){
  try{
    const response = await apiClient.post(`/ai/create-bgm`, request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// TTS 액터 선정 요청
async function WebPostSelectTTSActor(request){
  try{
    const response = await apiClient.post('/ai/select-tts-actor', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// TTS 액터 선정 결과
async function WebGetSelectTTSActor(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/select-tts-actor?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 나레이션 문구 생성 요청
async function WebPostCreateNarration(request){
  try{
    const response = await apiClient.post('/ai/create-narration', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 나레이션 문구 생성 결과
async function WebGetCreateNarration(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-narration?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
  // need Typecast API Token in header
}

// TTS 생성 요청
async function WebPostCreateSpeak(request){
  try{
    const response = await apiClient.post('/ai/create-tts', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// TTS 생성 결과
async function WebGetCreateSpeak(request, token){
  try{
    // need Typecast API Token in header
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-tts?${query}`,{headers:{"token":token}});
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}


// 레이아웃 생성 요청
async function WebPostGenLayout(request){
  try{
    const response = await apiClient.post('/ai/create-gen-layout', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 레이아웃 생성 결과
async function WebGetGenLayout(request){
  try{
    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/create-gen-layout?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 인페인팅 지우개 요청
async function WebPostInpaintingEraser(request){
  try{
    const response = await apiClient.get(`/ai/inpainting-eraser`,request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// 인페인팅 배경화명 요청
async function WebPostInpaintingBG(request){
  try{
    const response = await apiClient.get(`/ai/inpainting-bg`,request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

/////// todo ::: Supernova, I2V

// T2I 생성 요청
async function WebPostText2Image(request){
  try{
    const response = await apiClient.post('/ai/create-text2image', request);
    const result = await response.data;
    return result; 
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

async function WebPostImage2Video(request){
  try{
    const response = await apiClient.post('/ai/create-image2video', request);
    const result = await response.data;
    return result; 
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

// T2I 생성 결과
async function WebGetAIGernatingStatus(request){
  try{
    const paresRequest = Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");
    const response = await apiClient.get(`/ai/get-state-t2i-i2v?${query}`);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

export {
  WebPostAnalyzeStore,
  WebGetAnalyzeStore,
  WebPostAnalyzeImage,
  WebGetAnalyzeImage,
  WebPostAnalyzeRequirement,
  WebGetAnalyzeRequirement,
  WebPostCreateScenarioCandidate,
  WebGetCreateScenarioCandidate,
  WebPostCreateScenario,
  WebGetCreateScenario,
  WebPostFinalScenario,
  // WebGetFinalScenario,
  WebCreateAd,
  WebPostCreateBGMTag,
  WebGetCreateBGMTag,
  WebPostCreateBGMTagCode,
  WebCreateBGM,
  WebPostSelectTTSActor,
  WebGetSelectTTSActor,
  WebPostCreateNarration,
  WebGetCreateNarration,
  WebPostCreateSpeak,
  WebGetCreateSpeak,
  WebPostGenLayout,
  WebGetGenLayout,
  WebPostInpaintingEraser,
  WebPostInpaintingBG,
  WebPostText2Image,
  WebGetAIGernatingStatus,
  WebPostImage2Video
}