import styled from "styled-components";
import Button from "../Button";
import Icon from "../../Icon";
import { ReactComponent as CharacterMotion } from "../../../Assets/CharacterAsset/character-motion.svg";

export const ContentUnitDropZoneStyle = styled.div`
  align-items: center;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border: ${(props)=>props.theme.border["border-02"]} solid;
  border-color:${(props)=>props.theme.colors["border-secondary"]};
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  display: flex;
  flex-direction: row;
  height: 108px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;

  box-sizing: border-box;

  &:disabled{
    opacity:0.4;
    background-color:red;
  }

  &:hover{
    background-color: ${(props)=>props.theme.colors["background-secondary-hover"]};
    border: 1px dashed;
    border-color: ${(props)=>props.theme.colors["border-active"]};
    &.loading{
      background-color: ${(props)=>props.theme.colors["background-primary"]};
    }
  }
   
  &.drag-over{
    background-color: ${(props)=>props.theme.colors["background-secondary-hover"]};
    border: 1px dashed;
    border-color: ${(props)=>props.theme.colors["border-active"]};
    background-color: ${(props)=>props.theme.colors["button-mute"]};
    cursor:grab;
  }

  &.uploading{
    background-color:${(props)=>props.theme.colors["background-secondary"]};
    border: 1px dashed;
    border-color: ${(props)=>props.theme.colors["border-secondary"]};
    align-items:center;
    justify-content: center;
  }
`

export const ContentUnitStyle = styled.div`
  align-items: center;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border: ${(props)=>props.theme.border["border-02"]} solid;
  border-color:${(props)=>props.theme.colors["border-secondary"]};
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  display: flex;
  flex-direction: column;
  height: 108px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 160px;

  box-sizing: border-box;

  &:disabled{
    opacity:0.4;
    background-color:red;
  }

  &.image{
    background-color:transparent;
    background-image: url("${(props)=>props.sourceURL}");
    background-position: 50% 50%;
    background-size: cover;
  }
  &.video{
    background-color:transparent;
    background-image: url("${(props)=>props.sourceURL}");
    background-position: 50% 50%;
    background-size: cover;
  }
  &.loading{
    padding:0;
  }


  &:hover{
    background-color: ${(props)=>props.theme.colors["button-mute"]};
    border-color: ${(props)=>props.theme.colors["border-brand"]};

    &.loading{
      background-color: ${(props)=>props.theme.colors["background-primary"]};
    }
  }
`

export const ContentAddButtonFrameStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction:column;
  flex-grow: 1;
  justify-content: center;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  padding: ${(props)=>props.theme.spacing["spacing-07"]};
  position:relative;
  width:100%;
`
export const FileTextWrapper = styled.div`
  display: flex;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  align-items:center;
  justify-content:center;
`

export const FileTextStyle = styled.div`
  align-items: center;
  align-self:stretch;
  display: inline-flex;
  gap:${(props)=>props.theme.spacing["spacing-04"]};

  position:relative;
  box-sizing:border-box;
`

export const TypeTextStyle = styled.div`
  ${(props)=> props.theme.typography["pre-caption-02"]};
  color:${(props)=>props.theme.colors["text-tertiary"]};
  margin-top: -1px;
  position:relative;
  text-align:center;
  white-space: nowrap;
  width:fit-content;
`

export const ExtWrapperStyle = styled.div`
  align-items:center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: ${(props)=> props.theme.spacing["spacing-02"]};
  position:relative;
`

export const ExtTextStyle = styled.div`
  ${(props)=>props.theme.typography["pre-caption-01"]};
  color: ${(props)=>props.theme.colors["text-tertiary"]};
  margin-top:-1px;
  position:relative;
  white-space: nowrap;
  width: fit-content;
`
export const DescTextStyle = styled.div`
  color:${(props)=>props.theme.colors["text-brand2"]};
  ${(props)=>props.theme.typography["pre-caption-01"]};
`

export const DescTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props)=>props.theme.spacing["spacing-01"]};
`


export const ContentImageFrameStyle = styled.div`
  align-items: flex-start;
  display:flex;
  justify-content: space-between;
  left:0;
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-02"]} ${spacing["spacing-02"]} ${spacing["spacing-03"]} ${spacing["spacing-04"]}`
  }};
  position: absolute;
  top: 0;
  box-sizing:border-box;
  width:160px;
`

export const ContentImageNameStyle = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${(props)=>props.theme.colors["text-primary-inverse"]};
  ${(props)=>props.theme.typography["pre-caption-02"]};
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`

export const ContentImageDeleteStyle = styled(Icon)`
  all:unset;
  width: 24px;
  height: 24px;
  border: none;
  cursor:pointer;
`

export const ContentButtonStyle = styled.div`
  all: unset;
  align-items: center;
  background-color: ${(props)=>props.theme.colors["background-overlay-subtle"]};
  display: flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  height: 40px;
  justify-content: center;
  left: 0;
  color:${(props)=>props.theme.colors["text-primary-inverse"]};
  ${(props)=>props.theme.typography["pre-caption-02"]};
  position: absolute;
  top:68px;
  width:160px;
  cursor: pointer;

  user-select:none;

`


export const LoadingOverlapGroupStyle = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const LoadingFrameStyle = styled.div`
  align-items: center;
  display: flex;
  gap: 84px;
  justify-content: flex-end;
  padding: ${(props)=>props.theme.spacing["spacing-04"]};
  box-sizing: border-box;
  top:0;
  left:0;
  position:absolute;
  width: 160px;
`

export const FileButtonStyle = styled(Button)`
  padding:0px ${(props)=>props.theme.spacing["spacing-05"]};
`

export const CharacterStyle = styled(CharacterMotion)`
  height: 48px;
  width: 48px;
  position:absolute;
  left: 56px;
  top: 30px;
`