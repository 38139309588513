import styled from "styled-components"

export const InputStyle = styled.input`
  ${({theme})=>theme.typography["pre-body-02"]};
  width: 100%;
`

export const InputSearchStyle= styled.div`
  display: flex;
  align-items:center;
  justify-content: space-between;
  background-color:white;
  gap:${({theme})=>theme.spacing["spacing-04"]};
  width: 100%;
  height: ${(props) => { return props.height || '48px' }};
  border-radius: ${(props) => { return props.radius || 8 }}px;
  outline:none;
  box-sizing: border-box;
  padding: 0px ${(props) =>props.theme.spacing["spacing-07"]} 0px ${(props) => props.theme.spacing["spacing-07"]};
  border: 1px solid ${(props) => props.theme.colors["border-primary"]};
  color:${(props)=>props.theme.colors["text-tertiary"]};

  &input{
    ${({theme})=>theme.typography["pre-body-02"]}
  }

  &.filled{
    ${({theme})=>theme.typography["pre-body-02"]}
    color:${(props)=>props.theme.colors["text-primary"]};
    border:${({theme})=>`${theme.border["border-02"]} solid ${theme.colors["border.primary"]}`};
  }
  &.active{
    ${({theme})=>theme.typography["pre-body-02"]}
    color:${(props)=>props.theme.colors["text-primary"]};
    border:${({theme})=>`${theme.border["border-02"]} solid ${theme.colors["border.active"]}`};
  }
  &.invalid{
    ${({theme})=>theme.typography["pre-body-02"]}
    border:${({theme})=>`${theme.border["border-03"]} solid ${theme.colors["support.error"]}`};
  }
  &.disabled{
    ${({theme})=>theme.typography["pre-body-02"]}
    border:${({theme})=>`${theme.border["border-02"]} solid ${theme.colors["border.primary"]}`};
    opacity:0.4;
    cursor:default;
  }
`
