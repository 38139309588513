import React, { useEffect, useRef, useState } from "react";

import {TextareaStyle, TextareaResizerStyle, ResizerIconStyle} from "./Textarea.styled"
import e from "express";
import { elementType } from "prop-types";

// import { ReactComponent as ResizerIcon } from "../../../Assets/Icons/textarea-resizer.svg";

function Textarea(props){ 
  const areaRef = useRef(null);

  const [state,setState]= useState("default");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  const [startPosX, setStartPosX] = useState(0);
  const [startPosY, setStartPosY] = useState(0);
  const [startWidth, setStartWidth] = useState(0);
  const [startHeight, setStartHeight] = useState(0);

  useEffect(()=>{
    if(props.state){
      setState(props.state)
    }else{
      if(props.value){
        setState("filled")
      }else{
        setState("default")
      }
    }
  },[props.state])

  const handleStartResizing = (e)=>{
    console.log(`start at ( ${e.clientX}, ${e.clientY} )`)
    setStartPosX(e.clientX);
    setStartPosY(e.clientY);

    const element = areaRef.current as HTMLElement
    if(element){
      setStartWidth(element.getBoundingClientRect().width);
      setStartHeight(element.getBoundingClientRect().height);
    }

    setIsResizing(true)
  }
  const handleStopResizing = ()=>{
    setIsResizing(false)
  }

  const handleMouseMove = (e:any)=>{    
    if(isResizing){
      const offsetX = e.clientX - startPosX;
      const offsetY = e.clientY - startPosY;
      console.log(`width : ${e.clientX} - ${startPosX} :: ${e.clientX - startPosX}`)
      console.log(`height : ${e.clientY} - ${startPosY} :: ${e.clientY - startPosY}`)

      const element = areaRef.current;
      if(element){
        console.log("element exist")
        element.style.width = startWidth + offsetX;
        element.style.height = startHeight + offsetY;
      }
    }
  }

  useEffect(()=>{
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleStopResizing);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleStopResizing);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleStopResizing);
    };

  },[isResizing])

  function removeState(){
    const element = areaRef.current as HTMLElement
    if(element){
      element.classList.remove("default")
      element.classList.remove("filled")
      element.classList.remove("active")
      element.classList.remove("invalid")
      element.classList.remove("disabled")
    }
  }

  const handleOnChange = (e)=>{

    if(props.onChange){
      props.onChange(e)
    }
  }

  const handleOnFocus = (e)=>{
    setState("active")
    if(props.onFocus){
      props.onFocus(e);
    }
  }
  const handleOnBlur = (e)=>{
    const element = areaRef.current as HTMLTextAreaElement
    if(element.value){
      setState("filled")
    }else{
      setState("default")
    }
    if(props.onBlur){
      props.onBlur(e)
    }
  } 


  useEffect(()=>{
    if(props.state){
      setState(props.state)
    }
  },[props.state])

  return (
    <TextareaStyle
      state={state}
      value={props.value}
      placeholder={props.placeholder}
      style={{width:`${width}px`, height:`${height}px`}}
      ref={areaRef}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      {...props}
    />

  )
}

export default Textarea

function TextareaResizer(props){
  return (
    <TextareaResizerStyle {...props}>
      <ResizerIconStyle/>
    </TextareaResizerStyle>
  )

}