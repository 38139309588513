import styled, {keyframes} from "styled-components";
import PlayerController from "../Player";

export const VideoPlayerStyle = styled.div`
  width: 100%;
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  background-color: #00000080;
  overflow: hidden;
  position: relative;
  display:flex;
`
export const PlayButtonScreenStyle = styled.div`
  width:100%;
  height:100%;
  background-color: transparent;
  // background-color:${(props)=>props.theme.colors["background-overlay"]};
  transition:0.2s;
  opacity: 0;
  &:hover{
    opacity: 1;
  }
`
export const PlayButtonWrapperStyle = styled.div`
  left: calc(50% - 26px);
  top: calc(50% - 26px);
  position: absolute;
`

const moveUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const PlayerControllerStyle = styled(PlayerController)`
  left: 0px;
  bottom: 0px;
  position: absolute;
  box-sizing:border-box;
  width:100%;
  ${(props)=>{
    if(props.active){
      return {
        opacity: 1,
        transform: `translateY(0)`
      }
    }else{
      return{
        opacity: 0,
        transform: `translateY(20px)`
      }
    }
  }}
  transition:0.5s;
  &.active{
    animation: ${moveUp} 0.2s ease-out forawrd;
  }
`