import React, {useState, useEffect, useRef} from "react";

import { SelectGroupStyle,SelectStyle,SelectListUnitStyle,SelectListStyle,SelectPlaceholderStyle} from "./InputSelect.styled";
import Icon from "../../Icon";
import theme from "../../../styles/theme";

import { ReactComponent as QuestionIcon } from "../../../Assets/Icons/Question.svg";

function InputSelect(props){
  const selectRef = useRef(null)
  const [componentState, setComponentState] = useState("default");
  const [isOpen, setIsOpen] = useState(false);
  const [value,setValue] = useState(undefined);

  useEffect(()=>{
    if(props.state){
      setComponentState(props.state)
    }else{
      setComponentState("default")
    }
  },[props.state])

  useEffect(()=>{
    if(props.value){
      setComponentState("filled")
    }else{
      setComponentState("default")
    }
  },[props.value])

  const handleClickSelect = (e:any) => {
    if(isOpen){
      activateSelect(false)
    }else{
      activateSelect(true)
    }
    if(props.onClick){
      props.onClick()
    }
  } 
  const handleOnClickList = (value:any) => {
    console.log("List unit clicked" , value)
    activateSelect(false);
    if(props.onChange){
      props.onChange(value);
    }
  }

  useEffect(()=>{
    const element = (selectRef.current as HTMLElement)
    if(element){
      if(isOpen){
        element.classList.add("active")
        setComponentState("active")
      }else{
        element.classList.remove("active")
      }
    }
  },[isOpen])

  function activateSelect(active){    
    if(active){
      setIsOpen((isOpen)=>true)
    }else{
      setIsOpen((isOpen)=>false)
    }    
  }

  useEffect(()=>{
    if(props.state){
      switch(props.state){
        case "default":
          setComponentState("default");
          break;
        case "filled":
          setComponentState("filled");
          break;
        case "active":
          setComponentState("active");
          break;
        case "invalid":
          setComponentState("invalid");
          break;
        case "disabled":
          setComponentState("disabled");
          break;
        default: 
          setComponentState("default");      
          break;
      }
    }
  },[props.state])

  const handleFocusOut = () => {
    activateSelect(false);
    if(value){
      setComponentState("filled");
    }else{
      setComponentState("default");
    }
  }

  return(
    <SelectGroupStyle {...props}>
      <SelectStyle state={componentState} ref={selectRef} onClick={handleClickSelect} {...props}>
        <SelectPlaceholderStyle>
          {props.value? props.value:
            props.placeholder? props.placeholder:
              "Placeholder"
          }
        </SelectPlaceholderStyle>
        <Icon icon="CaretDown" color={"icon-primary"}/>
      </SelectStyle>
      {isOpen&&
        <>
          <SelectList {...props} onClick={handleOnClickList} list={props.list} />
          <InputSearchBackScreen onClick={handleFocusOut}/>
        </>
      }
    </SelectGroupStyle>
  )
}

export default InputSelect

function InputSearchBackScreen(props){
  return <div onClick={props.onClick} style={{width:"100vw", height:"100vh", top:0, left:0, opacity:0, position:"fixed",cursor:"default"}}></div>
}

function SelectListUnit(props){
  return(
    <SelectListUnitStyle {...props}>      
      {props.value}
      {props.value==="분양광고"&&<Icon icon="Question" height="16px" color="icon-primary"/>}
    </SelectListUnitStyle>
  )
}

function SelectList(props){
  const handleOnClickUnit = (e:any,value:any)=>{
    e.stopPropagation();
    e.preventDefault();
    console.log("clicked ", value)
    props.onClick(value);
  }
  return(
    <SelectListStyle {...props} onClick={undefined}>
      {props.list?.map((item, index)=>{
        return (
          <SelectListUnit {...props} key={`select-${index}`} onClick={(e)=>{handleOnClickUnit(e,item)}} value={item} />
        )
      })}
    </SelectListStyle>
  )
}