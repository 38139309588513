import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  &.tab-icon-3 {
    fill: none;
    height: 24px;
    width: 24px;
  }

  .g {
    clip-path: url(#clip0_116_8732);
  }

  .path-default {
    fill: #a9b0b7;
  }
  
  .path-selected {
    fill: #3617CE;
  }

  .defs {
    .clip-path {
      path {
        fill: white;
      }
    }
  }
`;

interface Props {
  state: string;
}

export const EffectTabIcon3 = ({ state="default" }: Props): JSX.Element => {
  return (
    <StyledSvg
      className="tab-icon-3"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g">
        <path className={`path-${state}`} d="M20 19V5L12 12L20 19Z" />
        <path className={`path-${state}`} d="M4 5V19L12 12L4 5Z" />
        <path
          className={`path-${state}`}
          d="M12 12L4 19V5L12 12ZM12 12L20 5V19L12 12Z"
          stroke={state=="default"? "#A9B0B7":"#3617CE"}
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_116_8732">
          <path
            className={`path-${state}`}
            d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
          />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};