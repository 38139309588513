import React from "react";
import styled from "styled-components";
import Badge from "../../../common/Badge";

import { VideoInfoStyle, FrameTopStyle, FrameTopTitleStyle, ContentsFrameStyle, ContentsItemStyle, ContentsItemTextStyle } from "./VideoInfo.styled";

function InfoItem(props){
  return(
    <ContentsItemStyle>
      <Badge variant={props.badgeVariant} width={props.badgeWidth} text={props.badgeText}/>
      <ContentsItemTextStyle>
        {props.text}
      </ContentsItemTextStyle>
    </ContentsItemStyle>
  )
}

function VideoInfo(props){
  return(
    <VideoInfoStyle
      onClick={(e:any)=>{
        if(props.onClick) props.onClick(e, props.scenario.scenario_no);
      }}
    >
      <FrameTopStyle>
        <FrameTopTitleStyle>
          광고정보
        </FrameTopTitleStyle>                   
      </FrameTopStyle>
      <ContentsFrameStyle>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={"업체명"} text={props.shopName}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={"업체주소"} text={props.address}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={"광고시간"} text={props.duration}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={"제작일자"} text={props.madeDate}/>        
      </ContentsFrameStyle>
    </VideoInfoStyle>
  )
}

export default VideoInfo
