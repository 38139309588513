import styled from "styled-components";

export const VideoSectionStyle = styled.div`
  align-items: center;
  justify-content: center;
  display:flex;
  flex-direction: column;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  position:relative;
  width:100%;
  height:fit-content;
  box-sizing: border-box;
`
export const VideoStyle = styled.video`
  width: 100%;
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  overflow:hidden;
  // height:100%;
`

export const LoadingStatusStyle = styled.div`
  display: flex;
  width:100%;
  height: fit-content;
  
  flex-direction: row;
  
  padding: ${(props)=>props.theme.spacing["spacing-04"]} ${(props)=>props.theme.spacing["spacing-08"]};

  align-items: center;
  justify-content: space-between;

  box-sizing:border-box;
  background-color: ${(props)=>props.theme.colors["background-quarternary"]};
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
`
export const LoadingTextWrapper = styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-brand1"]};
`
export const LottieWrapper = styled.div`
  width:100px;

  padding-top:${(props)=>props.theme.spacing["spacing-17"]};
  padding-bottom:${(props)=>props.theme.spacing["spacing-13"]}
`
export const VideoWrapper = styled.div`
  width: 100%;  
  height: fit-content;
  background-color:black;
  border-radius: ${(props)=>props.theme.radius["radius-04"]};
  overflow: hidden;
`