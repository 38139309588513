import React, {useState, useEffect, useRef}from "react";

import styled from "styled-components";
import Badge from "../Badge";

import Lottie from "lottie-react";
// import characterAnimation from '../../../animation/character.json'
import characterAnimation from '../../../animation/aside-character.json'

import { ReactComponent as BlueCharacter } from "../../../Assets/CharacterAsset/blue.svg"

import {ProgressBarStyle, NumberDivStyle, ContentDivStyle, ProgressTextWrapperStyle, BlueCharacterStyle} from './ProgressBar.styled'

type StatusType="ing"|"done"|"todo"
const statusTypes = ["ing","done","todo"]

interface ProgressBarInterface{
  number?:number,
  text?:string,
  status?:StatusType
}

function ProgressBar(props){

  const [badge, setBadge] = useState<any>(undefined);
  const [state, setState] = useState(undefined)

  const ref = useRef(null);

  const lottieRef = useRef(null);
  const [isHovered, setIsHovered] = useState(true);

  useEffect(()=>{
    if(ref.current){
      const element = ref.current as HTMLElement
      statusTypes.map(type=>{
        element.classList.remove(type)
      })
      switch(props.status){
        case "ing":
          element.classList.add("ing")
          break;
        case "done":
          element.classList.add("done")
          break;
        case "todo":
          element.classList.add("todo")
          break;
      }
    }
    console.log('change state ', props.status)
    switch(props.status){
      case "ing":
        setState('ing')
        setBadge(<Badge width={"54px"} text="진행중" variant="brand-subtle"/>)
        break;
      case "done":
        setState('done')
        setBadge(<Badge width={"54px"} text=" 완료 " variant="brand2"/>)
        break;
      case "todo":
        setState('todo')
        setBadge(<Badge width={"54px"} text="미완료" variant="white"/>)
        break;
    }
  },[props.status])

  const handleOnClick = () => {
    if(!props.disabled){
      if(props.status !== 'ing'){
        if(props.onClick){
          props.onClick();
        }
      }else if(props.status === 'todo'){
        
      }
    }
  }


  useEffect(() => {
    if (lottieRef.current) {
      if (isHovered) {
        lottieRef.current.goToAndPlay(0, true);
        setIsHovered(false);
      }
    }
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }


  return (
    <ProgressBarStyle ref={ref} {...props} onClick={handleOnClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NumberDivStyle>
        {("000"+props.number.toString()).slice(-2)}
      </NumberDivStyle>
      <ContentDivStyle>
        <ProgressTextWrapperStyle className={state}>
          {props.text}
        </ProgressTextWrapperStyle>
        {badge}
      </ContentDivStyle>
      <BlueCharacterStyle className={state}>
        <Lottie 
          lottieRef={lottieRef}
          animationData={characterAnimation}
          loop={false}
          autoplay={false}
          style={{width:"100px",height:"100px"}}
        />
      </BlueCharacterStyle>
      {/* <BlueCharacterStyle className={state}/> */}
    </ProgressBarStyle>
  )
}

export default ProgressBar