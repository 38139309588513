import styled, { keyframes } from "styled-components"

export const ModalStyle = styled.div`
  align-items: center;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  box-shadow: ${(props)=>props.theme.shadow["modal"]};
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-09"]};
  overflow: hidden; 
  position: relative;
`

const dimAnim = keyframes`
  0%{
    opacity:0
  }
  100%{
    opacity:1
  }
`

export const ModalDimStyle = styled.div`
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.4);
  
  &.show{
    display: flex;
    animation: ${dimAnim} 0.3s;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
`