import React from "react";
import {QuestionStyle, InputLabelStyle,LabelStyle,DescriptionStyle, QuestionHeaderStyle, SwitchWrapperStyle} from "./Question.styled"
import InputLabel from "../InputLabel";
import styled from "styled-components";
import Switch from "../../Switch";

function Question(props){
  return (
    <QuestionStyle>
      <QuestionHeaderStyle>
        <InputLabelStyle>
          <InputLabel label={props.label}/>
          <DescriptionStyle>
            {props.description}
          </DescriptionStyle>
        </InputLabelStyle>
        {props.hasSwitch && 
          <SwitchWrapperStyle>
            {props.switchLabel}
            <Switch checked={props.checked} setChecked={props.setChecked} size="lg"/>
          </SwitchWrapperStyle>
        }
      </QuestionHeaderStyle>
      {props.children}
    </QuestionStyle>
  )
}

export default Question;
