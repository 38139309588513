import React from "react";
import styled from "styled-components";

interface Props {
  state: string;
}

const StyledSvg = styled.svg`
  &.tab-icon-3 {
    fill: none;
    height: 24px;
    width: 25px;
  }

  .path-default {
    stroke: #a9b0b7;
    //stroke: #3617CE;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
  }
  
  .path-selected {
    // stroke: #a9b0b7;
    stroke: #3617CE;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
  }

  .defs {
    .clip-path {
      path {
        fill: white;
      }
    }
  }
`;

export const EffectTabIcon4 = ({ state="default" }: Props): JSX.Element => {
  return (
    <StyledSvg
      className={`tab-icon-3`}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_116_23320)">
        <g className="g" clipPath="url(#clip1_116_23320)">
          <path
            className={`path-${state}`}
            d="M12.6 5.25V18.75"
          />
          <path
            className={`path-${state}`}
            d="M5.84998 8.25V5.25H19.35V8.25"
          />
          <path
            className={`path-${state}`}
            d="M9.59998 18.75H15.6"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_116_23320">
          <path
            className="path"
            d="M0.599976 8C0.599976 3.58172 4.1817 0 8.59998 0H16.6C21.0183 0 24.6 3.58172 24.6 8V16C24.6 20.4183 21.0183 24 16.6 24H8.59998C4.1817 24 0.599976 20.4183 0.599976 16V8Z"
          />
        </clipPath>
        <clipPath className="clip-path" id="clip1_116_23320">
          <rect className="rect" fill="white" height="24" transform="translate(0.599976)" width="24" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};
