import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack, ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@mui/material";
import styled, { withTheme } from "styled-components";
import { SceneToggleButtons } from "../../../../../../../Components/pages/Editor/SceneToggleButtons";
import { EditorPageContext } from "../../../../../editorPageContext";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
//import { ThemeProvider } from "../../../../../../../theme/ThemeProvider";
import theme from "../../../../../../../styles/theme";

import screenEffectDataJson from "../../../../../../../Data/screenEffectData.json"
import { EditorContext } from "../../../../../../../Contexts/EditorContext";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const SceneButton = styled((props: ButtonProps) => (
  <Button
    variant="outlined"
    sx={{
      borderColor: 'divider',
      padding: 1,
      whiteSpace: 'normal'
    }}
    {...props}
  />
))((theme) => {
  return {
  width: 90.33,
  height: 56,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
}});

// const SceneButton = styled(Button)({
//   boxShadow: 'none',
//   textTransform: 'none',
//   fontSize: 16,
//   padding: '6px 12px',
//   border: '1px solid',
//   lineHeight: 1.5,
//   backgroundColor: '#5612f3',
//   borderColor: '#0063cc',
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   '&:hover': {
//     backgroundColor: '#0069d9',
//     borderColor: '#0062cc',
//     boxShadow: 'none',
//   },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: '#0062cc',
//     borderColor: '#005cbf',
//   },
//   '&:focus': {
//     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//   },
// });

// const SceneButton = styled(withTheme(Button))( props => {
//  console.log(props);
//  return {
//   flex: 1,
//   height: 56,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   border: 1,
//   borderColor: "black",
//   borderRadius: 2,
// }});

export const ScreenEffectTabContent = ({index}): JSX.Element => {
  
  const [screenEffect, setScreenEffect] = useState('zoom_in');
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);
  const scenarioContext = useContext(ScenarioContext);

  const [screenEffectArgs, setScreenEffectArgs] = useState(undefined);

  useEffect(()=>{
    // console.log(editorContext.screenEffectList)
    const screenEffect = editorContext.screenEffectList[index].before;
    setScreenEffectArgs(screenEffect)
    let effect
    if(screenEffect.enable){
      if(screenEffect.aspect==="horizontal"){
        effect = screenEffect.reverse? "zoom_out" : "zoom_in"
      }else if(screenEffect.aspect==="vertical"){
        effect = screenEffect.reverse? "slide_up" : "slide_down"
      }
    }else{
      effect='none'
    }
    // console.log("Effect ::: ", screenEffect, effect);
    setScreenEffect(effect)
  },[index,editorContext.screenEffectList])


  useEffect(()=>{
    // console.log("EffectList Changed ::: ",editorContext.screenEffectList)
    
  },[editorContext.screenEffectList])

  const handleChangeEffect = (newEffect: any ) => {
    // setScreenEffect(newValue);
    // console.log(newEffect);
    let newArgs=JSON.parse(JSON.stringify(screenEffectArgs))
    switch(newEffect.name){
      case "slide_down":
        newArgs.reverse=false;
        newArgs.enable=true;
        break;
      case "slide_up":
        newArgs.reverse=true;
        newArgs.enable=true;
        break;
      case "zoom_in":
        newArgs.reverse=false;
        newArgs.enable=true;
        break;
      case "zoom_out":
        newArgs.reverse=true;
        newArgs.enable=true;
        break;
      case "none":
        newArgs.reverse=false;
        newArgs.enable=false;
        break;
    }
    let effectList = JSON.parse(JSON.stringify(editorContext.screenEffectList));
    effectList[index].before = newArgs;
    if(index > 0){
      effectList[index-1].after = newArgs;
    }
    // console.log(`change effect index ${index}`)
    editorContext.setScreenEffectList(effectList);
  };

  useEffect(()=> {
    // console.log(screenEffect);
  }, [screenEffect]);

  return (
    
    <StyledFrame>
      {/* {editorPageContext.selectedItem?.type}
      {editorPageContext.selectedItem?.index}
      {transition} */}
      <Stack gap={1} direction="row" sx={{ flexWrap: 'wrap'}}>
        {
          screenEffectDataJson.map(effect=>{
            const selected = effect.name === screenEffect
            let disable=false
            if(screenEffectArgs?.aspect==="vertical" && effect.name.includes("zoom")){
              disable=true
            }else if(screenEffectArgs?.aspect==="horizontal" && effect.name.includes("slide")){
              disable=true
            }else if(screenEffectArgs?.aspect==="none"){
              disable=true
            }

            if(disable){
              return undefined
            }else{
              return(
                <SceneButton
                  // variant="outlined"
                  disabled={disable}
                  sx={{
                    wordBreak:"keep-all",
                    border:`${theme.border["border-01"]} solid`, 
                    borderColor: `${selected? `${theme.colors["border-active"]}`:`${theme.colors["border-secondary"]}`}`
                  }}
                  onClick={()=> {handleChangeEffect(effect)}}
                >
                  <Typography variant="body2" color={selected?"text.brand1":"text.secondary"}>
                    {effect.effect}
                  </Typography>
                </SceneButton>
              )
            }
          })
        }
      </Stack>
      {/* <SceneToggleButtons value={transition} onChange={handleChangeTransition}></SceneToggleButtons> */}
      {/* <Stack gap={1} direction="row" sx={{ flexWrap: 'wrap'}}>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            페이드인
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            페이드아웃
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')}>
          <Typography variant="body2" color="text.secondary">
            슬라이드<br/>라이트
          </Typography>
        </SceneButton>
        <SceneButton variant="outlined" onClick={()=> console.log('clicked')} >
          <Typography variant="body2" color="text.secondary">
            효과 이름
          </Typography>
        </SceneButton>
      </Stack> */}
    </StyledFrame>
  );
};
