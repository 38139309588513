import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { VideoTabContainer, SceneTransitionTabContainer, AIStyleTabContainer, ScreenEffectTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { EditorPageContext } from "../../editorPageContext";

const StyledEditingPanel = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  left: 1104px;
  height: 400px;
  min-height: 400px;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  position: absolute;
  top: 0px;
  width: 336px;
`;

export const EditingPanel = (): JSX.Element => {
  
  const [selectNum, setSelectNum] = useState(0);

  const handleSelect = (num: number) => {
    setSelectNum(num);
  }

  const editorPageContext = useContext(EditorPageContext)
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(undefined);
  useEffect(()=>{setSelectedSceneIndex(editorPageContext.selectedItem?.index)},[editorPageContext.selectedItem])

  return (
    <StyledEditingPanel>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum==0 &&<VideoTabContainer index={selectedSceneIndex}/>}
      {selectNum==1 &&<SceneTransitionTabContainer index={selectedSceneIndex}/>}
      {selectNum==2 &&<ScreenEffectTabContainer index={selectedSceneIndex}/>}
      {selectNum==3 &&<AIStyleTabContainer index={selectedSceneIndex}/>}
    </StyledEditingPanel>
  );
};
