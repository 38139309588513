import styled from "styled-components"
import Button from "../Button";

export const InputTextGroupStyle = styled.div`
  align-items: center;
  display:flex;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  position: relative;
  width: 100%;
  ${(props)=>props.theme.typography["pre-body-05"]};
`

export const InputStyle = styled.div`
  position: relative;
  display: flex;
  align-items:center;
  justify-contents: space-between;
  flex:1;
`
export const InputTextGroupButtonStyle = styled(Button)`
  all:unset;
  box-sizing: border-box;
  color:${(props)=>props.theme.colors["text-brand1"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  background-color: ${(props)=>props.theme.colors["button-brand2-subtle1"]};
  border-radius:${(props)=>props.theme.radius["radius-01"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-none"]} ${spacing["spacing-05"]} ${spacing["spacing-none"]} ${spacing["spacing-05"]}`
  }};
  position:absolute;
  cursor:pointer;
  right:8px;
`