import React, { useEffect, useState } from "react";
import styled from "styled-components";

// import { getTimeString } from "../../../../Utilities/index.js";

function getTimeString(time){
  if(time){
    const timeFloored = Math.floor(time)

    const hours = Math.floor(timeFloored / 3600);
    const mins = Math.floor(timeFloored / 60)%60;
    const seconds = timeFloored%60; 

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(mins).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }else{
    return '00:00:00'
  }
}

function PlayerTime(props){
  return(
    <PlayerTimeWrapperStyle>
      <PlayerTimeTextWrapperStyle>{getTimeString(props.current)}</PlayerTimeTextWrapperStyle>
      <PlayerTimeTextWrapperStyle>/</PlayerTimeTextWrapperStyle>
      <PlayerTimeTextWrapperStyle>{getTimeString(props.duration)}</PlayerTimeTextWrapperStyle>
    </PlayerTimeWrapperStyle>
  )
}

export default PlayerTime

const PlayerTimeWrapperStyle = styled.div`
  width: 200px;
  box-sizing:border-box;
  align-items:center;
  justify-content: flex-end;
  display: inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
`
const PlayerTimeTextWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-primary-inverse"]};
`