import React, { useContext, useEffect, useState } from "react";
import { Header } from "../../Components/pages/Editor/Header";
import { HeaderSub } from "../../Components/pages/Editor/HeaderSub";
import { Main } from "./Main";
import { ThemeProvider } from "../../theme/ThemeProvider";
import styled from "styled-components";

import fontDataJson from "../../Data/fontData.json"

import { WebGetFile, WebGetUrl, WebSave } from '../../Api/resource';
import { fileTypeFromBlob } from 'file-type';

import { EditorPageContext, SelectType } from "./editorPageContext";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../Contexts/ScenarioContext";

import textEffectData from "../../Data/textEffect.json"
import fontData from "../../Data/fontData.json"
import { EditorContext } from "../../Contexts/EditorContext";
import LoadingScreen from "../../Components/common/LoadingScreen";
import { ShopPointContext } from "../../Contexts/ShopPointContext";


const StyledElementEditor = styled.div`
  background-color: #f6f7fd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  ::-webkit-scrollbar{  
    width:0px;
  }
`;

const StyledHeaderWrapper = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  height: 800px;
  position: relative;
  width: 1440px;
`;

const Editor = (props): JSX.Element => {

  const [selectType, setSelectType] = useState<SelectType>(undefined)
  const [selectIndex, setSelectIndex] = useState<number|undefined>(undefined)
  const [selectedBgmInfo, setSelectedBgmInfo] = useState(undefined)
  const [bgmUrlList, setBgmUrlList] = useState([]);
  const [bgmBlobs, setBgmBlobs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [ntrActorList, setNtrActorList] = useState([]);
  const [ntrUrlList, setNtrUrlList]=useState([])
  const [textVideoUrlList, setTextVideoUrlList] = useState([])

  const editorPageContextValue = {
    selectType: selectType,
    selectIndex: selectIndex,
    selectedBgmInfo:selectedBgmInfo,
    bgmUrlList:bgmUrlList,
    bgmBlobs:bgmBlobs,
    selectedItem:selectedItem,
    ntrActorList:ntrActorList,
    ntrUrlList:ntrUrlList,
    textVideoUrlList:textVideoUrlList,

    setSelectType: (newType)=>{setSelectType(newType)},
    setSelectIndex: (newIndex)=>{setSelectIndex(newIndex)},
    setSelectedBgmInfo: (newInfo)=>{setSelectedBgmInfo(newInfo)},
    setBgmUrlList: (newList)=>{setBgmUrlList(newList)},
    setBgmBlobs: (newBlobs)=>{setBgmBlobs(newBlobs)},
    setSelectedItem:(newItem)=>{setSelectedItem(newItem)},
    setNtrActorList:(newList)=>{setNtrActorList(newList)},
    setNtrUrlList:(newList)=>{setNtrUrlList(newList)},
    setTextVideoUrlList:(newList)=>{setTextVideoUrlList(newList)}
  }

  const scenarioContext = useContext(ScenarioContext)
  const editorContext = useContext(EditorContext);

  const [isLoadingData, setIsLoadingData] = useState([false,false,false,false]);
  const [isSavingData, setIsSavingData] = useState(false);

  useEffect(()=>{
    getBgmUrl();
    getBgmBlobs();
    getNtrUrl();
    getTextVideo();
  },[])
  
  async function getBgmUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetUrl({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(response.result===0){
        newList.push(response.presignedUrl);
      }else{
        console.error(`failed get bgm url index_${i}`);
        newList.push("")
      }
    }
    setBgmUrlList(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=false;
      return newState;
    })
  }
  async function getBgmBlobs(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetFile({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(!response.result){
        newList.push(response);
      }else{
        console.error(`failed get bgm blob index_${i}`);
        newList.push(undefined)
      }
    }
    setBgmBlobs(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=false;
      return newState;
    })
  }

  async function getNtrUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      // console.log("tts path ::",scenarioContext.finalScenario.scenes[i].narrationInfo.path)
      const path = scenarioContext.finalScenario.scenes[i].narrationInfo.path;
      // const fileName = `adv_api_server/${sessionStorage.getItem("ASSET_ID")}/tts/scene`+("00"+i.toString()).slice(-2)+".mp3"
      if(path && path!==""){
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: path
        })
        if(response.result===0){
          newList.push(response.presignedUrl);
        }else{
          console.error(`failed get bgm url index_${i}`);
          newList.push("")
        }
      }
    }
    
    setNtrUrlList(newList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=false;
      return newState;
    })
  }

  async function getTextVideo(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=true;
      return newState;
    })
    function matchTextEffect(target){
      // console.log("target :: ",target)
      const index = textEffectData.findIndex(item=>item.effect===target)
      if(index<0){
        return 'none'
      }else{
        return textEffectData[index].name
      }
    }
    function matchFont(target){
      // console.log("font target :: ", target);
      const index = fontData.findIndex(font=>font.fontName===target)
      if(index>=0){
        return fontData[index]["fontFile"]
      }else{
        return fontData[0]["fontFile"]
      }
    }
  
    const textUrlList = []
    for(let sceneIndex=0; sceneIndex<scenarioContext.finalScenario.scenes.length;sceneIndex++){
      const sceneData = scenarioContext.finalScenario.scenes[sceneIndex]
      const shadow = editorContext.sceneTextShadowEffectList[sceneIndex]
      const request = {
        "assetId":sessionStorage.getItem("ASSET_ID"),
        "text": sceneData.textList[0].text,
        "fontSize": sceneData.textList[0].height,
        "textWidth":450,
        "fontFile": matchFont(sceneData.textList[0].font),
        "fontColor": sceneData.textList[0].fontColor,
        "hasShadow": sceneData.fileName==="search"?false:true,
        "posX": sceneData.textList[0].posX,
        "posY": sceneData.textList[0].posY,
        "duration": sceneData.time,
        "rotate":0,
        "textEffect": matchTextEffect(sceneData.textList[0].effect),
        "sceneNo": sceneIndex,
        "shadowInfo":shadow
      }
      // console.log("request :: ", request)
      
      const response = await fetch("https://aiad-mm-backend.pixtree.net:8080/render/text",{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(request)
      })
  
      if(response.status===200){
        const blob = await response.blob()
        const url = await URL.createObjectURL(blob);
        textUrlList.push(url);
      }else{
        textUrlList.push('');
      }
    }
    setTextVideoUrlList(textUrlList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=false;
      return newState;
    })
  }

  const onClickButton=()=>{
    // setClipData();
  }


  return (
    <>
    <EditorPageContext.Provider value={editorPageContextValue}>
      {
        isLoadingData.every(value=>value===false)?
        <ThemeProvider>
          <StyledElementEditor className="element-editor">
            <StyledHeaderWrapper>
              <Header step={props.step} setStep={props.setStep} />
              <HeaderSub />
              <Main />
            </StyledHeaderWrapper>
          </StyledElementEditor>
        </ThemeProvider>
        :
        <LoadingScreen text="정보를 불러오는 중입니다"/>
      }
    </EditorPageContext.Provider>
    </>
  );
};

export default Editor;
