import React, { useContext, useEffect, useState, useRef } from "react";
import './App.css';

import ShopInfo from './Pages/ShopInfo';
import ShopPoint from './Pages/ShopPoint';
import CreateScenario from './Pages/CreateScenario';
import EditVideo from './Pages/EditVideo';
import Aside from "./Components/Aside";
import Editor from "./Pages/Editor";

import { ShopInfoContext } from './Contexts/ShopInfoContext';
import { CheckListInterface, ShopPointContext, FontFeature } from "./Contexts/ShopPointContext";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import theme from "./styles/theme";

import { fileInfo } from "./Contexts/ShopInfoContext";
import { BGMInfo, GeneratedImageInterface, ScenarioContext } from "./Contexts/ScenarioContext";

import { useCookies } from "react-cookie"

import TutorialModal from "./Components/common/TutorialModal";
import AlertModal from "./Components/common/AlertModal";
import { ReactComponent as CloseIcon } from "./Assets/ModalIllu/Close.svg"
import { EditorContext } from "./Contexts/EditorContext";

import fontDataJson from "./Data/fontData.json"







function formatting(value){
  return ('00'+value.toString()).slice(-2);      
}

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard Variable';
    src: url('./Assets/Fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('./Assets/Fonts/Inter-Medium.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }   
`;
export const isLogging = process.env.REACT_APP_SERVER_TYPE?(process.env.REACT_APP_SERVER_TYPE==="STG"||process.env.REACT_APP_SERVER_TYPE==="DEV"):process.env.REACT_APP_BTV_TOWN_CALLBACK_URL.includes("stage")

const StyledViewport = styled.div`
  width:100%;
  height:100%;
`

function getUUID(){    
  ///////////// todo ////////////////
  //
  //    iframe 에서 직접 받아오기    
  //
  //////////////////////////////////
  const currentTime = new Date();

  const YY = formatting(currentTime.getFullYear());
  const MM = formatting(currentTime.getMonth()+1);
  const DD = formatting(currentTime.getDate());
  const hh = formatting(currentTime.getHours());
  const mm = formatting(currentTime.getMinutes());
  const ss = formatting(currentTime.getSeconds());
  
  return `test-${YY}${MM}${DD}-${hh}${mm}${ss}`
}

function getiFramePostData(event){
  if(process.env.REACT_APP_SERVER_TYPE != "DEV"){
    console.log('Message origin:', event.origin);
    const data = event.data;
    console.log("received data ::: ",data)  
  
    if (window.self !== window.top) {
      // console.log("이 페이지는 iframe 안에 있습니다.");
      if( event.origin.includes("btvtownad") || event.origin.includes("localhost") ){
        sessionStorage.clear();
        if(!event.data.userName || !event.data.assetId || !event.data.secretKey){
          alert("정보 취득에 실패했습니다.")
          window.parent.location.replace(`https://${process.env.REACT_APP_BTV_TOWN_CALLBACK_URL}`);
        }
    
        if(event.data.userName){sessionStorage.setItem("USER_NAME", event.data.userName)}
        else{sessionStorage.setItem("USER_NAME", "테스트_유저")}
      
        if(event.data.assetId){sessionStorage.setItem("ASSET_ID", event.data.assetId)}
        else{
          const tempAssetId = getUUID();
          sessionStorage.setItem("ASSET_ID", tempAssetId)
        }
        
        if(event.data.secretKey){sessionStorage.setItem("SECRET_KEY", event.data.secretKey)}
        else{sessionStorage.setItem("SECRET_KEY", "dev")}
      
  
        if(isLogging){
          console.log("received assetId ::: ", data.assetId)
          console.log("received name ::: ", data.userName)
          console.log("received secretKey ::: ", data.secretKey)
        }       
        
        window.removeEventListener('message',getiFramePostData);
      } 
    } else {
      // console.log("이 페이지는 iframe 안에 있지 않습니다.");
      // alert("비정상 접근입니다.")
      // window.history.go(-1);
    }  
  }
}

window.addEventListener('message', getiFramePostData);

function appLoadFunc(){
  console.log("app component loaded ")
  const name = sessionStorage.getItem("USER_NAME");
  const id = sessionStorage.getItem("ASSET_ID")
  const key = sessionStorage.getItem("SECRET_KEY")
  if((name&&name!=="")&&(id&&id!=="")&&(key&&key!=="")){
    console.log(`setting data  ${name} / ${id} / ${key}`)
    window.dispatchEvent(new Event('updateUserData'));
  }
}
window.addEventListener("appLoaded", appLoadFunc)


function App() {
  const [step, setStep] = useState(1);

  const [cookies, setCookie] = useCookies(["enableTutorial"]);

  const [showTutorial, setShowTutorial] = useState(false);
  const closeTutorial=()=>{setShowTutorial(false)};

  const invalidIcon = <CloseIcon/>
  const [isInvalidApproach, setIsInvalidApproach] = useState(false);
  const handleRedirect = () => {
    window.parent.location.replace(`https://${process.env.REACT_APP_BTV_TOWN_CALLBACK_URL}`);
  }


  const intervalRef = useRef([])

  const handleUserUpdate = ()=>{
    const name = sessionStorage.getItem("USER_NAME");
    const id = sessionStorage.getItem("ASSET_ID")
    const key = sessionStorage.getItem("SECRET_KEY")
    console.log(`user data update listener ${name} / ${id} / ${key}`);

    if((name&&name!=="")&&(id&&id!=="")&&(key&&key!=="")){
      console.log("user data updated")
      setUserName(prev=>sessionStorage.getItem("USER_NAME"));
      setHasAssetId(prev=>true);

      intervalRef.current.forEach((id)=> clearInterval(id));
      window.removeEventListener("appLoaded", appLoadFunc);
      window.removeEventListener("updateUserData", handleUserUpdate);
    }    
  }
  window.addEventListener('updateUserData', handleUserUpdate);

  
  useEffect(()=>{
    if (window.self !== window.top) {
      // iframe 안에 들어 있는 경우
      if(process.env.REACT_APP_SERVER_TYPE!="DEV"){
        // DEV가 아닌 경우 postmessage 수신
        // window.addEventListener('message', getiFramePostData);
        const interval = setInterval(() => {
          window.dispatchEvent(new Event('appLoaded'));          
        }, 100);
        intervalRef.current.push(interval);

      }
    }else{
      // 직접진입인 경우
      // DEV가 아니면 접속제한
      if(process.env.REACT_APP_SERVER_TYPE!="DEV"){
        setIsInvalidApproach(true)
      }
      if(process.env.REACT_APP_SERVER_TYPE==="DEV"){
        setDevAssetValues();
      }  
    }

    if(isLogging){
      console.group("ENV")
      console.log("API_SERVER_HOST           : ", process.env.REACT_APP_API_SERVER_HOST)
      console.log("BGM_SECRET_KEY            : ", process.env.REACT_APP_BGM_SECRET_KEY)
      console.log("BGM_USER_ID               : ", process.env.REACT_APP_BGM_USER_ID)
      console.log("BTV_TOWN_API_URL          : ", process.env.REACT_APP_BTV_TOWN_API_URL)
      console.log("BTV_TOWN_CALLBACK_URL     : ", process.env.REACT_APP_BTV_TOWN_CALLBACK_URL)
      console.log("BTV_TOWN_MY_ADV_URL       : ", process.env.REACT_APP_BTV_TOWN_MY_ADV_URL)
      console.log("MEDIA_BACKEND_SERVER_HOST : ", process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST)
      console.log("NTR_TOKEN                 : ", process.env.REACT_APP_NTR_TOKEN)
      console.log("INPUT_BUCKET_NAME         : ", process.env.REACT_APP_INPUT_BUCKET_NAME)
      console.log("OUTPUT_BUCKET_NAME        : ", process.env.REACT_APP_OUTPUT_BUCKET_NAME)
      console.log("SERVER_TYPE               : ", process.env.REACT_APP_SERVER_TYPE)
      console.groupEnd();
    }

    loadFont();

    return()=>{
      window.removeEventListener("message", getiFramePostData)
      sessionStorage.clear();
    }
  },[])

  async function loadFont(){
    console.group(`font text`)
    for(let i=0; i<fontDataJson.length; i++){
      const width = getTextWidth("가나다라123", `10px ${fontDataJson[i]['font-family']}`);
    }
    console.groupEnd();
  }


  function setDevAssetValues(){
    try{
      sessionStorage.clear();
      console.log("SETTING DEV values...");
      const name = `테스트_QA`
      const assetId = getUUID();
      const secretKey = "dev"
      
      console.log("values :: ", name, assetId, secretKey);
      sessionStorage.setItem("USER_NAME", name);
      sessionStorage.setItem("ASSET_ID", assetId);
      sessionStorage.setItem("SECRET_KEY", secretKey);

      setUserName(name);

      console.log(`session items :: ${sessionStorage.getItem("USER_NAME")} / ${sessionStorage.getItem("ASSET_ID")} / ${sessionStorage.getItem("SECRET_KEY")}`)
      setHasAssetId(true);
    }catch(err){
      console.error("setting asset error :: ", err)
    }
  } 

  useEffect(()=>{
    if(isLogging){
      console.log("cookies", cookies);
    }
    if(cookies){
      //// todo :: 디자인 전달되면 튜토리얼 팝업 띄우기

      // console.log("tutorial cookie", cookies.enableTutorial);
      // const enableTutorial = cookies.enableTutorial;
      // if(enableTutorial===undefined){
      //   console.log("no tutorial coookie")
      //   setShowTutorial(true)
      // }else if(enableTutorial === true){
      //   console.log("show tutorial")
      //   setShowTutorial(true)
      // }else if(enableTutorial === false){
      //   console.log("hide tutorial")
      //   setShowTutorial(false)
      // }
    }
  },[cookies])
  
  const [userName, setUserName] = useState<string>("")

  /////////////////// 매장 정보 state

  const shopInfoContext = useContext(ShopInfoContext);
  const [shopName, setShopName] = useState<string>("");
  const [shopAddress, setShopAddress] = useState<string>("");
  const [shopCall, setShopCall] = useState<string[]>(['','','']);
  const [shopCategory, setShopCategory] = useState<string>("");
  const [adTime, setAdTime] = useState<number>(15);
  const [includeAiImage, setIncludeAiImage] = useState<boolean>(false);
  const [files, setFiles] = useState<fileInfo[]>([]);
  const [editedFiles, setEditedFiles] =useState<fileInfo[]>([]);
  const [aiImages, setAiImages] = useState<string[]>([]);
  const [completedStep, setCompletedStep] = useState<number>(0);

  const [imageAnalysisInfo, setImageAnalysisInfo] = useState(undefined);

  const [isGenerating, setIsGenerating] = useState(false);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasAssetId, setHasAssetId] = useState(false);


  const shopInfoContextValue = {
    completedStep:completedStep,
    shopName:shopName,
    shopAddress:shopAddress,
    shopCall:shopCall,
    shopCategory:shopCategory,
    adTime:adTime,
    includeAiImage:includeAiImage,
    files:files,
    editedFiles:editedFiles,
    aiImages:aiImages,
    imageAnalysisInfo:imageAnalysisInfo,
    isGenerating:isGenerating,
    hasLoaded:hasLoaded,
    hasAssetId:hasAssetId,

    setCompletedStep:(newStep:number)=>{setCompletedStep(newStep)},
    setShopName:(newName:string)=>{setShopName(newName)},
    setShopAddress:(newAddress:string)=>{setShopAddress(newAddress)},
    setShopCall:(newCall:string[])=>{setShopCall(newCall)},
    setShopCategory:(newCategory:string)=>{setShopCategory(newCategory)},
    setAdTime:(newTime:number)=>{setAdTime(newTime)},
    setIncludeAiImage:(include:boolean)=>{setIncludeAiImage(include)},

    addFile:(newFileInfo:fileInfo[])=>{
      setFiles([...files, ...newFileInfo]);
      setEditedFiles([...editedFiles, ...newFileInfo])
    },
    setEditedFiles:(newEditedFiles:fileInfo[])=>{
      setEditedFiles([...newEditedFiles])
    },
    removeFile:(targetFileInfo:fileInfo)=>{
      const idx = files.findIndex(fileInfo=>{
        if(targetFileInfo.file){
          return fileInfo.file.name===targetFileInfo.file.name
        }else{
          return fileInfo.s3Key === targetFileInfo.s3Key
        }
      });
      setFiles([...files.slice(0,idx), ...files.slice(idx+1,files.length)])
      setEditedFiles([...editedFiles.slice(0,idx), ...editedFiles.slice(idx+1,files.length)])
    },
    changeFile:(originalFile:fileInfo, changeFile:fileInfo)=>{
      const idx = files.findIndex(fileInfo=>fileInfo.file.name===originalFile.file.name);
      setFiles([...files.slice(0,idx), changeFile,...files.slice(idx+1,files.length)])
    },
    changeEditedFile:(originalFile:fileInfo, changeFile:fileInfo)=>{
      const idx = editedFiles.findIndex(fileInfo=>fileInfo.file.name===originalFile.file.name);
      setEditedFiles([...editedFiles.slice(0,idx), changeFile,...editedFiles.slice(idx+1,files.length)])
    },
    setAiImages:(newUrlList:string[])=>{
      setAiImages(newUrlList)
    },
    setImageAnalysisInfo:(newInfo:any)=>{
      setImageAnalysisInfo(newInfo);
    },
    setIsGenerating:(newVal:boolean)=>{setIsGenerating(newVal)},
    setHasLoaded:(newBoolean:boolean)=>{setHasLoaded(newBoolean)},
    setHasAssetId:(newBoolean:boolean)=>{setHasAssetId(newBoolean)}
  }




  ///////// 매장 특징 state

  const shopPointContext = useContext(ShopPointContext)
  const [shopPros, setShopPros] = useState<CheckListInterface[]>([])
  const [shopTargets, setShopTargets] = useState<CheckListInterface[]>([])
  const [customRequirement, setCustomRequirement] = useState<string>("")
  const [requiredTextInfo, setRequiredTextInfo] = useState<string[]>([]);
  const [customFeatureList, setCustomFeatureList] = useState<string[]>([])
  const [customCustomerList, setCustomCustomerList] = useState<string[]>([]);
  const [customMoodList, setCustomMoodList] = useState<string[]>([]);
  const [fontFeature,setFontFeature] = useState<FontFeature>({fontFeatureList:[],fontColor:""})
  const [bgmFeature, setBgmFeature] = useState<string[]>([])
  const [narrationCharacterFeature, setNarrationCharacterFeature] = useState<string[]>([])

  const shopPointContextValue = {
    prosList: shopPros,
    targetList: shopTargets,
    customRequirement: customRequirement,
    requiredTextInfo:requiredTextInfo,
    customFeatureList:customFeatureList,
    customCustomerList:customCustomerList,
    customMoodList:customMoodList,
    fontFeature:fontFeature,
    bgmFeature:bgmFeature,
    narrationCharacterFeature:narrationCharacterFeature,

    setProsList:(newList:CheckListInterface[])=>{
      setShopPros(newList)
    },
    setTargetList:(newList:CheckListInterface[])=>{
      setShopTargets(newList)
    },
    setCustomRequirement:(newText:string)=>{
      setCustomRequirement(newText);
    },
    setRequiredTextInfo:(newTextInfo:any)=>{
      setRequiredTextInfo(newTextInfo);
    },
    setCustomFeatureList:(newList:string[])=>{
      setCustomFeatureList(newList)
    },
    setCustomCustomerList:(newList:string[])=>{
      setCustomCustomerList(newList);
    },
    setCustomMoodList:(newList:string[])=>{
      setCustomMoodList(newList)
    },
    setFontFeature:(newFeature:FontFeature)=>{
      setFontFeature(newFeature)
    },
    setBgmFeature:(newFeature:string[])=>{
      setBgmFeature(newFeature)
    },
    setNarrationCharacterFeature:(newFeature:string[])=>{
      setNarrationCharacterFeature(newFeature)
    }
  }

  ////////// 시나리오 state /////////
  
  const [scenario, setScenario] = useState([]);
  const [scene, setScene] = useState(undefined);
  const [finalScenario, setFinalScenario] = useState(undefined)
  const [selectedScenario, setSelectedScenario] = useState(undefined)
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [useLastEnding, setUseLastEnding] = useState(false);
  const [generatedImages, setGeneratedImages] = useState<GeneratedImageInterface[]>([]);
  const [generatedVideos, setGeneratedVideos] = useState<GeneratedImageInterface[]>([]);
  const [BGMInfo, setBGMInfo] = useState<BGMInfo[]>([]);
  const [selectedBgmIndex, setSelectedBgmIndex] = useState<number>(0);
  const [selectedTTSActorInfo, setSelectedTTSActorInfo] = useState(undefined);
  const [editedTTSActorList, setEditedTTSActorList] = useState([]);


  const scenarioContextValue = {
    finalScenario: finalScenario,
    scenario: scenario,
    scene: scene,
    selectedScenario: selectedScenario,
    selectedTemplate:selectedTemplate,
    useLastEnding: useLastEnding,
    generatedImages:generatedImages,
    generatedVideos:generatedVideos,
    // useTemplate:useTemplate,
    BGMInfo:BGMInfo,
    selectedBgmIndex:selectedBgmIndex,
    selectedTTSActorInfo:selectedTTSActorInfo,
    editedTTSActorList:editedTTSActorList,

    setFinalScenario: (newFinal)=>{setFinalScenario(newFinal)},
    setScenario: (newList)=>{ setScenario(newList)},
    setScene: (newScenarioResult)=>{ setScene(newScenarioResult)},
    setSelectedScenario: (newItem)=>{setSelectedScenario(newItem)},
    setSelectedTemplate: (newIndex)=>{setSelectedTemplate(newIndex)},
    setUseLastEnding: (newVal)=>{setUseLastEnding(newVal)},
    setGeneratedImages:(newImages)=>{setGeneratedImages(newImages)},
    setGeneratedVideos:(newVideos)=>{setGeneratedVideos(newVideos)},
    // setUseTemplate:(newVal)=>{setUseTemplate(newVal)},
    setBGMInfo:(newInfo)=>{setBGMInfo(newInfo)},
    setSelectedBgmIndex:(newIndex)=>{setSelectedBgmIndex(newIndex)},
    setSelectedTTSActorInfo:(newInfo)=>{setSelectedTTSActorInfo(newInfo)},
    setEditedTTSActorList:(newList)=>{setEditedTTSActorList(newList)},
  }



  //////// 에디터 state /////
  const [videoCreated, setVideoCreated] = useState(false);
  const [outputVideoUrl, setOutputVideoUrl] = useState(undefined);
  const [outputPresignedUrl, setOutputPresignedUrl] = useState(undefined);
  const [outputVideoKey, setOutputVideoKey] = useState(undefined);
  const [outputVideoBucket, setOutputVideoBucket] = useState(undefined);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [screenEffectList, setScreenEffectList] = useState([]);
  const [sceneTextShadowEffectList, setSceneTextShadowEffectList] = useState([])
  const [ttsVolumeList, setTtsVolumeList] = useState([]);
  const [bgmVolume, setBgmVolume]= useState<number>(100)

  const editorContextValue = {
    videoCreated:videoCreated,
    outputVideoUrl: outputVideoUrl,
    outputPresignedUrl: outputPresignedUrl,
    outputVideoKey:outputVideoKey,
    outputVideoBucket:outputVideoBucket,
    isEdited:isEdited,
    screenEffectList:screenEffectList,
    sceneTextShadowEffectList:sceneTextShadowEffectList,
    ttsVolumeList:ttsVolumeList,
    bgmVolume:bgmVolume,

    setVideoCreated:(created)=>{setVideoCreated(created)},
    setOutputVideoUrl:(newUrl)=>{setOutputVideoUrl(newUrl)},
    setOutputPresignedUrl:(newPresigned)=>{setOutputPresignedUrl(newPresigned)},
    setOutputVideoKey:(newKey)=>{setOutputVideoKey(newKey)},
    setOutputVideoBucket:(newBucket)=>{setOutputVideoBucket(newBucket)},
    setIsEdited:(newVal)=>{setIsEdited(newVal)},
    setScreenEffectList:(newList)=>{setScreenEffectList(newList)},
    setSceneTextShadowEffectList:(newList)=>{setSceneTextShadowEffectList(newList)},
    setTtsVolumeList:(newList)=>{setTtsVolumeList(newList)},
    setBgmVolume:(newVal)=>{setBgmVolume(newVal)}
  }

  return (
    <ShopInfoContext.Provider value={shopInfoContextValue}>
      <ShopPointContext.Provider value={shopPointContextValue}>
        <ScenarioContext.Provider value={scenarioContextValue}>
          <EditorContext.Provider value={editorContextValue}>
            <ThemeProvider theme={theme}>
              <StyledViewport>
                <BrowserRouter>
                  <StyledPage>
                    <Aside name={userName} step={step} setStep={setStep} />
                    {showTutorial&&<TutorialModal show={showTutorial} onClose={closeTutorial}/>}
                    <Routes>
                      {/* <Route path={"/"} element={<MasherPage/>} ></Route> */}
                      <Route path={"/"} element={<ShopInfo step={step} setStep={setStep}/>} ></Route>
                      <Route path={"/point"} element={<ShopPoint step={step} setStep={setStep}/>} ></Route>
                      <Route path={"/scenario"} element={<CreateScenario step={step} setStep={setStep}/>} ></Route>
                      <Route path={"/edit"} element={<EditVideo step={step} setStep={setStep}/>} ></Route>
                      <Route path={"/editor"} element={<Editor step={step} setStep={setStep}/>} ></Route>
                      {/*<--  기본경로  -->*/}
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                    <AlertModal
                      icon={invalidIcon}
                      description={"잘못된 접속 경로입니다"}
                      show={isInvalidApproach}
                      onConfirm={handleRedirect}
                      onlyConfirm
                    />   
                  </StyledPage>
                </BrowserRouter>
              </StyledViewport>
            </ThemeProvider>
          </EditorContext.Provider>
        </ScenarioContext.Provider>
      </ShopPointContext.Provider>
    </ShopInfoContext.Provider>
  );
}

export default App;

const StyledPage = styled.div`
  background-color: ${(props)=>props.theme.colors["background-tertiary"]};
  display:flex;
  height:100vh;
  width:100vw;
  box-sizing: border-box;

  `
 