import styled from "styled-components";

export const SelectGroupStyle = styled.div`
  display:flex;
  flex-direction:column;
  box-sizing:border-box;
  cursor:pointer;
  flex:1;

  ${(props)=>  props.type==="capsule"? props.theme.typography["pre-body-06"] : props.theme.typography["pre-body-02"]};
  position: relative;
`

export const SelectStyle = styled.div`
  align-items: center;
  display: flex;
  height:50px;
  position: relative;  
  ${(props)=> matchBorder(props.state, props.theme)};
  ${(props)=> matchColor(props.state, props.theme)};
  ${(props)=> matchTypeSelect(props.type, props.theme)};
 

  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]}`
  }};
`
function matchTypeSelect(type, theme){
  switch(type){
    case "capsule":
      return{
        borderRadius: `${theme.radius["radius-full"]}`,
        backgroundColor:`${theme.colors["background-secondary"]}`,
        gap: `${theme.spacing["spacing-02"]}`
      }
    default : 
      return{
        borderRadius: `${theme.radius["radius-02"]}`,
        backgroundColor:`none`,
        gap: `${theme.spacing["spacing-04"]}`
      }
  }

}

function matchBorder(state, theme){
  switch(state){
    case "default":
      return{
        border: `${theme.border["border-02"]} solid ${theme.colors["border-primary"]}`
      }
    case "filled":
      return{
        border: `${theme.border["border-02"]} solid ${theme.colors["border-primary"]}`
      }
    case "active":
      return{
        border: `${theme.border["border-02"]} solid ${theme.colors["border-active"]}`
      }
    case "invalid":
      return{
        border: `${theme.border["border-03"]} solid ${theme.colors["service-error"]}`
      }
    case "disabled":
      return{
        border: `${theme.border["border-02"]} solid ${theme.colors["border-primary"]}`
      }
    default:
      return{
        border: `${theme.border["border-02"]} solid ${theme.colors["border-primary"]}`
      }
  }
}
function matchColor(state, theme){
  switch(state){
    case "default":
      return{
        color: `${theme.colors["text-quaternary"]}`
      }
    case "filled":
      return{
        color: `${theme.colors["text-primary"]}`
      }
    case "active":
      return{
        color: `${theme.colors["text-primary"]}`
      }
    case "invalid":
      return{
        color: `${theme.colors["text-primary"]}`
      }
    case "disabled":
      return{
        color: `${theme.colors["text-quaternary"]}`
      }
    default:
      return{
        color: `${theme.colors["text-quaternary"]}`
      }
  }
}
export const SelectPlaceholderStyle= styled.div`
  flex:1;
`

export const SelectListUnitStyle = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  height: 40px;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-08"]} ${spacing["spacing-none"]} ${spacing["spacing-08"]}`
  }};

  ${(props)=>  props.type==="capsule"? props.theme.typography["pre-body-06"] : props.theme.typography["pre-body-02"]};
  
  width:100%;
  white-space: nowrap;
  position: relative;

  &:hover{
    background-color: ${(props)=>props.theme.colors["background-primary-hover"]}
  }
`

export const SelectListStyle = styled.div`
  align-items: flex-start;
  background-color:${(props)=>props.theme.colors["background-primary"]};
  border:${(props)=>`${props.theme.border["border-02"]} solid ${props.theme.colors["border-secondary"]}`};
  
  display:flex;
  flex-direction: column;
  max-height: 256px;
  overflow: hidden;
  overflow-y: scroll;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-02"]} ${spacing["spacing-none"]} ${spacing["spacing-02"]} ${spacing["spacing-none"]}`
  }};
  box-shadow: ${(props)=>props.theme.shadow['modal']};

  ${(props)=> matchTypeSelectList(props.type, props.theme)};
  ${(props)=> props.type==="capsule"? props.theme.typography["pre-body-06"] : props.theme.typography["pre-body-02"]};

  width:100%;
  box-sizing:border-box;
  z-index:1;
  
  position: absolute;
`
function matchTypeSelectList(type, theme){
  switch(type){
    case "capsule":
      return{
        borderRadius: `${theme.radius["radius-05"]}`,
        gap: `${theme.spacing["spacing-02"]}`,
        top: `calc(52px + ${theme.spacing['spacing-02']})`
      }
    default : 
      return{
        borderRadius: `${theme.radius["radius-02"]}`,
        gap: `${theme.spacing["spacing-04"]}`,
        top: '52px'
      }
  }
}

