import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { IconListItem } from "../../../../../../../Components/pages/Editor/IconListItem";

import { ColorPicker } from '../../../../../../../Components/pages/Editor/ColorPicker';

import textEffectData from '../../../../../../../Data/textEffect.json'
import fontData from '../../../../../../../Data/fontData.json'
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../../../editorPageContext";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";


const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const StyledFilterBox = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  border: 1px solid;
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-radius: var(--tokens-radius-none-duplicate);
  height: 72px;
  position: relative;
  width: 128px;
`
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const EffectTabContent = ({index}): JSX.Element => {
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [blur, setBlur] = useState(0);
  const [degree, setDegree] = useState(0);
  const [rotation, setRotation] = useState('left');
  const [opacity, setOpacity] = useState(0);
  const [color, setColor] = useState("#ffffff")


  const handleChangeX = (event: Event, newValue: number | number[]) => {
    setSizeX(newValue as number);
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    setSizeY(newValue as number);
  };

  // const handleChangeOpacity = (event: Event, newValue: number | number[]) => {
  //   setOpacity(newValue as number);
  // };

  const handleChangeColor = (value) => {
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].color = value;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setColor(value);
    // editorPageContext.selectedItem.clip.setContentValue("color", value);
  }

  function matchTextEffect(target){
    // console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffectData[index].name
    }
  }
  function matchFont(target){
    // console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }

  const [prevUrl, setPrevUrl] = useState(undefined)
  const handleCommand = async (name:string) => {
    // console.log(`Text Effect: ${name}`);
    const sceneData = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]
    const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
    const request = {
      "assetId":sessionStorage.getItem("ASSET_ID"),
      "text": sceneData.textList[0].text,
      "fontSize": sceneData.textList[0].height,
      "textWidth":450,
      "fontFile": matchFont(sceneData.textList[0].font),
      "fontColor": sceneData.textList[0].fontColor,
      "hasShadow": sceneData.fileName==="search"?false:true,
      "posX": sceneData.textList[0].posX,
      "posY": sceneData.textList[0].posY,
      "duration": sceneData.time,
      "rotate":0,
      "textEffect": matchTextEffect(sceneData.textList[0].effect),
      "sceneNo": editorPageContext.selectIndex,
      "shadowInfo":shadow
    }
    // console.log("request :: ", request)
    
    const response = await fetch("https://aiad-mm-backend.pixtree.net:8080/render/text",{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify(request)
    })

    if(response.status===200){
      const blob = await response.blob()
      const url = URL.createObjectURL(blob);
      setPrevUrl(url);
      let urlList = JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
      urlList[editorPageContext.selectIndex]=url;
      editorPageContext.setTextVideoUrlList(urlList)
    }
  };

  // useEffect(()=> {
  //   console.log(rotation);
  // }, [rotation]);

  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);
  
  const [effect, setEffect]=useState(undefined);
  useEffect(()=>{
    // console.log("Select index :: ", editorPageContext.selectIndex)
    if(editorPageContext.selectIndex!=undefined){
      // console.log("index :: ", editorPageContext.selectIndex)
      // console.log("final scenario ::: ",scenarioContext.finalScenario)
      // console.log("scene :: ", scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex])
      const effect = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex].textList[0].effect
      setEffect(effect);
    }
  },[editorPageContext.selectIndex, scenarioContext.finalScenario])

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      // console.log("ShadowList :: ", editorContext.sceneTextShadowEffectList)
      // console.log("Shadow :: ", editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex])
  
      const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
  
      setPositionX(shadow.posX);
      setPositionY(shadow.posY);
      setBlur(shadow.blur);
      setColor(shadow.color);
      setOpacity(shadow.opacity*100);
    }
  },[editorPageContext.selectIndex])

  useEffect(()=>{
    // console.log("pos x changed :: ", positionX)
  },[positionX])

  const handleChangePosX = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].posX = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setPositionX(val)
  }
  const handleChangePosY = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].posY = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setPositionY(val)
  }
  const handleChangeOpacity = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].opacity = val/100;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setOpacity(val)
  }
  const handleChangeBlur = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].blur = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setBlur(val)
  }

  return (
    <StyledFrame>
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>애니메이션</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
          <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {
              effect && 
              <IconListItem Icon={null} name={effect} onCommand={handleCommand}/>  
            }
            {/* <IconListItem Icon={null} name="출렁이는" onCommand={handleCommand}/>   */}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>그림자</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
          <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <LabeledInputStepper value={positionX} text="X" width={140} onChange={(val:number)=>handleChangePosX(val)}/>
            <LabeledInputStepper value={positionY} text="Y" width={140} onChange={(val:number)=>handleChangePosY(val)}/>
          </Box>
          <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <LabeledInputStepper step={0.1} value={blur} text="흐림효과" width={140} onChange={(val:number)=>handleChangeBlur(val)}/>
            {/* <LabeledInputStepper value={positionY} text="퍼짐효과" width={140} onChange={(val:number)=>setPositionY(val)}/> */}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <ColorPicker value={color} onChange={handleChangeColor}/>
            <LabeledInputStepper value={opacity} text="불투명도" width={140} unitText="%" onChange={(val:number)=>handleChangeOpacity(val)}/>
          </Box>    
        </AccordionDetails>
      </Accordion>
    </StyledFrame>
  );
};