import React, { useState, useEffect } from 'react';
import DaumPostcodeEmbed, {useDaumPostcodePopup} from 'react-daum-postcode';
import "./DaumPostcode.css"
import theme from '../../../../styles/theme'
import Modal from '../../../common/Modal';
import InputText from '../../../common/InputText';
import Button from '../../../common/Button';
import styled from 'styled-components';

function PostcodePopup(props:any) {
  const open = useDaumPostcodePopup("//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const handleComplete = (data:any) => {
    let fullAddress = data.address;
    console.log("주소 데이터 : ",data);
    let extraAddress = '';

    switch(data.userLanguageType){
      case "E":
        fullAddress = data.addressEnglish;
        if (data.bname !== '') {
          extraAddress += data.bnameEnglish;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        break;
      case "K":
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        break;
      default:
        console.error("언어설정 오류");
        break;
    }

    console.log(fullAddress);
    setAddress(fullAddress);
  };


  const handleClick = () => {
    open({
      onComplete: handleComplete, 
      popupTitle:"주소검색"
    });
  };

  const [address, setAddress] = useState();

  const divStyle={
    width:"500px",
    height:"50px",
    display:"flex",
    alignItems:"center",
    gap:"5px"
  }

  return (
    <div style={divStyle}>
      <span style={{width:'150px'}}>매장 주소지</span>
      <textarea value={address} placeholder='주소를 입력해주세요' style={{fontSize:"20px"}}/>
      <button style={{width:"100px", height:"40px", backgroundColor:"lightgrey", border:"1px solid black"}} type='button' onClick={handleClick}>
        주소검색
      </button>
    </div>
  )
}

function Postcode(props):any{

  const src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

  // const [selectedAddress, setSelectedAddress] = useState(undefined);
  // const [showDetailModal, setShowDetailModal] = useState(false)
  // const closeDetailModal = ()=>{setShowDetailModal(false)};
  
  const handleComplete = (data:any) => {
    let fullAddress = data.address;
    let extraAddress = '';
    console.log(data);

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    console.log(fullAddress)
    
    // setSelectedAddress(fullAddress);
    // setShowDetailModal(true);
    props.setAddress(fullAddress)
    // props.onClose()
  };

  const handleSearch = (data:any)=>{
    console.log("search data ::: ", data)
  }

  var themeObj = {
    bgColor: "#FFFFFF", //바탕 배경색
    searchBgColor: "#FFFFFF", //검색창 배경색
    contentBgColor: "#FFFFFF", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    pageBgColor: "#FFFFFF", //페이지 배경색
    textColor: theme.colors["text-primary"], //기본 글자색
    queryTextColor: theme.colors["text-primary"], //검색창 글자색
    postcodeTextColor: theme.colors["text-brand1"], //우편번호 글자색
    emphTextColor: theme.colors["text-brand1"], //강조 글자색
    outlineColor: theme.colors["border-brand1"] //테두리
 };

  return (
    <>
      <DaumPostcodeEmbed
        scriptUrl={src}
        style={{height:"100%"}}
        onComplete={handleComplete}
        onSearch={handleSearch}
        theme={themeObj}
      />
      {/* {showDetailModal&&
        <DetailAddressModal
          show={showDetailModal}
          handleClose={closeDetailModal}
          address={selectedAddress}
        />
      } */}
    </>
  )
};


export default Postcode

function DetailAddressModal(props){

  const [detailAddress, setDetailAddress] = useState<string|undefined>(undefined);

  const handleOnChange = (e:any) => {
    const detail = e.currentTarget.value
    console.log("상세주소 ::: ", detail);
    if(detail===undefined || detail===null){
      setDetailAddress("");
    }else{
      setDetailAddress(detail);
    }
  }

  return (
    <Modal {...props}>

      <BodyStyle>
        <ContentFrameStyle>
          <TextStyle>
            <ModalTextWrapperStyle>
              {props.address}
            </ModalTextWrapperStyle>
          </TextStyle>
          <InputText
            placeholder="상세 주소를 입력해주세요"
            value={detailAddress}
            onChange={handleOnChange}
          />
        </ContentFrameStyle>
        <BtnFrameStyle>
          <Button onClick={props.onCancel} style={{flex:1}} $buttonType="capsule" $variant="brand1-subtle" text="취소"/>
          <Button onClick={props.onConfirm} style={{flex:1}}  $buttonType="capsule" $variant="brand1" text="확인"/>
        </BtnFrameStyle>
      </BodyStyle>
    </Modal>
  )
}

export const BodyStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display:flex;

  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-09"]};
  position: relative;
  width: 500px;
  box-sizing: border-box;
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-08"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
`

export const ContentFrameStyle = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-06"]};
  width:100%;
  align-items: center;
  align-self: stretch;
  display:flex;
  flex: 0 0 auto;
  flex-direction: column;
`

export const BtnFrameStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  width:100%;
  flex: 0 0 auto;
`

export const TextStyle = styled.div`
align-items: flex-start;
align-self: stretch;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: ${(props)=>props.theme.spacing["spacing-04"]};
position: relative;
width: 100%;
`

export const ModalTextWrapperStyle = styled.div`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-heading-04"]};
  margin-top: -1px;
  position: relative;
  text-align: center;
`

export const ModalPStyle = styled.p`
  align-self: stretch;
  color:${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-body-04"]};
  position: relative;
  text-align: center;
  margin: 0px;
`