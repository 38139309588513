import React from "react";

import {DividerStyle} from "./Divider.styled"

import theme from "../../../styles/theme";

function Divider(props){

  const width = props.orientation==="vertical"? props.width : props.length
  const height = props.orientation==="horizontal"? props.width : props.length

  return (
    <DividerStyle {...props}>
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <line
          x1="0"
          y1="1"
          x2={`${width}`}
          y2={`${height}`}
          stroke={theme.colors[props.color]}
          stroke-width={`${width}`}
        />
      </svg>
    </DividerStyle>
  )
}

export default Divider