import React from "react";
import styled from "styled-components";

interface Props {
  state: string;
}

const StyledSvg = styled.svg`
  fill: none;
  height: 24px;
  width: 25px;
`;

export const EffectTabIcon1 = ({ state="default" }: Props): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_116_8717)">
        <g className="g" clipPath="url(#clip1_116_8717)">
          <path
            className={"path"}
            d="M4.79999 11H20.8V19.25C20.8 19.4489 20.7234 19.6397 20.587 19.7803C20.4506 19.921 20.2656 20 20.0727 20H5.52726C5.33438 20 5.14939 19.921 5.013 19.7803C4.87661 19.6397 4.79999 19.4489 4.79999 19.25V11Z"
            fill={state=="selected"? "#3617CE" : "#a9b0b7"}
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M4.58982 9.49998L20.2995 5.35341L19.5345 2.54091C19.4817 2.35317 19.357 2.19382 19.1875 2.09746C19.0179 2.0011 18.8172 1.9755 18.6289 2.02623L4.34794 5.79404C4.25461 5.81781 4.16693 5.85987 4.08998 5.91779C4.01303 5.97571 3.94835 6.04833 3.89969 6.13145C3.85102 6.21456 3.81935 6.3065 3.80649 6.40195C3.79364 6.4974 3.79987 6.59445 3.82482 6.68748L4.58982 9.49998Z"
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M7.14764 5.05542L11.628 7.64292"
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M12.6701 3.59839L17.1504 6.18495"
            stroke={state=="selected"? "#3617CE" : "#a9b0b7"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_116_8717">
          <path
            className="path"
            d="M0.799988 8C0.799988 3.58172 4.38171 0 8.79999 0H16.8C21.2183 0 24.8 3.58172 24.8 8V16C24.8 20.4183 21.2183 24 16.8 24H8.79999C4.38171 24 0.799988 20.4183 0.799988 16V8Z"
            fill="white"
          />
        </clipPath>
        <clipPath className="clip-path" id="clip1_116_8717">
          <rect className="rect" fill="white" height="24" transform="translate(0.799988)" width="24" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
};
