import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { InfoTabContent } from "./sections/InfoTabContent"; 
import { ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";

const StyledTabContainerNode = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-07-duplicate);
  height: 536px;
  overflow-y: scroll;
  padding: var(--tokens-spacing-08-duplicate) 0px var(--tokens-spacing-08-duplicate) 0px;
  position: relative;
  width: 100%;
`;

export const InfoTabContainer = (): JSX.Element => {

  const shopInfoContext = useContext(ShopInfoContext);
  const scenarioContext = useContext(ScenarioContext);

  const [name, setName] = useState(shopInfoContext.shopName);
  const [address, setAddress] = useState(shopInfoContext.shopAddress);
  const [duration, setDuration] = useState(shopInfoContext.adTime);
  const [atmosphere, setAtmosphere] = useState('아늑한');
  const [createDate, setCreateDate] = useState('2024.10.10');

  useEffect(()=>{
    getMood();
    getDateString();
  },[])

  function getMood(){
    const scenarioIndex = scenarioContext.selectedScenario    
    if(scenarioIndex!==undefined && scenarioIndex>=0){
      const mood = scenarioContext.scenario[scenarioIndex].mood;
      setAtmosphere(mood);
    }
  }

  function getDateString(){
    const now = new Date();
    
    const YY = now.getFullYear();
    const MM = now.getMonth()+1;
    const DD = now.getDate();

    const dateString = `${YY}.${MM}.${DD}`

    setCreateDate(dateString);
  }

  const handleInfoChange = ( item: string, value: any ) => {
    switch (item) {
      case 'name':
        setName(value as string);
        break;
      case 'address':
        setAddress(value as string);
        break;
      case 'duration':
        setDuration(value as number);
        break;    
      case 'atmosphere':
        setAtmosphere(value as string);
        break;
      case 'createDate':
        setCreateDate(value as string);
        break;
      default:
        break;
    }
  }

  return (
    <StyledTabContainerNode>
      <InfoTabContent name={name} address={address} duration={duration} atmosphere={atmosphere} createDate={createDate} onCommand={handleInfoChange}/>
    </StyledTabContainerNode>
  );
};