import styled from "styled-components";

export const ScenarioFrameStyle = styled.div`
  align-items: flex-start;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
  box-sizing: border-box;
  width:100%;
  
  cursor:pointer;

  &.selected{
    border: ${(props)=>props.theme.border["border-03"]} solid;
    border-color: ${(props)=>props.theme.colors["border-brand"]};
    padding-top: calc(${(props)=>props.theme.spacing["spacing-11"]} - ${(props)=>props.theme.border["border-03"]});
    padding-right: calc(${(props)=>props.theme.spacing["spacing-13"]} - ${(props)=>props.theme.border["border-03"]});
    padding-bottom: calc(${(props)=>props.theme.spacing["spacing-11"]} - ${(props)=>props.theme.border["border-03"]});
    padding-left: calc(${(props)=>props.theme.spacing["spacing-13"]} - ${(props)=>props.theme.border["border-03"]});
  }

  &:hover{
    background-color :${(props)=>props.theme.colors["background-secondary"]}
  }
`

export const ScenarioSubFrameTopStyle = styled.div`
  align-items: center;
  align-self:stretch;
  border-bottom: ${(props)=>props.theme.border["border-02"]} solid;
  border-color: ${(props)=>props.theme.colors["border-secondary"]};
  display: flex;
  flex-direction:row;
  flex: 0 0 auto;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-none"]} ${spacing["spacing-06"]} ${spacing["spacing-none"]}`
  }};
  position: relative;
  width:100%;
`
export const ScenarioSubFrameTopTitleStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-brand1"]};
  margin-top: -1px;
  position:relative;
  white-space: nowrap;
  width: fit-content;
`
export const ScenarioSubFrameTopTextWrapperStyle = styled.div`
  color: ${(props)=>props.theme.colors["text-secondary"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  margin-top: -1px;
  position:relative;
  white-space: nowrap;
  width: fit-content;
`
//frame2 
export const ScenarioConetentsFrameStyle = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width:100%;
`
//frame3
export const ScenarioConetentsFrameItemStyle = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  position: relative;
  width:100%;
`

//p
export const ScenarioConetentsFrameItemTextStyle = styled.div`
  align-self:stretch;
  ${(props)=>props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  position: relative;
`