import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";

import { Box, duration, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { TimelineHeader } from "./TimelineHeader/TimelineHeader";
import { TimelineBar } from "./TimelineBar/TimelineBar";

import { ReactComponent as VideoIcon } from "../../../../Assets/icon-editor/timeline-video.svg";
import { ReactComponent as CaptionIcon } from "../../../../Assets/icon-editor/timeline-caption.svg";
import { ReactComponent as NarrationIcon } from "../../../../Assets/icon-editor/timeline-narration.svg";
import { ReactComponent as BackMusicIcon } from "../../../../Assets/icon-editor/timeline-back-music.svg";
import { ReactComponent as SoundBlueIcon } from "../../../../Assets/icon-editor/timeline-sound-blue.svg";
import { ReactComponent as SoundOnIcon } from "../../../../Assets/icon-editor/timeline-sound-on.svg";
import { ReactComponent as SoundOffIcon } from "../../../../Assets/icon-editor/timeline-sound-off.svg";

import { ErrorOutlineOutlined, Close } from "@mui/icons-material";

import { VideoTrack } from "./VideoTrack/VideoTrack";
import { TextTrack } from "./TextTrack/TextTrack";
import { NarrationTrack } from "./NarrationTrack/NarrationTrack";
import { MusicTrack } from "./MusicTrack/MusicTrack";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

import { EditorPageContext } from "../../editorPageContext";

export const TimelinePanel = (): JSX.Element => {

  const timelineWidth = 1320; // 타임라인 화면 크기
  
  const [adsDuration,  setAdsDuration] = useState(15) // 30초 (영상 전체 길이.)
  const [time, setTime] = useState(0); // current time
  const [isError, setIsError] = useState(true);
  
  const [stepSize, setStepSize] = useState((timelineWidth/adsDuration)/10); 
  const [stepCount, setStepCount] = useState(5); // 초간 틱 구간 갯수 (기본 0.2초 당 1틱)

  const [selectedItem, setSelectedItem] = useState({type:undefined, index:undefined});

  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const editorPageContext = useContext(EditorPageContext);

  const handleClickTimeLineItem = ({type, index})=>{
    // console.log(`clicked ${type} index ${index}`)

    setSelectedItem({type:type, index:index});
    editorPageContext.setSelectIndex(index);
    switch(type){
      case "video":
        editorPageContext.setSelectType("video")
        break;
      case "text":
        editorPageContext.setSelectType("text")
        break;
      case "narration":
        editorPageContext.setSelectType("ntr")
        break;
      case "bgm":
        editorPageContext.setSelectType("bgm")
        break;
      default:
        editorPageContext.setSelectType(undefined)
        break;
    }
  }

  useEffect(()=>{
    setAdsDuration(shopInfoContext.adTime);
    setStepSize((timelineWidth/shopInfoContext.adTime)/10);
    if( shopInfoContext.adTime > 15 ) 
      setStepCount(2);
    else 
      setStepCount(5);

  },[shopInfoContext.adTime])

  useEffect(()=>{
    try{
      const newVideoEl = document.createElement('video');
      if(newVideoEl){
        newVideoEl.src = editorContext.outputVideoUrl;
        newVideoEl.muted=true;
        newVideoEl.autoplay=false;

        newVideoEl.addEventListener("loadedmetadata",(event)=>{
          // console.log(`video metadata loaded :: `, event)
          // audioEl.pause();
          const timeDiff = Math.abs(adsDuration - newVideoEl.duration);
          // console.log(`adv time : ${adsDuration} / video time : ${newVideoEl.duration} / diff : ${timeDiff}`)
          if(timeDiff>0.5){
            setIsError(true)
          }else{
            setIsError(false)
          }
          newVideoEl.remove();
        })
        newVideoEl.addEventListener("error",(event)=>{
          console.error(`video metadata error `, event)
          newVideoEl.remove();
        })
      }

      const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
      // console.log("preview element :: ", previewEl)
      if(previewEl){
        // previewEl.ontimeupdate
        previewEl.addEventListener("timeupdate",()=>{
          // console.log("current time :: ", previewEl.currentTime)
          setTime(previewEl.currentTime)
        })
      }
    }catch(err){
      console.error("get video duration error",err)
    }
  },[editorContext.outputVideoUrl])

  
  
  // const masherContext = useContext(MasherContext);
  // const {editor} = masherContext
  // const {selection} = editor

  // const [videoClips, setVideoClips] = useState<any[]>([])
  // const [bgmClips, setBgmClips] = useState<any[]>([])
  // const [textClips, setTextClips] = useState<any[]>([])
  // const [ntrClips, setNtrClips] = useState<any[]>([])

  // const prevClipsRef = useRef(null);
  
  // useEffect(()=>{
  //   const areClipsEqual = JSON.stringify(selection?.mash?.clips) === JSON.stringify(prevClipsRef.current);

  //   if(selection?.mash?.clips && !areClipsEqual){
  //     let videoClips = selection.mash.clips?.filter((clip, index)=> (index%3)===2)
  //     let bgmClip = selection.mash.clips[0];
  //     let ntrClips = selection.mash.clips.filter((clip, index)=> (index%3)===1)
  //     let textClips = selection.mash.clips.filter((clip, index)=> (index!==0)&&((index%3)===0))
  //     setVideoClips(videoClips);
  //     setBgmClips([bgmClip])
  //     setNtrClips(ntrClips)
  //     setTextClips(textClips)
  //     prevClipsRef.current = selection.mash.clips;
  //   }
  // },[selection?.mash?.clips])

  // useEffect(()=>{console.log("videoClips changed :: ", videoClips)},[videoClips])
  // useEffect(()=>{console.log("bgmClips changed :: ", bgmClips)},[bgmClips])
  // useEffect(()=>{console.log("textClips changed :: ", textClips)},[textClips])
  // useEffect(()=>{console.log("ntrClips changed :: ", ntrClips)},[ntrClips])



  


  return (
    <Box sx={{
      position: "absolute",
      top: "400px",
      left: 0,
      width: "1440px",
      height: "280px",
      background: "var(--color-background-secondary, #F6F7FD)",
      overflow: "hidden",
      borderTop: "1px solid var(--color-border-secondary, #E2E4E7)"
    }}>
      <TimelineHeader duration={adsDuration}/>
      {isError &&
        <Box sx={{ display: "flex", pt: 1, pr: "16px", pb: 1, pl: "16px", width: "100%", height: 24, bgcolor: "#FFF1F0",  }} gap={2}>
          <Box display="flex" alignItems="center" gap={2} flex={1}>
            <ErrorOutlineOutlined fontSize="small" color="error"/>
            <Typography flex={1} mt={-0.125} variant="caption" color="error">
              영상이 {adsDuration}초를 초과했습니다. 시간을 조정하여 다시 시도해 주세요.
            </Typography>
            <Close sx={{ cursor: 'pointer'}} fontSize="small" color="error" onClick={()=> setIsError(false)}/>
          </Box>
        </Box>
      }
      <TimelineBar maxTime={adsDuration} stepCount={stepCount} stepSize={stepSize} currentTime={time} />
      <Box sx={{ display: "flex", direction: "row", gap: "4px" }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "120px",
          height: "216px",
          alignItems: "flex-start",
          gap: "4px",
          paddingTop: "4px",
          borderRight: "1px solid var(--color-border-secondary, #E2E4E7)"
        }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "48px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <VideoIcon />
              <TrackHeadTextWrapper>비디오</TrackHeadTextWrapper> 
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <CaptionIcon />
              <TrackHeadTextWrapper>자막</TrackHeadTextWrapper>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px", gap: "4px", flexShrink: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <NarrationIcon/>
              <TrackHeadTextWrapper>나레이션</TrackHeadTextWrapper>
            </Box>
            {/* <IconButton size="small" sx={{ width: "16px", height: "16px", padding: "1px" }}> */}
              <SoundBlueIcon width={12} height={12}/>
            {/* </IconButton> */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <BackMusicIcon />
              <TrackHeadTextWrapper>배경음악</TrackHeadTextWrapper>
            </Box>
            {/* <IconButton size="small" sx={{ width: "16px", height: "16px", padding: "2px" }}> */}
              <SoundBlueIcon width={12} height={12}/>
            {/* </IconButton> */}
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "1320px",
          height: "216px",
          alignItems: "flex-start",
          gap: "4px",
          paddingTop: "4px",
          // position: "absolute",
          // top: "68px",
          // left: "124px",
          // backgroundColor: "blue",
        }}>
          <VideoTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize} clips={[]} />
          <TextTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize} clips={[]}/>
          <NarrationTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize} clips={[]}/>
          <MusicTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize} clips={[]}/>
        </Box>
      </Box>
    </Box>
  );
};

const TrackHeadTextWrapper = styled.div`
  color:${props=>props.theme.colors["text-secondary"]};
  ${(props)=>props.theme.typography["pre-caption-01"]};
  
`