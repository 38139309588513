import apiClient from './index.ts'

import axios from 'axios';

// const HOST_PROTOCOL= 'https';
// const HOST_ADDR = 'api-dev.skt-ai-ad.com';

// const BASE_URL = `${HOST_PROTOCOL}://${HOST_ADDR}`

import { BASE_URL } from './index';

const formDataClient = axios.create({
  baseURL: BASE_URL,
  headers:{
    "Adv-Token":"9ab559f974a9446839628edc267852aec3a53ebe32ba992c66396a2c500a3327"
  }
});
formDataClient.interceptors.request.use(
  function (config) {
    try{
      // console.log("sending request : ",config);
      return config;
    }catch(err){
      return Promise.reject(err)
    }
  },
  function (error) {
    // 요청 오류가 있는 경우 작업 수행
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    return Promise.reject(error);
  }
);

formDataClient.interceptors.response.use(
  function (response) {
    try{
      if(response.status === 200){
        // console.log("Got Axios response Success")
      }else{
        console.error("Got Axios response failed with error", response);
      }
      return response
    }catch(error){
      console.error("response error ::: ", error)
      return Promise.reject(error)
    }
  },
  function (error) {
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    console.error("Axios response failed with an error", error);
    return Promise.reject(error);  // 여기서도 Promise.reject로 에러 처리
  }
);



export const BASE_URL_CORE = `https://${process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST}`

const apiClientCore = axios.create({
  baseURL: BASE_URL_CORE,
});
apiClientCore.interceptors.request.use(
  function (config) {
    try{
      // console.log("sending request : ",config);
      return config;
    }catch(err){
      return Promise.reject(err)
    }
  },
  function (error) {
    // 요청 오류가 있는 경우 작업 수행
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    return Promise.reject(error);
  }
);

apiClientCore.interceptors.response.use(
  function (response) {
    try{
      if(response.status === 200){
        // console.log("Got Axios response Success")
      }else{
        console.error("Got Axios response failed with error", response);
      }
      return response
    }catch(error){
      console.error("response error ::: ", error)
      return Promise.reject(error)
    }
  },
  function (error) {
    if (!navigator.onLine) {
      console.error('Network is offline');
      return Promise.reject("Network offline")
    }
    console.error("Axios response failed with an error", error);
    return Promise.reject(error);  // 여기서도 Promise.reject로 에러 처리
  }
);



async function WebResourceUpload(request){
  try{
    const response = await formDataClient.post('/resource/upload', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}
async function WebResourceDelete(request){
  try{
    const response = await apiClient.delete('/resource/delete', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}
async function WebCutImage(request){
  try{
    const response = await apiClientCore.post('/resource/cut-image', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}
async function WebCutVideo(request){
  try{
    const response = await apiClientCore.post('/resource/cut-video', request);
    const result = await response.data;
    return result;
  }catch(err){
    return {
      result: -1,
      errMsg: err
    }
  }
}

async function WebGetUrl(request){
  try{
    const response = await apiClientCore.post('/resource/create-presigned-url',request)
    const result = await response.data;
    return result;
  }catch(err){
    return{
      result:-1,
      errMsg: err
    }
  }
}

async function WebSave(request){
  try{
    const response = await apiClientCore.post('/admin/save-final-scenario',request);
    const result = await response.data;
    return result;
  }catch(err){
    return({
      result:-1,
      errMsg: err
    })
  }
}

async function WebLoad(request){
  try{

    const paresRequest =  Object.entries(request).map(([key,value])=> `${key}=${value}`)
    const query = paresRequest.join("&");

    const response = await apiClientCore.get(`/admin/load-final-scenario?${query}`)
    const result = await response.data;
    return result;
  }catch(err){
    return({
      result:-1,
      errMsg: err
    })
  }
}

async function WebGetFile(request){
  try{
    const response = await apiClientCore.post('/resource/download-file',request,{
      responseType: 'blob',
    });
    if (response.headers['content-type']?.includes('application/json')) {
      const errorText = await response.data.text();
      const errorJson = JSON.parse(errorText);
      return {
        result: -1,
        errMsg: errorJson,
      };
    }

    return response.data;
  }catch(err){
    return({
      result:-1,
      errMsg: err
    })
  }
}

export {
  WebResourceUpload,
  WebResourceDelete,
  WebCutImage,
  WebCutVideo,
  WebGetUrl,
  WebSave,
  WebLoad,
  WebGetFile
}