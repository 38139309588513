import styled from "styled-components";

export const QuestionTitleStyle = styled.div`
  align-items: center;
  display: flex;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-04"]} ${spacing["spacing-none"]} ${spacing["spacing-04"]}`
  }};
  position: relative;
`
export const TextWrapperStyle = styled.p`
  ${(props)=>props.theme.typography["pre-heading-03"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  width:fit-content;
  white-space: nowrap;
`