import React, { ChangeEvent, useEffect, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckIcon } from "../../../Assets/Icons/Check.svg";

function CheckBox(props){

  const [checked, setChecked] = useState(false);

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    setChecked(e.currentTarget.checked);
  }
  
  useEffect(()=>{    
    console.log("check :: ",checked)
    if(props.onChange){
      props.onChange(checked)
    }
  },[checked])

  const handleClick=()=>{
    setChecked(!checked);
  }

  return(
    <CheckBoxContainer onClick={handleClick}>
      <StyledInput
        {...props}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <CheckboxStyle checked={checked}>
        <CheckIcon style={{stroke:"white"}}/>        
      </CheckboxStyle>      
    </CheckBoxContainer>
  )
}

export default CheckBox

const CheckboxStyle = styled.div<{checked:boolean}>`
  display: flex;
  width: 20px;
  height: 20px;
  background: ${(props) =>
    props.checked ? props.theme.colors["icon-brand1"] : props.theme.colors["icon-disabled"]};
  border-radius: ${(props)=>props.theme.radius["radius-01"]};
  
  align-items:center;
  justify-content:center;

`

const StyledInput = styled.input`  
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`