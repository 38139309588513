import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PlayerIcon from "./PlayerIcon";
import PlayerTime from "./PlayerTime";
import PlayerSeeker from "./PlayerSeeker";
import PlayerSoundBar from "./PlayerSoundBar";

function PlayerController(props){
  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClickMute = ()=> {
    setMuted(!muted);
  }

  const handleClickPlay = () => {
    // setIsPlaying(!isPlaying)
    if(props.onClickPlay){
      props.onClickPlay();
    }
  }

  const handleOnClickBack = () => {
    if(props.onClickBack){
      props.onClickBack()
    }
  }
  const handleOnClickForward = () => {
    if(props.onClickForward){
      props.onClickForward()
    }
  }

  return(
    <PlayerControllerStyle {...props} ref={undefined} onClick={undefined} >
      <PlayerControllerFrameStyle>
        <PlayerControllerControllWrapperStyle>
          {props.isPlaying? <PlayerIconStyle onClick={handleClickPlay} icon="pause"/> : <PlayerIconStyle onClick={handleClickPlay} icon="play"/>}
          <PlayerIconStyle onClick={handleOnClickBack} icon="back"/>
          <PlayerIconStyle onClick={handleOnClickForward} icon="forward"/>
        </PlayerControllerControllWrapperStyle>
        <PlayerControllerVolumWrapperStyle>
          <PlayerSoundBar value={50}/>
          {muted? <PlayerIconStyle onClick={handleClickMute} icon="mute"/>:<PlayerIconStyle onClick={handleClickMute} icon="volume"/>}          
        </PlayerControllerVolumWrapperStyle>
      </PlayerControllerFrameStyle>
      <PlayerControllerFrameStyle>
        <PlayerSeeker
          current={props.current}
          duration={props.duration}
          onDragHandle={props.onDragHandle}/>
        <PlayerTime current={props.current} duration={props.duration}/>
      </PlayerControllerFrameStyle>
    </PlayerControllerStyle>
  )
}

export default PlayerController

const PlayerIconStyle = styled(PlayerIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

const PlayerControllerStyle = styled.div`
  align-items:center;
  background-color: ${(props)=>props.theme.colors["background-overlay-subtle"]};
  display:flex;
  flex-direction: column;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-06"]} ${spacing["spacing-09"]} ${spacing["spacing-06"]} ${spacing["spacing-09"]}`
  }};

  width:100%;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
`

const PlayerControllerFrameStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 ;
  justify-content: space-between;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width: 100%;
  height: 24px;
`
const PlayerControllerControllWrapperStyle = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  display:inline-flex;
  width: fit-content;
`
const PlayerControllerVolumWrapperStyle = styled.div`
  display: inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  align-items:center;
`
